import moment from "moment";

export default {
  methods: {
    getRangeText(viewType, startDate, endDate) {
      switch (viewType) {
        case "days": {
          if (!endDate && startDate) {
            return moment(startDate).toDate().toLocaleDateString("nl-NL", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            });
          }
          if (!startDate && endDate) {
            return moment(endDate).toDate().toLocaleDateString("nl-NL", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            });
          }
          if (moment(startDate).isSame(endDate, "date")) {
            return moment(startDate).toDate().toLocaleDateString("nl-NL", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            });
          }

          const start = startDate
            ? moment(startDate).toDate().toLocaleDateString("nl-NL", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : "?";
          const end = endDate
            ? moment(endDate).toDate().toLocaleDateString("nl-NL", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : "?";
          return `${start} - ${end}`;
        }
        case "months": {
          if (!endDate && startDate) {
            return moment(startDate).toDate().toLocaleDateString("nl-NL", {
              month: "short",
              year: "numeric",
            });
          }
          if (!startDate && endDate) {
            return moment(endDate).toDate().toLocaleDateString("nl-NL", {
              month: "short",
              year: "numeric",
            });
          }
          if (moment(startDate).isSame(endDate, "month")) {
            return moment(startDate).toDate().toLocaleDateString("nl-NL", {
              month: "short",
              year: "numeric",
            });
          }

          const start = startDate
            ? moment(startDate).toDate().toLocaleDateString("nl-NL", {
                month: "short",
                year: "numeric",
              })
            : "?";
          const end = endDate
            ? moment(endDate).toDate().toLocaleDateString("nl-NL", {
                month: "short",
                year: "numeric",
              })
            : "?";
          return `${start} - ${end}`;
        }
        case "quarters": {
          if (!endDate && startDate) {
            return `Kwartaal ${moment(startDate).quarter()} ${moment(startDate).year()}`;
          }
          if (!startDate && endDate) {
            return `Kwartaal ${moment(endDate).quarter()} ${moment(endDate).year()}`;
          }
          if (moment(startDate).isSame(endDate, "quarter")) {
            return `Kwartaal ${moment(startDate).quarter()} ${moment(startDate).year()}`;
          }

          const start = startDate
            ? `Kwartaal ${moment(startDate).quarter()} ${moment(startDate).year()}`
            : "?";
          const end = endDate
            ? `Kwartaal ${moment(endDate).quarter()} ${moment(endDate).year()}`
            : "?";

          return `${start} - ${end}`;
        }
        case "years": {
          if (!endDate && startDate) {
            return `${moment(startDate).year()}`;
          }
          if (!startDate && endDate) {
            return `${moment(endDate).year()}`;
          }
          if (moment(startDate).isSame(endDate, "year")) {
            return `${moment(startDate).year()}`;
          }
          return `${startDate ? moment(startDate).year() : "?"} - ${
            endDate ? moment(endDate).year() : "?"
          }`;
        }
        default:
          return "? - ?";
      }
    },
  },
};
