<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 class="page-title">Dekken</h1>
        </div>
      </div>
      <b-form-checkbox
        id="included-checkbox"
        v-model="includes"
        name="included-checkbox"
        :value="true"
        :unchecked-value="false"
        class="mt-2 mb-2"
      >
        Inclusief niet aanwezige dieren
      </b-form-checkbox>
      <div class="mt-2 mb-2 d-flex align-items-center">
        <search-input class="mr-auto" column="animals_life_number" @search="onSearch" />
        <period-filter :period="period" @change="handlePeriodChange" />
        <export-options :url="'breeding_programs'" :period="period"></export-options>
      </div>
      <div>
        <div class="row">
          <div class="col-12">
            <Table
              sortable
              :columns="columns"
              :data="animals"
              :key-extractor="keyExtractor"
              :sort="sort"
              @sort="onSort"
              @filterChange="onFilterChange"
            >
              <template #animal_life_number="{ item }">
                <router-link
                  :to="{
                    path: '/passport/' + item.animal.id,
                  }"
                  class="animal_numbers"
                >
                  <span>{{ item.animal.animal_numbers.lifeNumber }}</span>
                  <span>{{ item.animal.animal_numbers.workNumber }}</span>
                </router-link>
              </template>
              <template #human_animal_type="{ item }">
                {{ item.animal.human_animal_type }}
              </template>
              <template #male_animals="{ item }">
                <template v-for="maleAnimal in item.breedingProgram.attributes.male_animals">
                  <span :key="maleAnimal.id">
                    {{ maleAnimal.data.attributes.life_number }}
                    <br />
                  </span>
                </template>
              </template>
              <template #human_method_type="{ item }">
                {{ item.breedingProgram.attributes.human_method_type }}
              </template>
              <template #empty>Er zijn nog geen resultaten</template>
            </Table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
import ExportOptions from "@/components/Lists/ExportOptions.vue";
import SearchInput from "@/components/SearchInput.vue";
import defaultListPage from "@/mixins/defaultListPage";
import pageLoader from "@/mixins/pageLoader";
import animalTypes from "@/enums/animalTypes";
import coverTypes from "@/enums/coverTypes";

export default {
  name: "BreedingPrograms",
  components: {
    PeriodFilter,
    ExportOptions,
    SearchInput,
  },
  mixins: [pageLoader, defaultListPage("breeding_programs", null, true, true)],
  data() {
    return {
      columns: {
        animal_life_number: "(Werk)nummer",
        human_animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        breedingProgramDate: { title: "Datum", filter: { type: "date" } },
        male_animals: "Mannelijke dier(en)",
        human_method_type: { title: "Soort dekking", filter: { type: "enum", values: coverTypes } },
      },
      reducedAnimals: [],
    };
  },
  computed: {
    ...mapState({
      animals: (state) => {
        const reducedItems = state.default_lists.items.reduce((result, breedingProgram) => {
          if ("attributes" in breedingProgram)
            return [
              ...result,
              ...breedingProgram.attributes.female_animals.map((animal) => ({
                breedingProgram,
                animal: {
                  id: animal.data.id,
                  ...animal.data.attributes,
                },
              })),
            ];
          return [];
        }, []);

        if (typeof reducedItems === "object") {
          const newAnimals = reducedItems.map((reducedItem) => {
            return {
              breedingProgram: reducedItem.breedingProgram,
              breedingProgramDate: reducedItem.breedingProgram.attributes.date,
              animal: {
                ...reducedItem.animal,
                animal_numbers: {
                  lifeNumber: reducedItem.animal.life_number.substring(
                    0,
                    reducedItem.animal.life_number.length - 5,
                  ),
                  workNumber: reducedItem.animal.life_number.substring(
                    reducedItem.animal.life_number.length - 5,
                  ),
                },
              },
            };
          });
          return newAnimals;
        }
        return [];
      },
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(animal) {
      return animal.id;
    },
  },
};
</script>
<style></style>
