var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pageLoader_isDataLoaded)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_vm._m(0),(_vm.statistics)?_c('div',{staticClass:"row"},[_c('core-stat-card',{attrs:{"category":"Groei sinds geboorte (gr/dag)","value":_vm._f("roundDecimal")((_vm.statistics.growth_since_first_weighing * 1000))}}),_c('core-stat-card',{attrs:{"category":"Groei sinds laatste weging (gr/dag)","value":_vm._f("roundDecimal")((_vm.statistics.growth_since_last_weighing * 1000))}})],1):_vm._e(),_c('b-form-checkbox',{staticClass:"mt-2 mb-2",attrs:{"id":"included-checkbox","name":"included-checkbox","value":true,"unchecked-value":false},model:{value:(_vm.includes),callback:function ($$v) {_vm.includes=$$v},expression:"includes"}},[_vm._v(" Inclusief niet aanwezige dieren ")]),_c('div',{staticClass:"mt-2 mb-2 d-flex justify-content-between align-items-center"},[_c('search-input',{staticClass:"mr-auto",attrs:{"column":"animal_life_number"},on:{"search":_vm.onSearch}}),_c('period-filter',{attrs:{"period":_vm.period},on:{"change":_vm.handlePeriodChange}}),_c('export-options',{attrs:{"period":_vm.period,"url":'default_lists/weighings/latest',"includes":!_vm.includes}})],1),(_vm.weighings)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('Table',{attrs:{"sortable":"","columns":_vm.columns,"data":_vm.weighings,"key-extractor":_vm.keyExtractor,"sort":_vm.sort},on:{"sort":_vm.onSort,"filterChange":_vm.onFilterChange},scopedSlots:_vm._u([{key:"animal_life_number",fn:function(ref){
var weighing = ref.item;
return [_c('router-link',{staticClass:"animal_numbers",attrs:{"to":{
                    path: '/passport/' + weighing.animal_id,
                  }}},[_c('span',[_vm._v(_vm._s(weighing.animal_numbers.lifeNumber))]),_c('span',[_vm._v(_vm._s(weighing.animal_numbers.workNumber))])])]}},{key:"animal_gender",fn:function(ref){
                  var weighing = ref.item;
return [_vm._v(" "+_vm._s(weighing.animal_human_gender)+" ")]}},{key:"animal_animal_type",fn:function(ref){
                  var weighing = ref.item;
return [_vm._v(" "+_vm._s(weighing.animal_human_animal_type)+" ")]}},{key:"weight_date",fn:function(ref){
                  var weighing = ref.item;
return [_vm._v(" "+_vm._s(weighing.human_weight_date)+" ")]}},{key:"animal_growth_since_first_weighing",fn:function(ref){
                  var weighing = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("roundDecimal")((weighing.animal_growth_since_first_weighing * 1000)))+" ")]}},{key:"growth_since_previous_weighing",fn:function(ref){
                  var weighing = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("roundDecimal")((weighing.growth_since_previous_weighing * 1000)))+" ")]}},{key:"empty",fn:function(){return [_vm._v("Geen gewichten doorgegeven")]},proxy:true}],null,false,1612508010)}),(_vm.pagination.totalPages > 1)?_c('paginate',{attrs:{"page-count":_vm.pagination.totalPages,"click-handler":_vm.fetchPaginatedItems,"prev-text":'Vorige',"next-text":'Volgende',"container-class":'pagination justify-content-center pt-3',"page-class":'page-item',"page-link-class":'page-link',"prev-class":'page-item prev-item',"prev-link-class":'page-link prev-link-item',"next-class":'page-item next-item',"next-link-class":'page-link next-link-item',"break-view-class":'break-view',"break-view-link-class":'break-view-link',"first-last-button":false},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)])]):_vm._e()],1)]):_c('div',{staticClass:"content"},[_c('AppSpinner')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"page-title"},[_vm._v("Gewicht (kg)")])])])}]

export { render, staticRenderFns }