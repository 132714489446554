<template>
  <div>
    <line-chart :chartdata="chartdata" :options="options" />
  </div>
</template>

<script>
import moment from "moment";
import LineChart from "./Chart.vue";

export default {
  name: "LineChartContainer",
  components: { LineChart },
  props: {
    weighings: {
      type: Array,
    },
    dataUrl: {
      type: String,
      default: "Line", // Line | Pie | Bar
    },
  },
  data: () => ({
    loaded: false,
    chartdata: null,
    options: {
      legend: {
        display: false,
        labels: {
          fontColor: "black",
          fontSize: 72,
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
    },
  }),
  created() {
    this.loaded = false;

    const labels = [];
    const series = [];

    const weights = this.weighings;

    // sort weights by weight_date
    weights.sort((a, b) => {
      return moment(a.weight_date, "DD-MM-YYYY").diff(moment(b.weight_date, "DD-MM-YYYY"));
    });

    weights.forEach((weighing) => {
      labels.push(moment(weighing.weight_date, "DD-MM-YYYY").format("D MMM"));
      series.push(weighing.weight);
    });

    this.chartdata = {
      labels,
      datasets: [
        {
          fill: false,
          label: "Gewicht",
          borderColor: "#0e0649",
          backgroundColor: "#0e0649",
          lineTension: 0.2,
          data: series,
        },
      ],
    };
    this.loaded = true;
  },
};
</script>
