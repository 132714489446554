<template>
  <b-form-input v-model="value" class="search mr-auto" placeholder="Zoeken" />
</template>
<script>
export default {
  name: "SearchInput",
  props: {
    column: { type: String, default: "life_number" },
  },
  data() {
    return { value: "" };
  },
  watch: {
    value(value) {
      this.$emit("search", { key: this.column, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  width: 360px;
}
</style>
