<template>
  <autocomplete
    auto-select
    v-bind="$attrs"
    :search="search"
    :get-result-value="getResultValue"
    @submit="handleSubmit"
  ></autocomplete>
</template>
<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";

export default {
  name: "BaseAutocomplete",
  components: {
    Autocomplete,
  },
  inheritAttrs: true,
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    label: {
      type: String,
      description: "Input label",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    addonRightIcon: {
      type: String,
      description: "Input icon on the right",
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left",
    },
    searchData: {
      type: Array,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
  },
  methods: {
    onInput(evt) {
      this.$emit("input", evt.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    search(input) {
      if (input.length < 1) {
        return [];
      }

      return this.searchData.filter((animal) => {
        return animal.life_number.toLowerCase().match(input.toLowerCase());
      });
    },
    getResultValue(animal) {
      return animal.life_number;
    },
    handleSubmit(animal) {
      return this.$emit("onResult", animal.id);
    },
  },
};
</script>
<style></style>
