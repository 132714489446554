<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 class="page-title">Transport</h1>
        </div>
      </div>
      <div>
        <div class="mt-4 mb-4 d-flex justify-content-between align-items-center">
          <tabs
            :items="['Aanvoer', 'Afvoer', 'A - B']"
            :active-index="selectedTabIndex"
            @change="onTabChange"
          />
        </div>
        <ImportsTable v-if="selectedTabIndex === 0" />
        <ExportsTable v-if="selectedTabIndex === 1" />
        <AToBTable v-if="selectedTabIndex === 2" />
      </div>
    </div>
  </div>
</template>
<script>
import ImportsTable from "@/components/Transports/ImportsTable.vue";
import ExportsTable from "@/components/Transports/ExportsTable.vue";
import AToBTable from "@/components/Transports/AToBTable.vue";
import Tabs from "@/components/Tabs.vue";

export default {
  name: "Transports",
  components: {
    Tabs,
    ImportsTable,
    ExportsTable,
    AToBTable,
  },
  data() {
    return {
      selectedTabIndex: 0,
      imports: [],
      error: "",
      query: "",
    };
  },
  created() {
    this.$http.secured
      .get("/imports")
      .then((response) => {
        this.imports = response.data.data;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    onTabChange(tabIndex) {
      this.selectedTabIndex = tabIndex;
    },
  },
};
</script>
<style></style>
