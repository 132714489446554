<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 class="page-title">Adressen</h1>
          <div class="pl-3 pr-3 row d-flex justify-content-between">
            <base-input
              v-model="query"
              type="text"
              class="w-25 mb-0"
              placeholder="Zoek een adres"
            ></base-input>
            <router-link
              class="btn btn-success"
              :to="{
                path: 'contacts/add',
              }"
            >
              Toevoegen
            </router-link>
          </div>
        </div>
      </div>
      <hr class="my-4" />
      <div v-if="filteredContacts">
        <div class="row">
          <div class="col-12">
            <Table :columns="columns" :data="filteredContacts" :key-extractor="keyExtractor">
              <template #company_name="{ item: contact }">
                <router-link :to="{ path: 'contacts/' + contact.attributes.id }">
                  {{ contact.attributes.company_name }}
                </router-link>
              </template>
              <template #empty>
                <template v-if="contacts.length === 0">Er zijn nog geen adressen gemaakt</template>
                <template v-else>Geen adressen gevonden</template>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Contacts",
  data() {
    return {
      contacts: [],
      error: "",
      query: "",
      columns: {
        company_name: "Naam",
        "attributes.ubn": "UBN",
        "attributes.city": "Plaats",
      },
    };
  },
  computed: {
    filteredContacts() {
      return this.contacts.filter((contact) => {
        return (
          contact.attributes.company_name.toLowerCase().match(this.query) ||
          contact.attributes.ubn.toLowerCase().match(this.query) ||
          contact.attributes.city.toLowerCase().match(this.query)
        );
      });
    },
  },
  created() {
    this.$http.secured
      .get("/contacts")
      .then((response) => {
        this.contacts = response.data.data;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    keyExtractor(contact) {
      return contact.attributes.id;
    },
  },
};
</script>
<style></style>
