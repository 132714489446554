<template>
  <div class="dropdown-wrapper">
    <div
      v-if="open"
      v-click-outside="$listeners.clickOutside"
      :class="{
        dropdown: true,
        [dropdownClass]: dropdownClass !== undefined,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    open: Boolean,
    dropdownClass: String,
  },
  methods: {
    onClickOutside() {
      this.$emit("clickOutside");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/bootstrap/custom-bootstrap.scss";

.dropdown-wrapper {
  position: relative;

  .dropdown {
    user-select: none;
    position: absolute;
    top: 0;
    right: 0;
    background: $grayLighter;
    border: 1px solid $gray;
    box-sizing: border-box;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    max-width: 320px;
    width: max-content;
    z-index: 100;
  }
}
</style>
