var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:{
    'calendar-option': true,
    'calendar-option-selected': _vm.isDateSelected(),
    'calendar-option-between-dates': _vm.isDateBetweenStartAndEnd(),
    'calendar-option-disabled': !_vm.isDateClickable(),
    'calendar-option-is-start-date': _vm.isStartDate(),
    'calendar-option-is-end-date': _vm.isEndDate(),
  },on:{"click":function($event){return _vm.handleDateSelect()}}},[_vm._v(" Kwartaal "+_vm._s(_vm.quarter)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }