<template>
  <button
    :class="{
      'calendar-option': true,
      'calendar-day': true,
      'calendar-option-selected': isDateSelected(),
      'calendar-option-between-dates': isDateBetweenStartAndEnd(),
      'calendar-option-disabled': !isDateClickable(),
      'calendar-option-is-start-date': isStartDate(),
      'calendar-option-is-end-date': isEndDate(),
    }"
    @click="handleDateSelect()"
  >
    {{ formattedDay }}
  </button>
</template>
<script>
import moment from "moment";

export default {
  name: "Day",
  props: {
    singleDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    day: {
      type: Number,
      default: 1,
      required: false,
    },
    month: {
      type: Number,
      default: 1,
      required: true,
    },
    year: {
      type: Number,
      default: 1970,
      required: true,
    },
    startDate: {
      type: Date,
      default: undefined,
    },
    endDate: {
      type: Date,
      default: undefined,
    },
    startSelection: {
      type: Boolean,
      default: true,
    },
    onSelectDate: {
      type: Function,
      required: true,
    },
  },
  computed: {
    formattedDay() {
      return `0${this.day}`.slice(-2);
    },
  },
  methods: {
    handleDateSelect() {
      this.onSelectDate(this.startSelection, this.day);
    },
    isDateSelected() {
      const date = moment().year(this.year).month(this.month).date(this.day);
      return this.startSelection
        ? !!this.startDate &&
            date.isSame(this.startDate, "date") &&
            date.isSame(this.startDate, "month") &&
            date.isSame(this.startDate, "year")
        : date.isSame(this.endDate, "date") &&
            date.isSame(this.endDate, "month") &&
            date.isSame(this.endDate, "year") &&
            !!this.endDate;
    },
    isDateBetweenStartAndEnd() {
      if (this.singleDate) return false;
      const date = moment().year(this.year).month(this.month).date(this.day);
      if (!this.startDate || !this.endDate) return false;
      return (
        (date.isSame(this.startDate, "date") &&
          date.isSame(this.startDate, "month") &&
          date.isSame(this.startDate, "year")) ||
        (date.isSame(this.endDate, "date") &&
          date.isSame(this.endDate, "month") &&
          date.isSame(this.endDate, "year")) ||
        date.isBetween(this.startDate, this.endDate, "date")
      );
    },
    isDateClickable() {
      if (this.singleDate) return true;
      const date = moment().year(this.year).month(this.month).date(this.day);
      return this.startSelection
        ? !this.endDate || (date.isBefore(this.endDate, "date") && !!this.endDate)
        : date.isAfter(this.startDate, "date") && !!this.startDate;
    },
    isStartDate() {
      if (this.singleDate) return false;
      const date = moment().year(this.year).month(this.month).date(this.day);
      return !!this.startDate && date.isSame(this.startDate, "date") && !!this.endDate;
    },
    isEndDate() {
      if (this.singleDate) return false;
      const date = moment().year(this.year).month(this.month).date(this.day);
      return !!this.endDate && date.isSame(this.endDate, "date") && !!this.startDate;
    },
  },
};
</script>
<style scope lang="scss">
.calendar-day {
  width: 14.25% !important;
  min-width: 14.25% !important;
  flex: 0 !important;
}
</style>
