<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Tellinglijst accountancy</h1>
          </div>
        </div>
        <div class="mt-4 mb-4 d-flex justify-content-between align-items-center">
          <tabs
            :items="['Schapen', 'Geiten']"
            :active-index="selectedAnimalType"
            @change="onTabChange"
          />
          <div>
            <period-filter :period="period" @change="handlePeriodChange" />
            <export-options :url="'statistics/count'" :period="period"></export-options>
          </div>
        </div>
        <div v-if="selectedAnimalType === 1">
          <div class="row">
            <div class="col-12">
              <Table
                :columns="columns"
                :data="goat"
                :key-extractor="keyExtractor"
                @filterChange="onFilterChange"
              >
                <template #empty>Geen dagaantallen </template>
              </Table>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-12">
              <Table
                :columns="columns"
                :data="sheep"
                :key-extractor="keyExtractor"
                @filterChange="onFilterChange"
              >
                <template #empty>Geen dagaantallen </template>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PeriodFilter from "@/components/Lists/PeriodFilter";
import ExportOptions from "@/components/Lists/ExportOptions";
import Tabs from "@/components/Tabs";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";

export default {
  name: "TellinglijstAccountancy",
  components: {
    Tabs,
    PeriodFilter,
    ExportOptions,
  },
  mixins: [pageLoader, defaultListPage("", "count")],
  data() {
    return {
      selectedAnimalType: 0,
      sheep: [],
      goat: [],
      columns: {
        title: { title: "" },
        died: { title: "Gestorven", filter: { type: "number" } },
        exported: { title: "Geëxporteerd", filter: { type: "number" } },
        final: { title: "Final", filter: { type: "number" } },
        imported: { title: "Geïmporteerd", filter: { type: "number" } },
        initial: { title: "Initieel", filter: { type: "number" } },
      },
    };
  },
  computed: {
    ...mapState({
      dailyNumbers: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },

  watch: {
    statistics() {
      const { sheep, goat } = this.statistics;

      const tempSheepArray = [];
      const tempGoatArray = [];

      Object.keys(sheep).forEach((schaap) => {
        tempSheepArray.push({
          title: this.returnKeyString(schaap),
          died: sheep[schaap].died,
          exported: sheep[schaap].exported,
          final: sheep[schaap].final,
          imported: sheep[schaap].imported,
          initial: sheep[schaap].initial,
        });
      });

      Object.keys(goat).forEach((geit) => {
        tempGoatArray.push({
          title: this.returnKeyString(geit),
          died: goat[geit].died,
          exported: goat[geit].exported,
          final: goat[geit].final,
          imported: goat[geit].imported,
          initial: goat[geit].initial,
        });
      });

      this.sheep = tempSheepArray;
      this.goat = tempGoatArray;
    },
  },

  methods: {
    onTabChange(index) {
      switch (index) {
        case 0:
          this.selectedAnimalType = 0;
          break;
        case 1:
          this.selectedAnimalType = 1;
          break;
        default:
          break;
      }
    },
    keyExtractor(animal) {
      return animal.title;
    },
    returnKeyString(key) {
      let keyString = "";

      switch (key) {
        case "female_below_7_months":
          keyString = "Vrw. jonge dieren < 7 mnd";
          break;
        case "female_above_1_year_below_2_years":
          keyString = "Vrw. jonge dieren 1 jaar tot 2 jaar";
          break;
        case "female_above_2_years":
          keyString = "Vrw. jonge dieren > 2 jaar";
          break;
        case "female_above_1_year":
          keyString = "Vrw. jonge dieren > 1 jaar";
          break;
        case "female_above_7_months_below_1_year":
          keyString = "Vrw. jonge dieren 7 mnd tot 1 jaar";
          break;
        case "male_above_4_months":
          keyString = "Man. dieren > 4 maanden";
          break;
        case "male_below_4_months":
          keyString = "Man. dieren < 4 maanden";
          break;

        default:
          return "";
      }

      return keyString;
    },
  },
};
</script>
<style></style>
