<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Spenen</h1>
          </div>
        </div>
        <b-form-checkbox
          id="included-checkbox"
          v-model="includes"
          name="included-checkbox"
          :value="true"
          :unchecked-value="false"
          class="mt-2 mb-2"
        >
          Inclusief niet aanwezige dieren
        </b-form-checkbox>
        <div class="mt-2 mb-2 d-flex align-items-center">
          <search-input class="mr-auto" @search="onSearch" />
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options :period="period" :url="'animals/weaned'"></export-options>
        </div>
        <div v-if="animals">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="animals"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #life_number="{ item: animal }">
                  <router-link
                    :to="{
                      path: '/passport/' + animal.id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ animal.animal_numbers.lifeNumber }}</span>
                    <span>{{ animal.animal_numbers.workNumber }}</span>
                  </router-link>
                </template>
                <template #animal_type="{ item: animal }">
                  {{ animal.human_animal_type }}
                </template>
                <template #gender="{ item: animal }">
                  {{ animal.human_gender }}
                </template>
                <template #mother_life_number="{ item: animal }">
                  <router-link
                    :to="{
                      path: '/passport/' + animal.mother_id,
                    }"
                    >{{ animal.mother_life_number }}</router-link
                  >
                </template>
                <template #empty>Geen spenen</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter";
import ExportOptions from "@/components/Lists/ExportOptions";
import SearchInput from "@/components/SearchInput";
import animalTypes from "@/enums/animalTypes";

export default {
  name: "Weaning",
  components: {
    PeriodFilter,
    ExportOptions,
    SearchInput,
  },
  mixins: [pageLoader, defaultListPage("animals/weaned", undefined, true)],
  data() {
    return {
      columns: {
        life_number: "(Werk)nummer",
        animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        date_of_birth: { title: "Geboren", filter: { type: "date" } },
        weaned_at: { title: "Gespeend op / speendatum", filter: { type: "date" } },
      },
    };
  },
  computed: {
    ...mapState({
      animals: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },

  methods: {
    keyExtractor(animal) {
      return animal.id;
    },
  },
};
</script>
<style></style>
