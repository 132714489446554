import Vue from "vue";

import { axiosInstance } from "@/services/axios";

import { applyFilters } from "@/helpers";

export default {
  namespaced: true,

  state: {
    items: [],
    item: {},
    pagination: {
      count: 0,
      pageCount: 0,
      pageSize: 3,
      pageNum: 1,
    },
  },
  actions: {
    fetchGroups({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setItems", { resource: "groups", items: [] }, { root: true });
      }
      const url = applyFilters("/groups", options.filter);

      return axiosInstance.get(url).then((res) => {
        const groups = res.data.data.map((g) => g.attributes);

        commit("setItems", { resource: "groups", items: groups }, { root: true });
        // commit("setPagination", { count, pageCount });
        return state.items;
      });
    },
    fetchAnimalById({ state, commit }, animalId) {
      commit("setItem", { resource: "groups", item: {} }, { root: true });
      return axiosInstance.get(`/groups/${animalId}/weighings`).then((res) => {
        const animal = res.data;
        commit("setItem", { resource: "groups", item: animal }, { root: true });
        return state.item;
      });
    },
    initializePagesFromQuery({ commit }, { pageSize, pageNum }) {
      commit("setPage", pageNum);
      commit("setPageSize", pageSize);
    },
  },
  mutations: {
    setPagination(state, { count, pageCount }) {
      Vue.set(state.pagination, "count", count);
      Vue.set(state.pagination, "pageCount", pageCount);
    },
    setPage(state, page) {
      Vue.set(state.pagination, "pageNum", page);
    },
    setPageSize(state, pageSize) {
      Vue.set(state.pagination, "pageSize", pageSize);
    },
  },
};
