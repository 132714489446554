import Vue from "vue";
import moment from "moment";

import { axiosInstance } from "@/services/axios";

import { applyFilters } from "@/helpers";

export default {
  namespaced: true,

  state: {
    items: [],
    missingItems: [],
    flaggedItems: [],
    item: {},
    pagination: {
      count: 0,
      pageCount: 0,
      pageSize: 3,
      pageNum: 1,
    },
  },
  getters: {
    missingRecent: (state) => {
      // TODO: better error handling
      if (!state.missingItems.length) return [];
      return state.missingItems.transport
        .filter(
          (transport) =>
            transport.gebeurtenisdatum !== null &&
            moment(transport.gebeurtenisdatum, "DD-MM-YYYY") > moment().subtract(14, "days"),
        )
        .sort(function (a, b) {
          return a.gebeurtenisdatum == b.gebeurtenisdatum
            ? 0
            : a.gebeurtenisdatum < b.gebeurtenisdatum
            ? 1
            : -1;
        });
    },
  },
  actions: {
    fetchMessages({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setItems", { resource: "messages", items: [] }, { root: true });
      }
      const url = applyFilters("/messages", options.filter);

      return axiosInstance.get(url).then((res) => {
        const messages = res.data;

        commit("setItems", { resource: "messages", items: messages }, { root: true });
        // commit("setPagination", { count, pageCount });
        return state.items;
      });
    },
    fetchMissingMessages({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setMissingItems", []);
      }
      const url = applyFilters("/messages/missing", options.filter);

      return axiosInstance.get(url).then((res) => {
        const messages = res.data;

        commit("setMissingItems", messages);
        return state.missedItems;
      });
    },
    fetchMissingMessagesWithAnimals({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setMissingItems", []);
      }
      const url = applyFilters("/messages/missing_with_animals", options.filter);

      return axiosInstance.get(url).then((res) => {
        const messages = res.data;

        commit("setMissingItems", messages);
        return state.missedItems;
      });
    },
    fetchFlaggedMessages({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setFlaggedItems", []);
      }
      const url = applyFilters("/messages/flagged", options.filter);

      return axiosInstance.get(url).then((res) => {
        const messages = res.data;

        commit("setFlaggedItems", messages);
        return state.flaggedItems;
      });
    },
    fetchAnimalById({ state, commit }, animalId) {
      commit("setItem", { resource: "messages", item: {} }, { root: true });
      return axiosInstance.get(`/messages/${animalId}/weighings`).then((res) => {
        const animal = res.data;
        commit("setItem", { resource: "messages", item: animal });
        return state.item;
      });
    },
    initializePagesFromQuery({ commit }, { pageSize, pageNum }) {
      commit("setPage", pageNum);
      commit("setPageSize", pageSize);
    },
  },
  mutations: {
    setMissingItems(state, items) {
      Vue.set(state, "missingItems", items);
    },
    setFlaggedItems(state, items) {
      Vue.set(state, "flaggedItems", items);
    },
    setPagination(state, { count, pageCount }) {
      Vue.set(state.pagination, "count", count);
      Vue.set(state.pagination, "pageCount", pageCount);
    },
    setPage(state, page) {
      Vue.set(state.pagination, "pageNum", page);
    },
    setPageSize(state, pageSize) {
      Vue.set(state.pagination, "pageSize", pageSize);
    },
  },
};
