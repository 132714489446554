<template>
  <Table :columns="columns" :data="imports" :key-extractor="keyExtractor">
    <template #documents="{ item: im }">
      <a
        v-if="im.attributes.transport_document"
        class="transport-download-link"
        target="_blank"
        @click="onTransportDocumentClick(im)"
      >
        Transport
      </a>
    </template>
    <template #empty>Er is nog geen aanvoer gedaan</template>
  </Table>
</template>
<script>
export default {
  name: "ImportsTable",
  data() {
    return {
      imports: [],
      error: "",
      query: "",
      columns: {
        "attributes.scheduled": "Datum",
        "attributes.carrier_name": "Transporteur",
        "attributes.origination_name": "Herkomst",
        documents: "Documenten",
      },
    };
  },
  created() {
    this.$http.secured
      .get("/imports")
      .then((response) => {
        this.imports = response.data.data;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    onTransportDocumentClick(im) {
      this.$http.secured
        .get(`/imports/${im.id}/transport_document`, {
          responseType: "blob",
        })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "transport_document.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    keyExtractor(im) {
      return im.attributes.id;
    },
  },
};
</script>
<style></style>
