<template>
  <div v-if="loading">
    <AppSpinner />
  </div>
  <div v-else class="d-inline-block">
    <b-dropdown
      id="dropdown-1"
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
      dropright
    >
      <template #button-content>
        <span class="download-link download">
          <img src="/img/icons/download.svg" width="30px" height="30px"
        /></span>
      </template>

      <b-dropdown-item @click="onPdfClick()">
        Download als
        <span class="ml-1 text-white text-bold">PDF</span>
      </b-dropdown-item>
      <b-dropdown-item @click="onExcelClick()">
        Download als
        <span class="ml-1 text-white text-bold">Excel</span>
      </b-dropdown-item>
    </b-dropdown>
    <span class="print-link download d-inline mr-2"></span>
  </div>
</template>
<script>
import moment from "moment";
import { camelToSnakeCase, applyFilters } from "@/helpers";

export default {
  name: "ExportOptions",
  props: ["url", "present_on", "period", "columns", "includes"],
  data() {
    return {
      loading: false,
      export_options: [
        {
          label: "Dit jaar",
          value: "test",
        },
      ],
    };
  },
  methods: {
    removeFilter(evt) {
      this.$emit("input", evt.target.value);
    },

    onExcelClick() {
      const route = this.$route.fullPath.split("?");
      const fileName = this.generateFileName();
      this.loading = true;
      this.$http.secured
        .get(
          applyFilters(`${this.url}.xlsx?${camelToSnakeCase(route[1])}`, {
            filter_animals: this.includes,
            present_on: this.present_on,
            start_date: moment(this.period.startDate).format("DD-MM-YYYY"),
            end_date: moment(this.period.endDate).format("DD-MM-YYYY"),
            columns: this.columns ? this.columns.map((column) => column) : null,
          }),
          {
            responseType: "blob",
          },
        )
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${fileName}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading = false;
        });
    },
    onPdfClick() {
      const route = this.$route.fullPath.split("?");
      const fileName = this.generateFileName();
      this.loading = true;
      this.$http.secured
        .get(
          applyFilters(`${this.url}.pdf?${camelToSnakeCase(route[1])}`, {
            filter_animals: this.includes,
            present_on: this.present_on,
            start_date: moment(this.period.startDate).format("DD-MM-YYYY"),
            end_date: moment(this.period.endDate).format("DD-MM-YYYY"),
            columns: this.columns ? this.columns.map((column) => column) : null,
          }),
          {
            responseType: "blob",
          },
        )
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${fileName}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading = false;
        });
    },
    generateFileName() {
      const routeName = camelToSnakeCase(this.$route.name);
      if (this.$route.query.startDate && this.$route.query.endDate) {
        return `${routeName}-start-${this.$route.query.startDate}-end-${this.$route.query.endDate}`;
      }
      return routeName;
    },
  },
};
</script>
<style></style>
