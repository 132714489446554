<template>
  <div class="doses">
    <div v-for="(dose, index) in doses" :key="index" class="itemContainer">
      <div v-if="promptRemove && promptRemoveIndex === index" class="promptRemoveContainer">
        <span>Zeker weten?</span>
        <div class="promptBtnContainer">
          <button class="btn cancel" @click="promptRemove = false">Nee</button>
          <button class="btn btn-success proceed" @click="handleRemove(index)">Ja</button>
        </div>
      </div>
      <div class="inputWrapper">
        <div v-if="selectedOptionType !== 'edit'" class="inputWrapper">
          <input v-model="doses[index].medicineName" :disabled="true" class="disabledInput" />
          <span class="icon">Medicijn</span>
        </div>
        <b-dropdown
          v-else
          variant="light"
          class="dropdown-input"
          toggle-class="text-decoration-none"
        >
          <template #button-content>
            {{ dose.medicineName }}
          </template>
          <b-dropdown-item
            v-for="medicine in medicines"
            :key="medicine.id"
            @click="
              updateDose({ target: { value: medicine.id } }, index, 'medicineId') ||
                updateDose({ target: { value: medicine.attributes.name } }, index, 'medicineName')
            "
          >
            {{ medicine.attributes.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="inputWrapper">
        <input
          v-model="doses[index].amount"
          :disabled="selectedOptionType !== 'edit'"
          :class="selectedOptionType !== 'edit' ? 'disabledInput' : 'input'"
          @input="updateDose($event, index, 'amount')"
        />
        <span class="icon">doses</span>
      </div>
      <div class="inputWrapper">
        <input
          v-model="doses[index].date"
          :disabled="selectedOptionType !== 'edit'"
          :class="selectedOptionType !== 'edit' ? 'disabledInput' : 'input'"
          @input="updateDose($event, index, 'date')"
        />
        <span class="icon">
          <b-icon-calendar2-check class="icon" />
        </span>
      </div>
      <button
        v-if="selectedOptionType === 'remove'"
        class="btn remove"
        @click="handlePromptRemove(index)"
      >
        <b-icon-trash-fill class="removeIcon" />
      </button>
    </div>
    <div v-if="selectedOptionType === 'add' && addInput === true" class="addInputs">
      <period-filter-modal
        arrow-anabled
        :single-date="true"
        :open="open"
        default-view-type="days"
        :period="inputValue.date ? inputValue.date : new Date()"
        @change="handleChange"
        @cancel="open = false"
      ></period-filter-modal>
      <div class="rowInputs">
        <div class="inputWrapper">
          <b-dropdown variant="light" class="dropdown-input" toggle-class="text-decoration-none">
            <template #button-content>
              {{ currentMedicineName }}
            </template>
            <b-dropdown-item
              v-for="medicine in medicines"
              :key="medicine.id"
              @click="setMedicineForIndex({ target: { value: medicine.id } })"
            >
              {{ medicine.attributes.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="inputWrapper">
          <input v-model="inputValue.amount" placeholder="amount" type="text" class="input" />
          <span class="icon">hoeveelheid</span>
        </div>
        <div class="inputWrapper">
          <input
            v-model="inputValue.date"
            type="text"
            placeholder="date"
            class="input"
            @click="open = true"
          />
          <span class="icon">
            <b-icon-calendar2-check class="icon" />
          </span>
        </div>
        <b-button class="btn button" variant="warning" @click="submitInput"
          >Toediening bevestigen</b-button
        >
      </div>
    </div>
    <button v-if="selectedOptionType === 'add'" class="btn addInputButton" @click="addInput = true">
      Nieuwe toevoegen<b-icon-plus class="plusIcon" />
    </button>
  </div>
</template>

<script>
import { BIconTrashFill, BIconPlus, BIconCalendar2Check } from "bootstrap-vue";
import moment from "moment";
import PeriodFilterModal from "@/components/Lists/PeriodFilter/PeriodFilterModal.vue";

export default {
  name: "DosesModal",
  components: {
    BIconTrashFill,
    BIconPlus,
    BIconCalendar2Check,
    PeriodFilterModal,
  },
  props: {
    selectedAddOption: {
      type: String,
      default: "",
    },
    selectedEditOption: {
      type: String,
      default: "",
    },
    selectedRemoveOption: {
      type: String,
      default: "",
    },
    selectedOptionType: {
      type: String,
      default: "",
    },
    selectedAnimalsDoses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      medicines: [],
      open: false,
      doses: [],
      currentMedicine: null,
      inputValue: { amount: "", date: "" },
      promptRemove: false,
      promptRemoveIndex: 0,
      addInput: false,
    };
  },
  computed: {
    currentMedicineName() {
      return (
        this.currentMedicine?.attributes.name ?? this.medicines[0]?.attributes.name ?? "Medicine"
      );
    },
  },
  watch: {
    doses() {
      this.$emit("passData", this.doses);
    },
  },
  created() {
    if (this.selectedOptionType !== "add") {
      this.doses = this.selectedAnimalsDoses.map((dose) => {
        return {
          doseId: dose.id,
          medicineId: dose.relationships.medicine.data.id,
          medicineName: dose.attributes.medicine_name,
          amount: dose.attributes.amount,
          unit: dose.attributes.unit,
          date: moment(dose.attributes.dose_date, "DD-MM-YYYY").format("DD-MM-YYYY"),
        };
      });
    }

    this.$http.secured
      .get("/medicines")
      .then((response) => {
        this.medicines = response.data.data;
        // eslint-disable-next-line prefer-destructuring
        this.currentMedicine = this.medicines[0];
      })
      .catch((error) =>
        this.setError(error, "Er ging iets mis bij het ophalen van de medicijnen."),
      );
  },
  methods: {
    handleChange(data) {
      this.inputValue.date = moment(data.startDate).format("DD-MM-YYYY");
      this.open = false;
    },
    updateDose(event, index, key) {
      const doses = [...this.doses];
      doses[index][key] = event.target.value;
      this.doses = doses;
    },
    submitInput() {
      if (!this.currentMedicine || !this.inputValue.amount || !this.inputValue.date) return;

      this.addInput = false;
      this.doses.push({
        doseId: "new-item",
        medicineId: this.currentMedicine.id,
        medicineName: this.currentMedicine.attributes.name,
        amount: this.inputValue.amount,
        unit: "ml",
        date: this.inputValue.date,
      });
      // eslint-disable-next-line prefer-destructuring
      this.currentMedicine = this.medicines[0];
      this.inputValue = { amount: "", date: "" };
    },
    setMedicineForIndex(event) {
      this.currentMedicine = this.medicines.find((medicine) => medicine.id === event.target.value);
    },
    handleRemove(index) {
      this.doses.splice(index, 1);
      this.promptRemove = false;
    },
    handlePromptRemove(index) {
      this.promptRemove = true;
      this.promptRemoveIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.doses {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}
.addInputs {
  display: flex;
  flex-direction: column;

  .rowInputs {
    display: flex;
  }
}
.animalModal .modalBody .inputWrapper .input + .icon,
.animalModal .modalBody .inputWrapper .disabledInput + .icon {
  padding: 11px;
}
</style>

<style lang="scss">
.dropdown-input {
  width: 100%;
}
.dropdown-input > button {
  height: 43px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: none !important;
}
</style>
