<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">
              RVO Verschillen
              <small v-if="animals"> ({{ pagination.totalCount }}) </small>
            </h1>
          </div>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options
            :period="period"
            :url="'default_lists/rvo_comparison/all'"
          ></export-options>
        </div>
        <div v-if="animals">
          <div class="row">
            <div class="col-12">
              <Table :columns="columns" :data="animals" :key-extractor="keyExtractor">
                <template #life_number="{ item: animal }">
                  <router-link
                    :to="{
                      path: '/passport/' + animal.id + '/rvo',
                    }"
                  >
                    {{ animal.life_number }}
                  </router-link>
                </template>
                <template #group="{ item: animal }">
                  <router-link v-if="animal.group_id" :to="'/groups/' + animal.group_id">
                    {{ animal.group_name }}
                  </router-link>
                  <div v-else>—</div>
                </template>
                <template #empty>
                  <template v-if="animals.length === 0">Geen dieren</template>
                  <template v-else>Geen dieren gevonden</template>
                </template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter";
import ExportOptions from "@/components/Lists/ExportOptions";

export default {
  name: "RvoComparison",
  components: {
    PeriodFilter,
    ExportOptions,
  },
  mixins: [pageLoader, defaultListPage("rvo_comparisons/all")],
  data() {
    return {
      columns: {
        life_number: "(Werk)nummer",

        human_animal_type: "Soort",
        human_gender: "Geslacht",
        date_of_birth: "Geboren",
        group: "Group",
      },
    };
  },
  computed: {
    ...mapState({
      animals: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(animal) {
      return animal.id;
    },
  },
};
</script>
<style></style>
