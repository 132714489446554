<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Dagaantallen</h1>
          </div>
        </div>
        <div v-if="statistics" class="row">
          <core-stat-card category="Gemiddelde cat. 550" :value="statistics['550'] | roundToFull" />
          <core-stat-card category="Gemiddelde cat. 551" :value="statistics['551'] | roundToFull" />
          <core-stat-card category="Gemiddelde cat. 552" :value="statistics['552'] | roundToFull" />
        </div>
        <div class="mt-4 mb-4 d-flex justify-content-between align-items-center">
          <tabs :items="['Geiten', 'Schapen']" :active-index="1" @change="onTabChange" />
          <div>
            <period-filter :period="period" @change="handlePeriodChange" />
            <export-options
              :period="period"
              :url="'default_lists/daily_numbers/animal_type_3'"
            ></export-options>
          </div>
        </div>
        <div v-if="dailyNumbers">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="dailyNumbers"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #created_at="{ item: dose }">
                  {{ dose.human_created_at }}
                </template>
                <template #empty>Geen dagaantallen </template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter";
import ExportOptions from "@/components/Lists/ExportOptions";
import CoreStatCard from "@/components/Cards/CoreStatCard";
import Tabs from "@/components/Tabs";

export default {
  name: "DailyNumbersType3Animal",
  components: {
    Tabs,
    PeriodFilter,
    ExportOptions,
    CoreStatCard,
  },
  mixins: [pageLoader, defaultListPage("daily_numbers/animal_type_3", "daily_numbers")],
  data() {
    return {
      columns: {
        created_at: { title: "Datum", filter: { type: "date" } },
        category_1: { title: "550", filter: { type: "number" } },
        category_2: { title: "551", filter: { type: "number" } },
        category_3: { title: "552", filter: { type: "number" } },
      },
    };
  },
  computed: {
    ...mapState({
      dailyNumbers: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    onTabChange(index) {
      switch (index) {
        case 0:
          this.$router.push({
            path: "/lists/daily-numbers-type-4-animal",
            params: this.$route.params,
          });
          break;
        case 1:
          this.$router.push({
            path: "/lists/daily-numbers-type-3-animal",
            params: this.$route.params,
          });
          break;
        default:
          break;
      }
    },
    keyExtractor(dailyNumber) {
      return dailyNumber.id;
    },
  },
};
</script>
<style></style>
