<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Gegeven medicijnen</h1>
          </div>
        </div>
        <div v-if="statistics" class="row">
          <core-stat-card
            category="Gemiddelde aantal toedieningen per dier"
            :value="statistics.average_medicine_doses | roundDecimal"
          />
          <core-stat-card
            category="Meest gebruikt medicijn"
            :value="statistics.most_used_medicine"
          />
        </div>
        <b-form-checkbox
          id="included-checkbox"
          v-model="includes"
          name="included-checkbox"
          :value="true"
          :unchecked-value="false"
          class="mt-2 mb-2"
        >
          Inclusief niet aanwezige dieren
        </b-form-checkbox>
        <div class="mt-2 mb-2 d-flex align-items-center">
          <search-input class="mr-auto" column="animal_life_number" @search="onSearch" />
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options :period="period" :url="'default_lists/medicines/doses'"></export-options>
        </div>
        <div v-if="doses">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="doses"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #animal_life_number="{ item: dose }">
                  <router-link
                    :to="{
                      path: '/passport/' + dose.animal_id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ dose.animal_numbers.lifeNumber }}</span>
                    <span>{{ dose.animal_work_number }}</span>
                  </router-link>
                </template>
                <template #animal_animal_type="{ item: dose }">
                  {{ dose.animal_human_animal_type }}
                </template>
                <template #amount="{ item: dose }"> {{ dose.amount }} {{ dose.unit }} </template>
                <template #empty>Geen giften</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter";
import ExportOptions from "@/components/Lists/ExportOptions";
import CoreStatCard from "@/components/Cards/CoreStatCard";
import SearchInput from "@/components/SearchInput";
import animalTypes from "@/enums/animalTypes";

export default {
  name: "Medicines",
  components: {
    PeriodFilter,
    ExportOptions,
    CoreStatCard,
    SearchInput,
  },
  mixins: [pageLoader, defaultListPage("medicines/doses", "medical")],
  data() {
    return {
      columns: {
        animal_life_number: "(Werk)nummer",
        animal_animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        medicine_name: {
          title: "Medicijn",
          filter: {
            type: "async",
            values: this.fetchItems({ url: "medicines/usage", filter: {} }).then((result) =>
              result.map((item) => ({
                value: encodeURIComponent(item.name),
                label: item.name,
              })),
            ),
          },
        },
        amount: { title: "Hoeveelheid", filter: { type: "number" } },
        dose_date: { title: "Datum", filter: { type: "date" } },
      },
    };
  },
  computed: {
    ...mapState({
      doses: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(dose) {
      return dose.id;
    },
  },
};
</script>
<style></style>
