<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div v-if="!selectedMessage">
          <div class="row justify-content-center">
            <div class="col-12">
              <h1 class="page-title">RVO berichten</h1>
            </div>
          </div>
          <div class="mt-4 mb-4 d-flex justify-content-between align-items-center">
            <tabs
              :items="['Alle', 'Missend', 'Vlaggen']"
              :active-index="selectedTabIndex"
              @change="onTabChange"
            />
          </div>
          <AllTable v-if="selectedTabIndex === 0" />
          <MissingTable v-if="selectedTabIndex === 1" @select-message="selectMessage" />
          <FlaggedTable v-if="selectedTabIndex === 2" />
        </div>
        <div v-else>
          <div class="row justify-content-center">
            <div class="col-12">
              <span class="back-link" @click="deselectMessage">
                <div class="icon-chevron-back"></div>
                Terug naar berichten
              </span>
              <h1 class="page-title">
                Missende melding oplossen - {{ selectedMessage.signalering_code_reden }}
              </h1>
            </div>
          </div>
          <div v-if="selectedMessageType == 'AAN'">
            <Export :message="selectedMessage" />
          </div>
          <div v-if="selectedMessageType == 'AFV'">
            <Import :message="selectedMessage" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import AllTable from "@/components/Messages/AllTable.vue";
import MissingTable from "@/components/Messages/MissingTable.vue";
import FlaggedTable from "@/components/Messages/FlaggedTable.vue";
import Export from "@/components/Messages/Export.vue";
import Import from "@/components/Messages/Import.vue";
import Tabs from "@/components/Tabs.vue";

import pageLoader from "@/mixins/pageLoader";

export default {
  name: "Messages",
  components: {
    Tabs,
    AllTable,
    MissingTable,
    FlaggedTable,
    Export,
    Import,
  },
  mixins: [pageLoader],
  data() {
    return {
      selectedTabIndex: 0,
      selectedMessage: "",
      selectedMessageType: "",
      animalLifeNumbers: [],
      tabIndex: 0,
      error: "",
    };
  },
  created() {
    if (this.$route.params.tab !== undefined) {
      switch (this.$route.params.tab) {
        case "all":
          this.tabIndex = 0;
          break;
        case "missing":
          this.tabIndex = 1;
          break;
        case "flags":
          this.tabIndex = 2;
          break;
        default:
          this.tabIndex = 0;
      }
    } else {
      this.tabIndex = 0;
    }
    this.pageLoader_resolveData();
  },
  methods: {
    selectMessage(message) {
      this.selectedMessage = message;
      this.selectedMessageType = message.berichttype;
    },
    deselectMessage() {
      this.selectedMessage = "";
      this.selectedMessageType = "";
    },
    onTabChange(tabIndex) {
      this.selectedTabIndex = tabIndex;
    },
  },
};
</script>
<style></style>
