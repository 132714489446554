<template>
  <multi-select-dropdown
    :open="open"
    :header-text="headerText"
    :dropdown-class="dropdownClass"
    :items="items"
    :initial-checked="initialChecked"
    @change="$listeners.change"
    @cancel="$listeners.cancel"
  ></multi-select-dropdown>
</template>
<script>
import MultiSelectDropdown from "@/components/Dropdowns/MultiSelectDropdown";

export default {
  components: {
    MultiSelectDropdown,
  },
  props: {
    open: Boolean,
    headerText: String,
    dropdownClass: String,
    asyncItems: Promise,
  },
  data() {
    return {
      items: [],
      initialChecked: [],
    };
  },
  watch: {
    asyncItems(asyncItems) {
      this.updateItems(asyncItems);
    },
  },
  created() {
    this.updateItems(this.asyncItems);
  },
  methods: {
    updateItems(asyncItems) {
      asyncItems.then((items) => {
        this.items = items;
        this.initialChecked = items.map((item) => item.value);
      });
    },
  },
};
</script>
