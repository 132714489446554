<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Scanfouten</h1>
          </div>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options :period="period" :url="'default_lists/scan_errors/all'"></export-options>
        </div>
        <div v-if="scanErrors">
          <div class="row">
            <div class="col-12">
              <Table :columns="columns" :data="scanErrors" :key-extractor="keyExtractor">
                <template #empty>
                  <template v-if="scanErrors.length === 0">Geen scanfouten</template>
                  <template v-else>Geen scanfouten gevonden</template>
                </template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter";
import ExportOptions from "@/components/Lists/ExportOptions";

export default {
  name: "ScanErrors",
  components: {
    PeriodFilter,
    ExportOptions,
  },
  mixins: [pageLoader, defaultListPage("scan_errors/all")],
  data() {
    return {
      columns: {
        life_number: "(Werk)nummer",
        human_created_at: "Datum",
        status: "Status",
        source: "Actie",
      },
    };
  },
  computed: {
    ...mapState({
      scanErrors: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(scanError) {
      return scanError.id;
    },
  },
};
</script>
<style></style>
