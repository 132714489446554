<template>
  <button
    :class="{
      'calendar-option': true,
      'calendar-option-selected': isDateSelected(),
      'calendar-option-between-dates': isDateBetweenStartAndEnd(),
      'calendar-option-disabled': !isDateClickable(),
      'calendar-option-is-start-date': isStartDate(),
      'calendar-option-is-end-date': isEndDate(),
    }"
    @click="handleDateSelect()"
  >
    Kwartaal {{ quarter }}
  </button>
</template>
<script>
import moment from "moment";

export default {
  name: "Quarter",
  props: {
    singleDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    quarter: {
      type: Number,
      default: 1,
      required: true,
    },
    year: {
      type: Number,
      default: 1970,
      required: true,
    },
    startDate: {
      type: Date,
      default: undefined,
    },
    endDate: {
      type: Date,
      default: undefined,
    },
    startSelection: {
      type: Boolean,
      default: true,
    },
    onSelectDate: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleDateSelect() {
      this.onSelectDate(this.startSelection, this.quarter, this.year);
    },
    isDateSelected() {
      const date = moment().quarter(this.quarter).year(this.year);
      if (this.startSelection) {
        return (
          !!this.startDate &&
          date.isSame(this.startDate, "quarter") &&
          date.isSame(this.startDate, "year")
        );
      }
      if (
        moment(this.startDate).isSame(this.endDate, "quarter") &&
        moment(this.startDate).isSame(this.endDate, "year")
      ) {
        return false;
      }
      return (
        date.isSame(this.endDate, "quarter") && date.isSame(this.endDate, "year") && !!this.endDate
      );
    },
    isDateBetweenStartAndEnd() {
      if (this.singleDate) return false;
      const date = moment().quarter(this.quarter).year(this.year);
      if (!this.startDate || !this.endDate) return false;
      if (
        moment(this.startDate).isSame(this.endDate, "quarter") &&
        moment(this.startDate).isSame(this.endDate, "year")
      ) {
        return false;
      }
      return (
        (date.isSame(this.startDate, "quarter") && date.isSame(this.startDate, "year")) ||
        (date.isSame(this.endDate, "quarter") && date.isSame(this.endDate, "year")) ||
        date.isBetween(this.startDate, this.endDate, "quarter")
      );
    },
    isDateClickable() {
      if (this.singleDate) return true;
      const date = moment().quarter(this.quarter).year(this.year);
      if (
        this.startSelection &&
        moment(this.startDate).isSame(this.endDate, "quarter") &&
        moment(this.startDate).isSame(this.endDate, "year")
      ) {
        return true;
      }
      return this.startSelection
        ? !this.endDate ||
            ((date.isBefore(this.endDate, "quarter") || date.isSame(this.endDate, "quarter")) &&
              !!this.endDate)
        : date.isAfter(this.startDate, "quarter") && !!this.startDate;
    },
    isStartDate() {
      if (this.singleDate) return false;
      const date = moment().quarter(this.quarter).year(this.year);
      if (
        moment(this.startDate).isSame(this.endDate, "quarter") &&
        moment(this.startDate).isSame(this.endDate, "year")
      ) {
        return false;
      }
      return !!this.startDate && date.isSame(this.startDate, "quarter") && !!this.endDate;
    },
    isEndDate() {
      if (this.singleDate) return false;
      const date = moment().quarter(this.quarter).year(this.year);
      if (
        moment(this.startDate).isSame(this.endDate, "quarter") &&
        moment(this.startDate).isSame(this.endDate, "year")
      ) {
        return false;
      }
      return !!this.endDate && date.isSame(this.endDate, "quarter") && !!this.startDate;
    },
  },
};
</script>
<style scope lang="scss"></style>
