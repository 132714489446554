<template>
  <div class="calendar-quarters">
    <div class="calendar-quarters-content">
      <div class="calendar-start-date">
        <month-year-selector
          :year="startYear"
          :show-month="false"
          :on-year-change="setYear"
          :start-selection="true"
        />
        <div class="calendar-options">
          <div class="calendar-options-row">
            <quarter
              :quarter="1"
              :year="startYear"
              :start-date="startDate"
              :end-date="endDate"
              :start-selection="true"
              :on-select-date="selectedDate"
              :single-date="singleDate"
            />
            <quarter
              :quarter="2"
              :year="startYear"
              :start-date="startDate"
              :end-date="endDate"
              :start-selection="true"
              :on-select-date="selectedDate"
              :single-date="singleDate"
            />
          </div>
          <div class="calendar-options-row">
            <quarter
              :quarter="3"
              :year="startYear"
              :start-date="startDate"
              :end-date="endDate"
              :start-selection="true"
              :on-select-date="selectedDate"
              :single-date="singleDate"
            />
            <quarter
              :quarter="4"
              :year="startYear"
              :start-date="startDate"
              :end-date="endDate"
              :start-selection="true"
              :on-select-date="selectedDate"
              :single-date="singleDate"
            />
          </div>
        </div>
      </div>
      <div v-if="!singleDate" class="calendar-end-date">
        <month-year-selector
          :year="endYear"
          :show-month="false"
          :on-year-change="setYear"
          :start-selection="false"
        />
        <div class="calendar-options">
          <div class="calendar-options-row">
            <quarter
              :quarter="1"
              :year="endYear"
              :start-date="startDate"
              :end-date="endDate"
              :start-selection="false"
              :on-select-date="selectedDate"
            />
            <quarter
              :quarter="2"
              :year="endYear"
              :start-date="startDate"
              :end-date="endDate"
              :start-selection="false"
              :on-select-date="selectedDate"
            />
          </div>
          <div class="calendar-options-row">
            <quarter
              :quarter="3"
              :year="endYear"
              :start-date="startDate"
              :end-date="endDate"
              :start-selection="false"
              :on-select-date="selectedDate"
            />
            <quarter
              :quarter="4"
              :year="endYear"
              :start-date="startDate"
              :end-date="endDate"
              :start-selection="false"
              :on-select-date="selectedDate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import MonthYearSelector from "./MonthYearSelector.vue";
import Quarter from "./Quarter.vue";

export default {
  name: "PeriodFilterQuarters",
  components: { MonthYearSelector, Quarter },
  props: {
    singleDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    startDate: {
      type: Date,
      default: undefined,
      required: false,
    },
    endDate: {
      type: Date,
      default: undefined,
      required: false,
    },
    startYear: {
      type: Number,
      default: undefined,
      required: false,
    },
    endYear: {
      type: Number,
      default: undefined,
      required: false,
    },
    onDatesChange: {
      type: Function,
      required: true,
      // default: undefined,
    },
    onYearChange: {
      type: Function,
      required: true,
      // default: undefined,
    },
  },
  methods: {
    setYear(isStartDate, year) {
      this.onYearChange({
        start: isStartDate ? year : undefined,
        end: !isStartDate ? year : undefined,
      });
    },
    selectedDate(isStartDate, quarter, year) {
      let start = this.startDate;
      let end = this.singleDate ? this.startDate : this.endDate;

      // Check if the end or start year is selected to uncheck these dates.
      // This makes it easier to change years.
      if (end && quarter === moment(end).quarter() && year === moment(end).year()) {
        this.onDatesChange({ start, end: undefined });
        return;
      }
      if (start && quarter === moment(start).quarter() && year === moment(start).year()) {
        this.onDatesChange({ start: undefined, end });
        return;
      }

      if (isStartDate || this.singleDate) {
        start = moment().year(year).quarter(quarter).startOf("quarter").toDate();
        if (this.singleDate) {
          end = moment().year(year).quarter(quarter).endOf("quarter").toDate();
        }
      } else {
        end = moment().year(year).quarter(quarter).endOf("quarter").toDate();
      }

      this.onDatesChange({ start, end });
    },
  },
};
</script>
<style scope lang="scss">
.calendar-quarters {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  > .calendar-quarters-content {
    display: flex;
    height: 100%;
    flex-direction: row;
    flex: 1;

    > div {
      padding: 16px;
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
