var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"page-title"},[_vm._v(" Alle dieren "),(_vm.animals)?_c('small',[_vm._v(" ("+_vm._s(_vm.pagination.totalCount)+") ")]):_vm._e()])])]),(_vm.statistics)?_c('div',{staticClass:"row"},[_c('core-stat-card',{attrs:{"category":"Vrouwelijke dieren","value":_vm.statistics.number_of_female_animals['3'] + _vm.statistics.number_of_female_animals['4']}}),_c('core-stat-card',{attrs:{"category":"Mannelijke dieren","value":_vm.statistics.number_of_male_animals['3'] + _vm.statistics.number_of_male_animals['4']}}),_c('core-stat-card',{attrs:{"category":"Onbekende dieren","value":_vm.statistics.number_of_genderless_animals['3'] +
          _vm.statistics.number_of_genderless_animals['4']}})],1):_vm._e(),_c('b-form-checkbox',{staticClass:"mt-2 mb-2",attrs:{"id":"included-checkbox","name":"included-checkbox","value":true,"unchecked-value":false},model:{value:(_vm.includes),callback:function ($$v) {_vm.includes=$$v},expression:"includes"}},[_vm._v(" Inclusief niet aanwezige dieren ")]),_c('div',{staticClass:"mt-4 mb-2 d-flex align-items-center"},[_c('search-input',{staticClass:"mr-auto",on:{"search":_vm.onSearch}}),_c('period-filter',{attrs:{"period":_vm.period,"single-date":true,"default-view-type":"days"},on:{"change":_vm.handlePeriodChange}}),_c('column-filter',{staticClass:"mr-4",attrs:{"items":_vm.columnFilterItems,"initial-checked":_vm.initialColumns},on:{"change":_vm.onColumnFilterChange}}),_c('export-options',{attrs:{"period":_vm.period,"url":'animals/paginated_index',"present_on":_vm.period.startDate,"columns":Object.keys(_vm.columns),"includes":!_vm.includes}})],1),(_vm.animals)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.animals,"loading":!_vm.pageLoader_isDataLoaded,"key-extractor":_vm.keyExtractor,"sort":_vm.sort},on:{"sort":_vm.onSort,"filterChange":_vm.onFilterChange},scopedSlots:_vm._u([{key:"checkAll",fn:function(){return [_c('td',[_c('b-form-checkbox',{attrs:{"id":"checkbox-all","name":"checkbox-all","value":"checked","unchecked-value":"unchecked"},model:{value:(_vm.allStatus),callback:function ($$v) {_vm.allStatus=$$v},expression:"allStatus"}})],1)]},proxy:true},{key:"checkItem",fn:function(ref){
          var animal = ref.item;
return [_c('td',[_c('b-form-checkbox',{attrs:{"value":("" + (animal.id))},model:{value:(_vm.selectedAnimals),callback:function ($$v) {_vm.selectedAnimals=$$v},expression:"selectedAnimals"}})],1)]}},{key:"life_number",fn:function(ref){
          var animal = ref.item;
return [_c('router-link',{staticClass:"animal_numbers",attrs:{"to":{
                  path: '/passport/' + animal.id,
                }}},[_c('span',[_vm._v(_vm._s(animal.animal_numbers.lifeNumber))]),_c('span',[_vm._v(_vm._s(animal.animal_numbers.workNumber))])])]}},{key:"gender",fn:function(ref){
                var animal = ref.item;
return [_vm._v(" "+_vm._s(animal.human_gender)+" ")]}},{key:"animal_type",fn:function(ref){
                var animal = ref.item;
return [_vm._v(" "+_vm._s(animal.human_animal_type)+" ")]}},{key:"group_name",fn:function(ref){
                var animal = ref.item;
return [(animal.group_id)?_c('router-link',{attrs:{"to":'/groups/' + animal.group_id}},[_vm._v(" "+_vm._s(animal.group_name)+" ")]):_vm._e()]}},{key:"breed",fn:function(ref){
                var animal = ref.item;
return [(animal.breed)?_c('div',[_vm._v(" "+_vm._s(animal.breed)+" ")]):_vm._l((animal.breed_percentages),function(breed,index){return _c('div',{key:index + breed.breed},[_c('p',[_vm._v(_vm._s(breed.breed)+" - "+_vm._s(breed.percentage)+"%")])])})]}},{key:"empty",fn:function(){return [_vm._v("Geen dieren")]},proxy:true}],null,false,3533220714)}),(_vm.pagination.totalPages > 1)?_c('paginate',{attrs:{"page-count":_vm.pagination.totalPages,"click-handler":_vm.fetchPaginatedAnimals,"prev-text":'Vorige',"next-text":'Volgende',"container-class":'pagination justify-content-center pt-3',"page-class":'page-item',"page-link-class":'page-link',"prev-class":'page-item prev-item',"prev-link-class":'page-link prev-link-item',"next-class":'page-item next-item',"next-link-class":'page-link next-link-item',"break-view-class":'break-view',"break-view-link-class":'break-view-link',"first-last-button":false},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)])]):_vm._e()],1),_c('EditBar',{attrs:{"selected-animals-array":_vm.selectedAnimals,"selected-animals-detailed":_vm.selectedAnimalsDetailed},on:{"deselect":_vm.handleEditBarDeselect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }