import Vue from "vue";
import axios from "axios";
import moment from "moment";
import PortalVue from "portal-vue";

import "./plugins/bootstrap-vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faMinus,
  faTrashAlt,
  faInfoSquare,
  faVenus,
  faMars,
  faGenderless,
  faMapMarkerCheck,
  faMapMarkerSlash,
  faFileCertificate,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueAxios from "vue-axios";
import VSwitch from "v-switch-case";
import vSelect from "vue-select";
import Paginate from "vuejs-paginate";
import Toasted from "vue-toasted";

// Gribb plugin
import App from "./App.vue";
import Table from "./components/shared/Table.vue";
import AppSpinner from "./components/shared/AppSpinner.vue";
import GribbMain from "./gribb-main";
import { securedAxiosInstance, plainAxiosInstance } from "./backend/axios";
import router from "./router";
import store from "./store";

library.add(
  faPlus,
  faMinus,
  faTrashAlt,
  faInfoSquare,
  faVenus,
  faMars,
  faGenderless,
  faMapMarkerCheck,
  faMapMarkerSlash,
  faFileCertificate,
);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
Vue.component("VSelect", vSelect);
Vue.component("Paginate", Paginate);

Vue.config.productionTip = false;
const API_URL = process.env.VUE_APP_API_URL;
axios.defaults.baseURL = API_URL;
axios.defaults.timeout = 110000;
axios.defaults.headers.post["Content-Type"] = "application/json";

Vue.component("AppSpinner", AppSpinner);
Vue.component("Table", Table);

// plugin setup
Vue.use(GribbMain);
Vue.use(VSwitch);
Vue.use(Toasted);
Vue.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance,
});
Vue.use(PortalVue);

Vue.filter("roundDecimal", (value) => {
  let number = value;
  if (!value) return "";
  if (value.toString().split(".")[1] && value.toString().split(".")[1].length > 2) {
    number = parseFloat(value).toFixed(2);
  }
  if (
    value.toString().split(".")[1] &&
    value.toString().split(".")[1].length == 1 &&
    value.toString().split(".")[1] == 0
  ) {
    return parseInt(value);
  }
  return number;
});

Vue.filter("roundToFull", (value) => {
  let number = value;
  if (!value) return "";

  if (value.toString().split(".")[1]) {
    number = parseFloat(value).toFixed(0);
  }

  return number;
});

Vue.filter("formatDate", (value) => {
  if (!value) return "";
  return moment(value).format("DD-MM-YYYY");
});

Vue.filter("dayPart", (value) => {
  if (!value) return "";
  if (value === "MORNING") return "Ochtend";
  if (value === "AFTERNOON") return "Middag";
  if (value === "EVENING") return "Avond";

  return "";
});

new Vue({
  router,
  store,
  created() {
    const userString = localStorage.getItem("user");
    const gribbUuid = localStorage.getItem("gribb-uuid");
    const jwt = localStorage.getItem("gribb-jwt");
    if (userString && gribbUuid && jwt) {
      const userData = JSON.parse(userString);
      this.$store.commit("auth/SET_USER_DATA", userData);
    }
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("logout");
        }
        return Promise.reject(error);
      },
    );
  },
  render: (h) => h(App),
}).$mount("#app");
