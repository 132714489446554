import Vue from "vue";

import { axiosInstance } from "@/services/axios";

export default {
  namespaced: true,

  state: {
    statistics: {},
  },
  actions: {
    fetchStatistics({ commit }) {
      return axiosInstance.get("/statistics").then((res) => {
        const statistics = res.data;
        commit("setStatistics", statistics);
        return statistics;
      });
    },
    updateStats({ state, commit }, meetupId) {
      commit("deleteResource", { resource: "meetups", itemId: meetupId });

      state.threads.data
        .filter((thread) => {
          return thread.meetup === meetupId;
        })
        .flatMap((thread) => {
          commit("deleteResource", { resource: "threads", itemId: thread._id });
          return thread.posts;
        })
        .map((postId) => {
          commit("deleteResource", { resource: "posts", itemId: postId });
        });
    },
  },
  mutations: {
    setStatistics(state, statistics) {
      Vue.set(state, "period", statistics);
    },
  },
};
