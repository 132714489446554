import DashboardLayout from "@/layout/DashboardLayout.vue";
import DashboardListLayout from "@/layout/DashboardListLayout.vue";

// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
import TechnicalFigures from "@/pages/TechnicalFigures.vue";

// Session pages
import SignIn from "@/pages/SignIn.vue";
import Password from "@/pages/Password.vue";
import EditPassword from "@/pages/EditPassword.vue";

// Admin pages
import Dashboard from "@/pages/Dashboard.vue";
import Passport from "@/pages/Passport.vue";
import Transports from "@/pages/Transports.vue";
import Contacts from "@/pages/Contacts.vue";
import ContactAdd from "@/pages/ContactAdd.vue";
import Contact from "@/pages/Contact.vue";
import Medicines from "@/pages/Medicines.vue";
import MedicineAdd from "@/pages/MedicineAdd.vue";
import Medicine from "@/pages/Medicine.vue";
import Messages from "@/pages/Messages.vue";
import RvoReports from "@/pages/RvoReports.vue";
import RvoReport from "@/pages/RvoReport.vue";
import TreatmentPlans from "@/pages/treatment/TreatmentPlans.vue";
import TreatmentPlan from "@/pages/treatment/TreatmentPlan.vue";
import TreatmentPlanAdd from "@/pages/treatment/TreatmentPlanAdd.vue";

import Groups from "@/pages/Groups.vue";
import Group from "@/pages/Group.vue";
import ListSidebar from "@/components/ListSidebar.vue";
import SettingsSidebar from "@/components/SettingsSidebar.vue";

// List pages
import AllAnimals from "@/pages/Lists/AllAnimals.vue";
import AnimalCharacteristics from "@/pages/Lists/characteristic/AnimalCharacteristics.vue";
import Characteristics from "@/pages/Lists/characteristic/Characteristics.vue";
import AnimalsWithCharacteristic from "@/pages/Lists/characteristic/AnimalsWithCharacteristic.vue";
import Latest from "@/pages/Lists/weighings/Latest.vue";
import Range from "@/pages/Lists/weighings/Range.vue";
import Details from "@/pages/Lists/weighings/Details.vue";
import Renumbers from "@/pages/Lists/Renumbers.vue";
import ScanErrors from "@/pages/Lists/ScanErrors.vue";
import RvoComparison from "@/pages/Lists/RvoComparison.vue";
import DailyNumbersType4Animal from "@/pages/Lists/daily_numbers/DailyNumbersType4Animal.vue";
import DailyNumbersType3Animal from "@/pages/Lists/daily_numbers/DailyNumbersType3Animal.vue";
import AccountancyCount from "@/pages/Lists/AccountancyCount.vue";
import Reports from "@/pages/Lists/Reports.vue";

import Death from "@/pages/Lists/death/Death.vue";
import DeathReason from "@/pages/Lists/death/DeathReason.vue";
import DeathDetails from "@/pages/Lists/death/DeathDetails.vue";

import MilkAnimal from "@/pages/Lists/milk/MilkAnimal.vue";
import MilkGroup from "@/pages/Lists/milk/MilkGroup.vue";
import Milking from "@/pages/Lists/milk/Milking.vue";
import MilkPickup from "@/pages/Lists/milk/MilkPickup.vue";

import MedicinesList from "@/pages/Lists/medicine/Medicines.vue";
import MedicinesDetails from "@/pages/Lists/medicine/MedicineDetails.vue";
import DosesList from "@/pages/Lists/medicine/Doses.vue";

import Birth from "@/pages/Lists/propagation/Birth.vue";
import MotherAnimals from "@/pages/Lists/propagation/MotherAnimals.vue";
import Pregnancies from "@/pages/Lists/propagation/Pregnancies.vue";
import PregnantAnimals from "@/pages/Lists/propagation/PregnantAnimals.vue";
import ConditionScore from "@/pages/Lists/propagation/ConditionScore.vue";
import BreedingPrograms from "@/pages/Lists/propagation/BreedingPrograms.vue";
import Sponges from "@/pages/Lists/propagation/Sponges.vue";
import Weaning from "@/pages/Lists/propagation/Weaning.vue";

import NewList from "@/pages/Lists/NewList.vue";
import CustomList from "@/pages/Lists/CustomList.vue";

const loginPage = {
  path: "/sign_in",
  name: "Login",
  component: SignIn,
  meta: { allowUnAuthenticated: true },
};
const PasswordPage = {
  path: "/password",
  name: "Password",
  component: Password,
  meta: { allowUnAuthenticated: true },
};
const updatePasswordPage = {
  path: "/password/edit",
  name: "EditPassword",
  component: EditPassword,
  meta: { allowUnAuthenticated: true },
};
const settingsMenu = {
  path: "/settings",
  components: {
    default: DashboardListLayout,
    sidebar: SettingsSidebar,
  },
  redirect: "/settings/contacts",
  children: [
    {
      path: "contacts",
      name: "Contacts",
      components: {
        default: Contacts,
        sidebar: SettingsSidebar,
      },
    },
    {
      path: "contacts/add",
      name: "ContactAdd",
      components: {
        default: ContactAdd,
        sidebar: SettingsSidebar,
      },
    },
    {
      path: "contacts/:id",
      name: "Contact",
      components: {
        default: Contact,
        sidebar: SettingsSidebar,
      },
    },
    {
      path: "medicines",
      name: "MedicinesSettings",
      components: {
        default: Medicines,
        sidebar: SettingsSidebar,
      },
    },
    {
      path: "medicines/add",
      name: "MedicineAdd",
      components: {
        default: MedicineAdd,
        sidebar: SettingsSidebar,
      },
    },

    {
      path: "medicines/:id",
      name: "Medicine",
      components: {
        default: Medicine,
        sidebar: SettingsSidebar,
      },
    },

    {
      path: "treatmentplans",
      name: "treatmentplans",
      components: {
        default: TreatmentPlans,
        sidebar: SettingsSidebar,
      },
    },
    {
      path: "treatmentplans/add",
      name: "treatmentplans-add",
      components: {
        default: TreatmentPlanAdd,
        sidebar: SettingsSidebar,
      },
    },
    {
      path: "treatmentplans/:id",
      name: "Medicine",
      components: {
        default: TreatmentPlan,
        sidebar: SettingsSidebar,
      },
    },
  ],
};
const listsMenu = {
  path: "/lists",
  components: {
    default: DashboardListLayout,
    sidebar: ListSidebar,
  },
  redirect: "/lists/all",
  children: [
    {
      path: "all",
      name: "AllAnimals",
      components: {
        default: AllAnimals,
        sidebar: ListSidebar,
      },
    },
    {
      path: "characteristics",
      name: "Characteristics",
      components: {
        default: Characteristics,
        sidebar: ListSidebar,
      },
    },
    {
      path: "animal-characteristics",
      name: "AnimalCharacteristics",
      components: {
        default: AnimalCharacteristics,
        sidebar: ListSidebar,
      },
    },
    {
      path: "characteristics/:id",
      name: "AnimalsWithCharacteristic",
      components: {
        default: AnimalsWithCharacteristic,
        sidebar: ListSidebar,
      },
    },
    {
      path: "renumbers",
      name: "Renumbers",
      components: {
        default: Renumbers,
        sidebar: ListSidebar,
      },
    },
    {
      path: "daily-numbers-type-4-animal",
      name: "DailyNumbersType4Animal",
      components: {
        default: DailyNumbersType4Animal,
        sidebar: ListSidebar,
      },
    },
    {
      path: "daily-numbers-type-3-animal",
      name: "DailyNumbersType3Animal",
      components: {
        default: DailyNumbersType3Animal,
        sidebar: ListSidebar,
      },
    },
    {
      path: "accountancy-count",
      name: "AccountancyCount",
      components: {
        default: AccountancyCount,
        sidebar: ListSidebar,
      },
    },
    {
      path: "latest-weighings",
      name: "Latest",
      components: {
        default: Latest,
        sidebar: ListSidebar,
      },
    },
    {
      path: "weight-range",
      name: "Range",
      components: {
        default: Range,
        sidebar: ListSidebar,
      },
    },
    {
      path: "weight-details/:range",
      name: "Details",
      components: {
        default: Details,
        sidebar: ListSidebar,
      },
    },
    {
      path: "mother-animals",
      name: "MotherAnimals",
      components: {
        default: MotherAnimals,
        sidebar: ListSidebar,
      },
    },
    {
      path: "birth",
      name: "Birth",
      components: {
        default: Birth,
        sidebar: ListSidebar,
      },
    },
    {
      path: "pregnancies",
      name: "Pregnancies",
      components: {
        default: Pregnancies,
        sidebar: ListSidebar,
      },
    },
    {
      path: "pregnant-animals",
      name: "PregnantAnimals",
      components: {
        default: PregnantAnimals,
        sidebar: ListSidebar,
      },
    },
    {
      path: "condition-score",
      name: "ConditionScore",
      components: {
        default: ConditionScore,
        sidebar: ListSidebar,
      },
    },
    {
      path: "breeding-programs",
      name: "BreedingPrograms",
      components: {
        default: BreedingPrograms,
        sidebar: ListSidebar,
      },
    },
    {
      path: "sponges",
      name: "Sponges",
      components: {
        default: Sponges,
        sidebar: ListSidebar,
      },
    },
    {
      path: "reports",
      name: "Reports",
      components: {
        default: Reports,
        sidebar: ListSidebar,
      },
    },
    {
      path: "new",
      name: "New List",
      components: {
        default: NewList,
        sidebar: ListSidebar,
      },
    },
    {
      path: "milk-per-animal",
      name: "MilkAnimal",
      components: {
        default: MilkAnimal,
        sidebar: ListSidebar,
      },
    },
    {
      path: "milk-per-group",
      name: "MilkGroup",
      components: {
        default: MilkGroup,
        sidebar: ListSidebar,
      },
    },
    {
      path: "milkings",
      name: "Milkings",
      components: {
        default: Milking,
        sidebar: ListSidebar,
      },
    },
    {
      path: "milk-pickup",
      name: "MilkPickup",
      components: {
        default: MilkPickup,
        sidebar: ListSidebar,
      },
    },
    {
      path: "death",
      name: "Death",
      components: {
        default: Death,
        sidebar: ListSidebar,
      },
    },
    {
      path: "death-reason",
      name: "DeathReason",
      components: {
        default: DeathReason,
        sidebar: ListSidebar,
      },
    },
    {
      path: "death-details/:cause",
      name: "DeathDetails",
      components: {
        default: DeathDetails,
        sidebar: ListSidebar,
      },
    },
    {
      path: "medicines",
      name: "Medicines",
      components: {
        default: MedicinesList,
        sidebar: ListSidebar,
      },
    },
    {
      path: "doses",
      name: "Doses",
      components: {
        default: DosesList,
        sidebar: ListSidebar,
      },
    },
    {
      path: "medicine-details/:medicine",
      name: "MedicinesDetails",
      components: {
        default: MedicinesDetails,
        sidebar: ListSidebar,
      },
    },
    {
      path: "weaning",
      name: "Weaning",
      components: {
        default: Weaning,
        sidebar: ListSidebar,
      },
    },
    {
      path: "messages",
      name: "messages",
      components: {
        default: Messages,
        sidebar: ListSidebar,
      },
    },
    {
      path: "messages/:tab",
      name: "messages-tab",
      components: {
        default: Messages,
        sidebar: ListSidebar,
      },
    },
    {
      path: "rvo-reports",
      name: "rvo-reports",
      components: {
        default: RvoReports,
        sidebar: ListSidebar,
      },
    },
    {
      path: "rvo-reports/:id",
      name: "rvo-report",
      components: {
        default: RvoReport,
        sidebar: ListSidebar,
      },
    },

    {
      path: "scan-errors",
      name: "scan-errors",
      components: {
        default: ScanErrors,
        sidebar: ListSidebar,
      },
    },
    {
      path: "rvo-comparison",
      name: "rvo-comparison",
      components: {
        default: RvoComparison,
        sidebar: ListSidebar,
      },
    },
    {
      path: ":id",
      name: "Custom List",
      components: {
        default: CustomList,
        sidebar: ListSidebar,
      },
    },
    // {
    //   path: "paginated",
    //   name: "Paginated Tables",
    //   component: PaginatedTables
    // }
  ],
};

const routes = [
  loginPage,
  PasswordPage,
  updatePasswordPage,
  listsMenu,
  settingsMenu,
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "technical-figures",
        name: "TechnicalFigures",
        component: TechnicalFigures,
      },
      {
        path: "passport",
        component: Passport,
      },
      {
        path: "passport/:id",
        name: "passport",
        component: Passport,
      },
      {
        path: "passport/:id/:tab",
        name: "passport-tab",
        component: Passport,
      },
      {
        path: "groups",
        name: "Groups",
        component: Groups,
      },
      {
        path: "groups/:id",
        name: "Group",
        component: Group,
      },
      {
        path: "transports",
        name: "Transports",
        component: Transports,
      },
    ],
  },
  {
    path: "/levensnummers-bestellen",
    beforeEnter() {
      location.href = "https://www.merkodalton.nl/schapen-geiten/schapenoormerken.html";
    },
  },
  { path: "*", component: NotFound },
];

export default routes;
