<template>
  <Table
    :columns="columns"
    :data="messages"
    :loading="!pageLoader_isDataLoaded"
    :key-extractor="keyExtractor"
    :column-styles="{ message: 'width: 20%; font-size: 8px;' }"
  >
    <template #message="{ item: message }">{{ message }}</template>
    <template #empty>Er zijn nog geen berichten</template>
  </Table>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "AllTable",
  mixins: [pageLoader],
  data() {
    return {
      columns: {
        berichttype: "Berichttype",
        gebeurtenisdatum: "Datum",
        melding_status: "Status",
        meldingnummer: "meldingnummer",
        message: "Volledig",
      },
      imports: [],
      error: "",
      query: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
    ...mapState({
      messages: (state) => state.messages.items,
    }),
  },
  created() {
    this.pageLoader_loadingData();
    Promise.all([this.fetchMessages()])
      .then(() => this.pageLoader_resolveData())
      .catch(() => {
        this.pageLoader_resolveData();
      });
  },
  methods: {
    ...mapActions("messages", ["fetchMessages"]),
    keyExtractor(message) {
      return message.meldingnummer;
    },
  },
};
</script>
<style></style>
