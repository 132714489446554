<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div v-if="doses">
        <div class="row">
          <div class="col-12">
            <Table
              sortable
              :columns="columns"
              :data="doses"
              :key-extractor="keyExtractor"
              :sort="sort"
              @sort="onSort"
              @filterChange="onFilterChange"
            >
              <template #amount="{ item: dose }">{{ dose.amount }} {{ dose.unit }}</template>
              <template #empty>Geen giften</template>
            </Table>
            <paginate
              v-if="pagination.totalPages > 1"
              v-model="pagination.page"
              :page-count="pagination.totalPages"
              :click-handler="fetchPaginatedItems"
              :prev-text="'Vorige'"
              :next-text="'Volgende'"
              :container-class="'pagination justify-content-center pt-3'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'page-item prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'page-item next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
              :first-last-button="false"
            ></paginate>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";

export default {
  name: "MedicineHistory",
  mixins: [pageLoader, defaultListPage(`animals/doses`, "medical", true)],
  data() {
    return {
      period: {
        startDate: new Date(0),
        endDate: new Date(2524608000000),
      },
      filteredAnimals: [],
      animalLoaded: false,
      columns: {
        dose_date: {
          title: "Datum",
          filter: { type: "date" },
        },
        medicine_name: {
          title: "Medicijn",
          filter: {
            type: "async",
            values: [],
          },
        },
        amount: {
          title: "Hoeveelheid",
          filter: { type: "number" },
        },
      },
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.default_lists.pagination,
      doses: (state) => state.default_lists.items,
    }),
  },
  created() {
    this.columns.medicine_name.filter.values = this.$http.secured
      .get("/medicines")
      .then((response) => {
        const medicines = [];
        response.data.data
          .filter((medicine) => medicine.attributes)
          .map((medicine) => {
            medicines.push({
              value: medicine.attributes.name,
              label: medicine.attributes.name,
            });
          });
        return medicines;
      });
  },
  methods: {
    keyExtractor(dose) {
      return dose.id;
    },
  },
};
</script>
<style></style>
