<template>
  <div class="content">
    <div class="container">
      <div class="row d-flex justify-content-between">
        <div class="col-12">
          <h1 class="page-title">Behandelplannen</h1>
          <div class="pl-3 pr-3 row d-flex justify-content-between">
            <base-input
              v-model="query"
              type="text"
              class="w-25 mb-0"
              placeholder="Zoek een behandelplan"
            ></base-input>
            <router-link
              class="btn btn-success"
              :to="{
                path: 'treatmentplans/add',
              }"
            >
              Toevoegen
            </router-link>
          </div>
        </div>
      </div>
      <hr class="my-4" />
      <div v-if="treatmentplans">
        <div class="row">
          <div class="col-12">
            <Table :columns="columns" :data="treatmentplans" :key-extractor="keyExtractor">
              <template #name="{ item: treatmentplan }">
                <router-link :to="{ path: 'treatmentplans/' + treatmentplan.id }">
                  {{ treatmentplan.attributes.disease }}
                </router-link>
              </template>
              <template #empty>
                <template v-if="treatmentplans.length === 0">
                  Er zijn nog geen behandelplannen gemaakt
                </template>
                <template v-else>Geen behandelplannen gevonden</template>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TreatmentPlans",
  data() {
    return {
      treatmentplans: [],
      error: "",
      query: "",
      columns: {
        name: "Ziekte",
        "attributes.animal_type": "Diersoort",
        "attributes.notes": "Notities",
      },
    };
  },
  created() {
    this.$http.secured
      .get("/treatment_plans")
      .then((response) => {
        this.treatmentplans = response.data.data;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    keyExtractor(medicine) {
      return medicine.attributes.id;
    },
  },
};
</script>
<style></style>
