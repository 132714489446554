<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Doodsoorzaak</h1>
          </div>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options
            :period="period"
            :url="'default_lists/deaths/death_reasons'"
          ></export-options>
        </div>

        <div v-if="death_reasons">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="death_reasons"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
              >
                <template #reason_of_death="{ item: death_reason }">
                  <router-link
                    :to="{
                      path: '/lists/death-details/' + death_reason.reason_of_death,
                    }"
                  >
                    {{ death_reason.reason_of_death }}
                  </router-link>
                </template>
                <template #empty>Geen sterfte</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
import ExportOptions from "@/components/Lists/ExportOptions.vue";

export default {
  name: "DeathReason",
  components: {
    PeriodFilter,
    ExportOptions,
  },
  mixins: [pageLoader, defaultListPage("deaths/death_reasons")],
  data() {
    return {
      columns: {
        reason_of_death: "Oorzaak",
        number_of_animals: "Overleden",
      },
    };
  },
  computed: {
    ...mapState({
      death_reasons: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(death_reason) {
      return death_reason.reason_of_death;
    },
  },
};
</script>
<style></style>
