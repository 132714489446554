var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pageLoader_isDataLoaded)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_vm._m(0),(_vm.statistics)?_c('div',{staticClass:"row"},[_c('core-stat-card',{attrs:{"category":"Gem. melkgift in L","value":_vm._f("roundDecimal")(_vm.statistics.average_milk_yield)}}),_c('core-stat-card',{attrs:{"category":"Gem. vet in %","value":_vm._f("roundDecimal")(_vm.statistics.average_fat)}}),_c('core-stat-card',{attrs:{"category":"Gem. eiwit in %","value":_vm._f("roundDecimal")(_vm.statistics.average_protein)}}),_c('core-stat-card',{attrs:{"category":"Gem. celgetal","value":_vm._f("roundDecimal")(_vm.statistics.average_cells)}})],1):_vm._e(),_c('div',{staticClass:"mt-4 d-flex justify-content-end"}),_c('b-form-checkbox',{staticClass:"mt-2 mb-2",attrs:{"id":"included-checkbox","name":"included-checkbox","value":true,"unchecked-value":false},model:{value:(_vm.includes),callback:function ($$v) {_vm.includes=$$v},expression:"includes"}},[_vm._v(" Inclusief niet aanwezige dieren ")]),_c('div',{staticClass:"mt-2 mb-2 d-flex align-items-center"},[_c('search-input',{staticClass:"mr-auto",attrs:{"column":"animal_life_number"},on:{"search":_vm.onSearch}}),_c('period-filter',{attrs:{"period":_vm.period},on:{"change":_vm.handlePeriodChange}}),_c('export-options',{attrs:{"period":_vm.period,"url":'milk_yields'}})],1),(_vm.milkyields)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('Table',{attrs:{"sortable":"","columns":_vm.columns,"data":_vm.milkyields,"key-extractor":_vm.keyExtractor,"sort":_vm.sort},on:{"sort":_vm.onSort,"filterChange":_vm.onFilterChange},scopedSlots:_vm._u([{key:"life_number",fn:function(ref){
var milkyield = ref.item;
return [_c('router-link',{staticClass:"animal_numbers",attrs:{"to":{
                    path: '/passport/' + milkyield.animal.id,
                  }}},[_c('span',[_vm._v(_vm._s(milkyield.animal.attributes.animal_numbers.lifeNumber))]),_c('span',[_vm._v(_vm._s(milkyield.animal.attributes.animal_numbers.workNumber))])])]}},{key:"date",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(milkyield.attributes.date)))])]}},{key:"amount",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.amount))])]}},{key:"milker_company_name",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.milker.attributes.company_name))])]}},{key:"fat",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.fat))])]}},{key:"npn_cu",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.npn_cu))])]}},{key:"protein",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.protein))])]}},{key:"lactose",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.lactose))])]}},{key:"solids",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.solids))])]}},{key:"npn_cu_a2",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.npn_cu_a2))])]}},{key:"cells",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.cells))])]}},{key:"snf",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.snf))])]}},{key:"denovo_fatty_acids",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.denovo_fatty_acids))])]}},{key:"mixed_fatty_acids",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.mixed_fatty_acids))])]}},{key:"preformed_fatty_acids",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.preformed_fatty_acids))])]}},{key:"denovo_relative",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.denovo_relative))])]}},{key:"mixed_relative",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.mixed_relative))])]}},{key:"preformed_relative",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.preformed_relative))])]}},{key:"nefa",fn:function(ref){
                  var milkyield = ref.item;
return [_c('span',[_vm._v(_vm._s(milkyield.attributes.nefa))])]}},{key:"empty",fn:function(){return [_vm._v("Geen melkgiften gevonden")]},proxy:true}],null,false,574237102)}),(_vm.pagination.totalPages > 1)?_c('paginate',{attrs:{"page-count":_vm.pagination.totalPages,"click-handler":_vm.fetchPaginatedItems,"prev-text":'Vorige',"next-text":'Volgende',"container-class":'pagination justify-content-center pt-3',"page-class":'page-item',"page-link-class":'page-link',"prev-class":'page-item prev-item',"prev-link-class":'page-link prev-link-item',"next-class":'page-item next-item',"next-link-class":'page-link next-link-item',"break-view-class":'break-view',"break-view-link-class":'break-view-link',"first-last-button":false},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)])]):_c('div',[_c('AppSpinner')],1)],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"page-title"},[_vm._v("Melkgift per dier")])])])}]

export { render, staticRenderFns }