<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">RVO meldingen</h1>
          </div>
        </div>
        <tabs>
          <tab title="Intrekbaar">
            <RepairableTable />
          </tab>
          <!-- <tab title="Intrekbaar">
            <RetractableTable />
          </tab> -->
        </tabs>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { Tabs, Tab } from "vue-slim-tabs";
import { mapActions, mapState, mapGetters } from "vuex";
import RepairableTable from "@/components/RvoReports/RepairableTable.vue";
// import RetractableTable from "@/components/RvoReports/RetractableTable.vue";

import pageLoader from "@/mixins/pageLoader";

export default {
  name: "RvoReports",
  components: {
    Tabs,
    Tab,
    RepairableTable,
    // RetractableTable
  },
  mixins: [pageLoader],
  data() {
    return {
      animalLifeNumbers: [],
      error: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),

    ...mapState({
      retractableReports: (state) => state.rvo_reports.retractableItems,
      repairableReports: (state) => state.rvo_reports.repairableItems,
    }),
  },
  created() {
    this.pageLoader_resolveData();
  },
  methods: {
    ...mapActions("rvoReports", ["fetchRetractableReports", "fetchRepairableReports"]),
  },
};
</script>
<style></style>
