<template>
  <Table :columns="columns" :data="transports" :key-extractor="keyExtractor">
    <template #documents="{ item: transport }">
      <a
        v-if="transport.attributes.transport_document"
        class="download"
        target="_blank"
        @click="onTransportDocumentClick(transport)"
      >
        Transport
      </a>
    </template>
    <template #empty>
      <template v-if="transports.length === 0">Er zijn nog geen transports gemaakt</template>
      <template v-else>Geen transports gevonden</template>
    </template>
  </Table>
</template>
<script>
export default {
  name: "AToBTable",
  data() {
    return {
      transports: [],
      error: "",
      query: "",
      columns: {
        "attributes.scheduled": "Datum",
        "attributes.origination_name": "Location A",
        "attributes.destination_name": "Location B",
        documents: "Documenten",
      },
    };
  },
  created() {
    this.$http.secured
      .get("/transports")
      .then((response) => {
        this.transports = response.data.data;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    onTransportDocumentClick(transport) {
      this.$http.secured
        .get(`/transports/${transport.id}/transport_document`, {
          responseType: "blob",
        })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "transport_document.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    keyExtractor(transport) {
      return transport.attributes.id;
    },
  },
};
</script>
