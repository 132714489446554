<template>
  <div class="view-type-option">
    <input
      :id="value + '-viewtype'"
      type="radio"
      name="view-type"
      :checked="isChecked"
      :value="value"
      @change="$emit('change', $event.target.value)"
    />
    <label :for="value + '-viewtype'" class="view-type-label">{{ label }}</label>
  </div>
</template>
<script>
export default {
  name: "ViewType",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    label: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      type: String,
      default: undefined,
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
};
</script>
<style scope lang="scss">
.view-type-option {
  width: 100%;
  height: 41px;
  position: relative;

  font-size: 14px;
  font-weight: 400;
  text-align: left;

  input,
  .view-type-label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 41px;
  }

  input[type="radio"] {
    opacity: 0.01;
    z-index: 100;

    &:checked + .view-type-label {
      background: #f0f0f080;
      color: #2e994c;
    }
  }

  .view-type-label {
    cursor: pointer;
    z-index: 90;
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 8px;
    font-weight: normal;
    color: #333;

    &:hover {
      background: #f0f0f080;
    }
  }
}
</style>
