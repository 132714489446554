import Vue from "vue";
import { axiosInstance } from "@/services/axios";
import { createQuery } from "@/helpers";

const today = new Date();
const defaultStartDate = new Date(today.getFullYear(), 0, 1);
const defaultEndDate = new Date(today.getFullYear(), 11, 31);
export default {
  namespaced: true,

  state: {
    items: [],
    included: [],
    item: {},
    pagination: {
      totalCount: 0,
      totalPages: 0,
      pageSize: 25,
      page: 1,
    },
    period: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
  },
  actions: {
    setItem({ state, commit }, item) {
      commit("setItem", { resource: "default_lists", item: {} }, { root: true });
      commit("setItem", { resource: "default_lists", item }, { root: true });
      return state.item;
    },
    fetchItems({ state, commit }, options = { reset: true, noDefaultList: false, raw: false }) {
      if (options.reset) {
        commit("setItems", { resource: "default_lists", items: [] }, { root: true });
        commit("setItems", { resource: "raw", items: [] }, { root: true });
        commit("setIncluded", { resource: "default_lists", included: [] }, { root: true });
      }

      let url;
      if (options.filter.medicine || options.noDefaultList) {
        url = `${options.url}?${createQuery(options.filter, options.sort, options.extraFilters)}`;
      } else {
        url = `/default_lists/${options.url}?${createQuery(
          options.filter,
          options.sort,
          options.extraFilters,
        )}`;
      }

      return axiosInstance.get(url).then((res) => {
        const items = options.filter.medicine || options.filter.short ? res.data : res.data.data;

        const newAnimals = options.raw
          ? null
          : items &&
            items.map((animal) => {
              if ("life_number" in animal.attributes) {
                return {
                  ...animal,
                  attributes: {
                    ...animal.relationships,
                    ...animal.attributes,
                    id: animal.id,
                    animal_numbers: {
                      lifeNumber: animal.attributes.life_number.substring(
                        0,
                        animal.attributes.life_number.length - 5,
                      ),
                      workNumber: animal.attributes.life_number.substring(
                        animal.attributes.life_number.length - 5,
                      ),
                    },
                  },
                };
              }
              if (
                "mother_life_number" in animal.attributes &&
                "mother_work_number" in animal.attributes
              ) {
                return {
                  ...animal,
                  attributes: {
                    ...animal.relationships,
                    ...animal.attributes,
                    id: animal.id,
                    mother_numbers: {
                      lifeNumber: animal.attributes.mother_life_number.substring(
                        0,
                        animal.attributes.mother_life_number.length - 5,
                      ),
                      workNumber: animal.attributes.mother_life_number.substring(
                        animal.attributes.mother_life_number.length - 5,
                      ),
                    },
                  },
                };
              }
              return {
                ...animal,
                attributes: {
                  relationships: animal.relationships,
                  ...animal.attributes,
                  id: animal.id,
                  animal_numbers: {
                    lifeNumber: animal.attributes.animal_life_number?.substring(
                      0,
                      animal.attributes.animal_life_number.length - 5,
                    ),
                    workNumber: animal.attributes.animal_life_number?.substring(
                      animal.attributes.animal_life_number.length - 5,
                    ),
                  },
                },
              };
            });
        const included = options.filter.medicine ? res?.included : res.data?.included;
        const { meta } = res.data;
        const pagination = res.data.meta;
        commit(
          "setItems",
          {
            resource: "default_lists",
            items: options.raw ? items : newAnimals.map((animal) => animal.attributes),
          },
          { root: true },
        );
        if (included) {
          commit(
            "setIncluded",
            {
              resource: "default_lists",
              included,
            },
            { root: true },
          );
        }
        commit("setPageMeta", { meta });
        commit("setPagination", { pagination });
        return state.items;
      });
    },
    initializePagesFromQuery({ commit }, { page, startDate, endDate }) {
      commit("setPage", page);
      commit("setPeriod", { startDate, endDate });
    },
  },
  mutations: {
    setPagination(state, pagination) {
      Vue.set(state.pagination, "totalCount", parseInt(pagination.pagination.total_count));
      Vue.set(state.pagination, "totalPages", parseInt(pagination.pagination.total_pages));
    },
    setPageMeta(state, meta) {
      if (meta.meta.item_name !== undefined) {
        Vue.set(state.pagination, "totalCount", parseInt(meta.meta.total_count));
      }
    },
    setPage(state, currentPage) {
      Vue.set(state.pagination, "page", parseInt(currentPage));
    },
    setPageSize(state, pageSize) {
      Vue.set(state.pagination, "pageSize", pageSize);
    },
    setPeriod(state, { startDate, endDate }) {
      Vue.set(state.period, "startDate", startDate);
      Vue.set(state.period, "endDate", endDate);
    },
  },
};
