<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 class="page-title">Medicijnen</h1>
          <div class="pl-3 pr-3 row d-flex justify-content-between">
            <base-input
              v-model="query"
              type="text"
              class="w-25 mb-0"
              placeholder="Zoek een medicijn"
            ></base-input>
            <router-link
              class="btn btn-success"
              :to="{
                path: 'medicines/add',
              }"
            >
              Toevoegen
            </router-link>
          </div>
        </div>
      </div>
      <hr class="my-4" />
      <div v-if="filteredMedicines">
        <div class="row">
          <div class="col-12">
            <Table :columns="columns" :data="filteredMedicines" :key-extractor="keyExtractor">
              <template #name="{ item: medicine }">
                <router-link :to="{ path: 'medicines/' + medicine.attributes.id }">
                  {{ medicine.attributes.name }}
                </router-link>
              </template>
              <template #empty>
                <template v-if="medicines.length === 0">
                  Er zijn nog geen medicijnen gemaakt
                </template>
                <template v-else>Geen medicijnen gevonden</template>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Medicines",
  data() {
    return {
      medicines: [],
      error: "",
      query: "",
      columns: {
        name: "Naam",
        "attributes.human_animal_type": "Diersoort",
        "attributes.created_at": "Aangemaakt",
      },
    };
  },
  computed: {
    filteredMedicines() {
      return this.medicines.filter((medicine) => {
        return medicine.attributes.name.toLowerCase().match(this.query);
      });
    },
  },
  created() {
    this.$http.secured
      .get("/medicines")
      .then((response) => {
        this.medicines = response.data.data;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    keyExtractor(medicine) {
      return medicine.attributes.id;
    },
  },
};
</script>
<style></style>
