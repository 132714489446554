<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <router-link class="back" :to="{ path: '/settings/treatmentplans' }">
            <div class="icon-chevron-back"></div>
            Alle behandelplannen
          </router-link>
          <h1 class="page-title">Nieuw behandelplan</h1>
        </div>
      </div>
      <hr class="my-4" />
      <div class="col-12">
        <b-form @submit.prevent="onSubmit">
          <!-- ANIMAL TYPE -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Dit medicijn is voor">
              <b-form-radio-group
                v-model="treatmentplan.animal_type"
                required
                :options="[
                  { text: 'Schapen', value: 3 },
                  { text: 'Geiten', value: 4 },
                ]"
                name="animal-type-selector"
              ></b-form-radio-group>
            </b-form-group>
          </div>

          <!-- CONDITION -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Aandoening">
              <b-form-input v-model="treatmentplan.disease" required class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- MEDICINE 1 -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Eerste keus medicijnen">
              <b-form-input
                v-if="!openMedicine1 && treatmentplan.medicine_first_choice_attributes.medicine_id"
                disabled
                :value="treatmentplan.medicine_first_choice_attributes.medicine_name"
                class="input"
              />
              <add-button
                v-if="!openMedicine1 && !treatmentplan.medicine_first_choice_attributes.medicine_id"
                text="Medicijn toevoegen"
                size="l"
                @handleAddClick="openMedicine1 = true"
              />
              <medicine-select
                v-if="openMedicine1"
                class="mb-4"
                :medicines="medicines"
                @submit="getMedicine1"
              />
              <div class="row pl-3">
                <b-form-input
                  v-if="
                    !openCombi1 &&
                    treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
                      .medicine_id
                  "
                  disabled
                  :value="
                    treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
                      .medicine_name
                  "
                  class="input m"
                />
                <add-button
                  v-if="
                    !openCombi1 &&
                    !treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
                      .medicine_id
                  "
                  text="In combinatie met..."
                  size="m"
                  @handleAddClick="openCombi1 = true"
                />
                <medicine-select
                  v-if="openCombi1"
                  class="mb-4"
                  :medicines="medicines"
                  @submit="getCombi1"
                />
                <b-form-input
                  v-if="
                    !openAlt1 &&
                    treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
                      .medicine_id
                  "
                  disabled
                  :value="
                    treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
                      .medicine_name
                  "
                  class="input m"
                />
                <add-button
                  v-if="
                    !openAlt1 &&
                    !treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
                      .medicine_id
                  "
                  text="Alternatief toevoegen"
                  size="m"
                  @handleAddClick="openAlt1 = true"
                />
                <medicine-select
                  v-if="openAlt1"
                  class="mb-4"
                  :medicines="medicines"
                  @submit="getAlt1"
                />
              </div>
            </b-form-group>
          </div>

          <!-- MEDICINE 2 -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Tweede keus medicijnen">
              <b-form-input
                v-if="!openMedicine2 && treatmentplan.medicine_second_choice_attributes.medicine_id"
                disabled
                :value="treatmentplan.medicine_second_choice_attributes.medicine_name"
                class="input"
              />
              <add-button
                v-if="
                  !openMedicine2 && !treatmentplan.medicine_second_choice_attributes.medicine_id
                "
                text="Medicijn toevoegen"
                size="l"
                @handleAddClick="openMedicine2 = true"
              />
              <medicine-select
                v-if="openMedicine2"
                class="mb-4"
                :medicines="medicines"
                @submit="getMedicine2"
              />
              <div class="row pl-3">
                <b-form-input
                  v-if="
                    !openCombi2 &&
                    treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes &&
                    treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
                      .medicine_id
                  "
                  disabled
                  :value="
                    treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
                      .medicine_name
                  "
                  class="input m"
                />
                <add-button
                  v-if="
                    !openCombi2 &&
                    treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes &&
                    !treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
                      .medicine_id
                  "
                  text="In combinatie met..."
                  size="m"
                  @handleAddClick="openCombi2 = true"
                />
                <medicine-select
                  v-if="openCombi2"
                  class="mb-4"
                  :medicines="medicines"
                  @submit="getCombi2"
                />
                <b-form-input
                  v-if="
                    !openAlt2 &&
                    treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes
                      .medicine_id
                  "
                  disabled
                  :value="
                    treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes
                      .medicine_name
                  "
                  class="input m"
                />
                <add-button
                  v-if="
                    !openAlt2 &&
                    !treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes
                      .medicine_id
                  "
                  text="Alternatief toevoegen"
                  size="m"
                  @handleAddClick="openAlt2 = true"
                />
                <medicine-select
                  v-if="openAlt2"
                  class="mb-4"
                  :medicines="medicines"
                  @submit="getAlt2"
                />
              </div>
            </b-form-group>
          </div>
          <!-- NOTES -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Overige notities">
              <b-form-input
                v-model="treatmentplan.notes"
                placeholder="Notitie..."
                class="input"
              ></b-form-input
            ></b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Behandelplan opslaan" :state="saveSuccess">
              <template #invalid-feedback
                ><p>Er is iets fout gegaan met het toevoegen van een behandelplan.</p></template
              >
              <template #valid-feedback><p>Behandelplan opgeslagen</p></template>
              <b-button type="submit" variant="success" class="px-4 save">Opslaan</b-button>
            </b-form-group>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosInstance } from "@/services/axios";
import AddButton from "../../components/Forms/AddButton.vue";
import MedicineSelect from "../../components/Forms/MedicineSelect.vue";

export default {
  name: "TreatmentPlanAdd",
  components: {
    AddButton,
    MedicineSelect,
  },
  data() {
    return {
      saveSuccess: null,
      medicines: [],
      openMedicine1: false,
      openMedicine2: false,
      openCombi1: false,
      openCombi2: false,
      openAlt1: false,
      openAlt2: false,
      treatmentplan: {
        disease: null,
        animal_type: null,
        notes: null,
        medicine_first_choice_attributes: {
          treatment_duration: null,
          dosage: null,
          dosage_unit: null,
          frequency: null,
          frequency_unit: null,
          frequency_custom: null,
          notes: null,
          medicine_id: null,
          combined_medicine_attributes: {
            treatment_duration: null,
            dosage: null,
            dosage_unit: null,
            frequency: null,
            frequency_unit: null,
            frequency_custom: null,
            notes: null,
            medicine_id: null,
          },
          alternative_medicine_attributes: {
            treatment_duration: null,
            dosage: null,
            dosage_unit: null,
            frequency: null,
            frequency_unit: null,
            frequency_custom: null,
            notes: null,
            medicine_id: null,
          },
        },
        medicine_second_choice_attributes: {
          treatment_duration: null,
          dosage: null,
          dosage_unit: null,
          frequency: null,
          frequency_unit: null,
          frequency_custom: null,
          notes: null,
          medicine_id: null,
          combined_medicine_attributes: {
            treatment_duration: null,
            dosage: null,
            dosage_unit: null,
            frequency: null,
            frequency_unit: null,
            frequency_custom: null,
            notes: null,
            medicine_id: null,
          },
          alternative_medicine_attributes: {
            treatment_duration: null,
            dosage: null,
            dosage_unit: null,
            frequency: null,
            frequency_unit: null,
            frequency_custom: null,
            notes: null,
            medicine_id: null,
          },
        },
      },
    };
  },
  created() {
    this.$http.secured
      .get("/medicines")
      .then((response) => {
        this.medicines = response.data.data;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },

  methods: {
    onSubmit() {
      const treatmentplan = { ...this.treatmentplan };
      if (
        !treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes?.medicine_id
      ) {
        delete treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes;
      }
      if (
        !treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes?.medicine_id
      ) {
        delete treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes;
      }
      if (
        !treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes?.medicine_id
      ) {
        delete treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes;
      }
      if (
        !treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes
          ?.medicine_id
      ) {
        delete treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes;
      }
      if (!treatmentplan.medicine_second_choice_attributes.medicine_id) {
        delete treatmentplan.medicine_second_choice_attributes;
      }
      if (!treatmentplan.notes) {
        delete treatmentplan.notes;
      }

      axiosInstance
        .post("/treatment_plans", treatmentplan)
        .then(() => {
          this.saveSuccess = true;
          setTimeout(() => {
            this.saveSuccess = null;
            this.$router.push("/settings/treatmentplans");
          }, 2000);
        })
        .catch(() => {
          this.saveSuccess = false;
        });
    },
    getMedicine1(medicine) {
      this.treatmentplan.medicine_first_choice_attributes = medicine;
      this.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes = {
        treatment_duration: null,
        dosage: null,
        dosage_unit: null,
        frequency: null,
        frequency_unit: null,
        frequency_custom: null,
        notes: null,
        medicine_id: null,
      };
      this.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes = {
        treatment_duration: null,
        dosage: null,
        dosage_unit: null,
        frequency: null,
        frequency_unit: null,
        frequency_custom: null,
        notes: null,
        medicine_id: null,
      };
      this.openMedicine1 = false;
    },
    getMedicine2(medicine) {
      this.treatmentplan.medicine_second_choice_attributes = medicine;
      this.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes = {
        treatment_duration: null,
        dosage: null,
        dosage_unit: null,
        frequency: null,
        frequency_unit: null,
        frequency_custom: null,
        notes: null,
        medicine_id: null,
      };
      this.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes = {
        treatment_duration: null,
        dosage: null,
        dosage_unit: null,
        frequency: null,
        frequency_unit: null,
        frequency_custom: null,
        notes: null,
        medicine_id: null,
      };
      this.openMedicine2 = false;
    },
    getCombi1(medicine) {
      this.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes = medicine;
      this.openCombi1 = false;
    },
    getCombi2(medicine) {
      this.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes = medicine;

      this.openCombi2 = false;
    },
    getAlt1(medicine) {
      this.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes =
        medicine;
      this.openAlt1 = false;
    },
    getAlt2(medicine) {
      this.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes =
        medicine;
      this.openAlt2 = false;
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  color: #0e0649;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: url("../../assets/images/icons/filter.svg") !important;
  padding: 5px;
}

.inputWrapper {
  position: relative;
  .input + .icon {
    position: absolute;
    font-size: 0.875rem;
    right: 1rem;
    top: 8px;
    bottom: 0;
    height: 100%;
  }
}

.input {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 14px 16px;
  font-size: 0.875rem;
  margin-right: 8px;
  margin-bottom: 8px;
  max-height: 43px;
  width: 348px;
  background-color: white;
}

.save {
  width: 224px;
  border-radius: 21.5px;
}

.m {
  width: 224px !important;
}
</style>
