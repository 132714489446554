export default {
  data() {
    return {
      sort: null,
    };
  },
  methods: {
    onSort(sort) {
      this.sort = sort;
      this.$emit("sorted");
    },
  },
};
