<template>
  <div
    id="app"
    :class="
      $route.path === '/sign_in' || $route.path === '/password' || $route.path === '/password/edit'
        ? 'sign-in'
        : 'others'
    "
  >
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import { load } from "@/services/intercom";

export default {
  name: "App",
  beforeMount() {
    load();
  },
};
</script>
