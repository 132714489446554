<template>
  <div class="sidebar sub-sidebar">
    <ul class="nav flex-column">
      <li class="nav-item">
        <router-link class="nav-link" :to="{ path: '/lists/all' }">Alle dieren</router-link>
      </li>
      <li class="nav-item">
        <a v-b-toggle.collapse-1 class="nav-link drop-down-chevron">Voortplanting</a>
        <b-collapse id="collapse-1" v-model="propagationsVisible" class="mt-2">
          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link class="nav-link" :class="{ active: birthVisible }" to="/lists/birth"
                >Geboorte</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/condition-score">Conditiescore</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/breeding-programs">Dekken</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/sponges">Sponzen</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/pregnant-animals">Drachtig</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="'/lists/weaning'">Spenen</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li class="nav-item">
        <a v-b-toggle.collapse-death class="nav-link drop-down-chevron">Sterfte</a>
        <b-collapse id="collapse-death" v-model="deathVisible" class="mt-2">
          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/death">Dieren</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/death-reason">Oorzaken</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ path: '/lists/renumbers' }">Hermerken</router-link>
      </li>
      <li class="nav-item">
        <a v-b-toggle.collapse-characteristics class="nav-link drop-down-chevron">Kenmerken</a>
        <b-collapse id="collapse-characteristics" v-model="characteristicsVisible" class="mt-2">
          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ path: '/lists/animal-characteristics' }"
                >Dieren</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ path: '/lists/characteristics' }"
                >Kenmerken</router-link
              >
            </li>
          </ul>
        </b-collapse>
      </li>
      <li class="nav-item">
        <a v-b-toggle.collapse-weighings class="nav-link drop-down-chevron">Gewicht</a>
        <b-collapse id="collapse-weighings" v-model="weighingsVisible" class="mt-2">
          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/latest-weighings">Dieren</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/weight-range">Per gewicht</router-link>
            </li>
          </ul>
        </b-collapse>
        <!-- <router-link class="nav-link" :to="{ path: '/lists/weighings' }">Gewicht</router-link> -->
      </li>
      <li class="nav-item">
        <a v-b-toggle.collapse-milkings class="nav-link drop-down-chevron">Melk</a>
        <b-collapse id="collapse-milkings" v-model="milkingsVisible" class="mt-2">
          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/milk-per-animal">Per dier</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/milk-per-group">Per groep</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/milkings">Per melkbeurt</router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/lists/milk-pickup">Per ophaalpunt</router-link>
            </li> -->
          </ul>
        </b-collapse>
        <!-- <router-link class="nav-link" :to="{ path: '/lists/weighings' }">Gewicht</router-link> -->
      </li>

      <li class="nav-item">
        <a v-b-toggle.collapse-medicines class="nav-link drop-down-chevron">Medicijnen</a>
        <b-collapse id="collapse-medicines" v-model="medicinesVisible" class="mt-2">
          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/doses">Dieren</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/lists/medicines">Gebruik</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ path: '/lists/reports' }">
          Vermist / gevonden
        </router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link" :to="{ path: '/lists/accountancy-count' }"
          >Tellingslijst</router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ path: '/lists/daily-numbers-type-3-animal' }"
          >Dierdagtelling</router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ path: '/lists/scan-errors' }">Scanfouten</router-link>
      </li>
      <li v-show="user.rvo_enabled" class="nav-item">
        <router-link class="nav-link" :to="{ path: '/lists/rvo-comparison' }"
          >RVO verschillen</router-link
        >
      </li>
      <li v-show="user.rvo_enabled" class="nav-item">
        <router-link class="nav-link" :to="{ path: '/lists/messages' }">RVO berichten</router-link>
      </li>
      <li v-show="user.rvo_enabled" class="nav-item">
        <router-link class="nav-link" :to="{ path: '/lists/rvo-reports' }"
          >RVO meldingen</router-link
        >
      </li>
      <!-- <li class="nav-item">
        <a v-b-toggle.custom-lists class="nav-link drop-down-chevron">Samengestelde lijsten</a>
        <b-collapse id="custom-lists" v-model="customListsVisible" class="mt-2">
          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ path: '/lists/new' }">Nieuwe lijst</router-link>
            </li>
            <li v-for="list in lists" :key="list.id" class="nav-item">
              <router-link class="nav-link" :to="{ path: '/lists/' + list.id }">{{
                list.attributes.name
              }}</router-link>
            </li>
          </ul>
        </b-collapse>
      </li> -->
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      env: process.env.VUE_APP_ENV,
      activeNotifications: false,
      lists: [],
      error: "",
      query: "",
      birthVisible: this.$route.name === "Birth" || this.$route.name === "MotherAnimals",
      propagationsVisible:
        this.$route.name === "Birth" ||
        this.$route.name === "MotherAnimals" ||
        this.$route.name === "ConditionScore" ||
        this.$route.name === "BreedingPrograms" ||
        this.$route.name === "Sponges" ||
        this.$route.name === "PregnantAnimals" ||
        this.$route.name === "Weaning" ||
        this.$route.name === "Pregnancies",
      deathVisible: this.$route.name === "Death" || this.$route.name === "DeathReason",
      medicinesVisible: this.$route.name === "Medicines" || this.$route.name === "Doses",
      weighingsVisible: this.$route.name === "Latest" || this.$route.name === "Range",
      milkingsVisible:
        this.$route.name === "MilkGroup" ||
        this.$route.name === "MilkAnimal" ||
        this.$route.name === "Milkings" ||
        this.$route.name === "MilkPickup",
      characteristicsVisible:
        this.$route.name === "Characteristics" ||
        this.$route.name === "AnimalCharacteristics" ||
        this.$route.name === "AnimalsWithCharacteristic",
      customListsVisible:
        this.$route.name === "Lists" ||
        this.$route.name === "Custom List" ||
        this.$route.name === "New List",
    };
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.$http.secured
      .get("/lists")
      .then((response) => {
        this.lists = response.data.data;
      })
      .catch((error) => {
        this.setError(error, "Something went wrong");
      });
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
<style></style>
