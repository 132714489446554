<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 class="page-title">Nieuwe lijst</h1>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-4">
          <AddFilter v-on:save-list-filter="onSaveListFilter" />
        </div>
      </div>
      <div class="row my-3">
        <div class="col-8">
          <b-button v-b-modal="'my-modal'">Show Modal</b-button>
          <router-link :to="{ path: '/passport/' }">Lijst opslaan</router-link>
        </div>
        <div class="col-4 text-right">
          <router-link :to="{ path: '/passport/' }">Verwijder lijst</router-link>
        </div>
      </div>
      <div v-if="animals">
        <div class="row mt-3">
          <div class="col-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Nummer</th>
                  <th>Werknummer</th>
                  <th>Soort</th>
                  <th>Geboren</th>
                  <th>Geslacht</th>
                  <th>Nakomelingen</th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="animal.id" v-for="animal in animals">
                  <td>
                    <router-link
                      :to="{
                        path: '/passport/' + animal.attributes.life_number
                      }"
                    >{{ animal.attributes.life_number }}</router-link>
                  </td>
                  <td>{{ animal.attributes.work_number }}</td>
                  <td>{{ animal.attributes.animal_type }}</td>
                  <td>{{ animal.attributes.date_of_birth }}</td>
                  <td>{{ animal.attributes.gender }}</td>
                  <td>{{ animal.attributes.number_of_children }}</td>
                </tr>
                <tr v-if="animals.length == 0">
                  <td colspan="42" class="text-center">Lege groep</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>
<script>
import moment from "moment";
// import AddFilter from "@/components/Lists/AddFilter.vue";
// import ListFilter from "@/components/Lists/ListFilter.vue";
export default {
  name: "Lists",
  data() {
    return {
      animals: [],
      error: "",
      query: "",
    };
  },
  created() {
    const currentDate = new Date();
    this.$http.secured
      .post("/lists/", {
        list: {
          id: this.list_id,
          name: `Nieuwe lijst ${moment(currentDate).format("DD-MM-YYYY")}`,
          filters_attributes: [],
        },
      })
      .then((response) => {
        this.$router
          .push(`/lists/${response.data.data.id}`)
          .catch((error) => this.setError(error, "Something went wrong"));
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    onSaveListFilter(filter) {
      this.$http.secured
        .post("/lists/", {
          list: {
            id: this.list_id,
            filters_attributes: [
              {
                filter_type: filter.filterType,
                values: filter.values,
              },
            ],
          },
        })
        .then((response) => {
          this.filters = response.data.data.attributes.filters.data;
          this.animals = response.data.data.attributes.animals.data;
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
  },
};
</script>
<style></style>
