<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <router-link class="back" :to="{ path: '/settings/medicines' }">
            <div class="icon-chevron-back"></div>
            Alle medicijnen
          </router-link>
          <h1 class="page-title">Nieuw medicijn</h1>
        </div>
      </div>
      <hr class="my-4" />
      <div class="col-12">
        <b-form @submit.prevent="handleSave">
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Dit medicijn is voor">
              <b-form-radio-group
                v-model="selected"
                required
                :options="[
                  { text: 'Schapen', value: 3 },
                  { text: 'Geiten', value: 4 },
                ]"
                name="radio-inline"
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Medicijn">
              <b-form-input v-model="name" required class="input"></b-form-input
            ></b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500 inputWrapper" label="Toedieningswijze">
              <b-form-select
                v-model="toedieningswijzeOption"
                class="option input"
                :options="toedieningswijzeOptions"
              ></b-form-select
            ></b-form-group>
          </div>
          <div v-if="toedieningswijzeOption === 'else'" class="row mb-4">
            <b-form-group class="font-m-500" label="Anders">
              <b-form-input
                v-model="toedieningswijzeOptionElse"
                placeholder="Anders..."
                class="input"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500 inputWrapper" label="Wachtdagen melk">
              <b-form-input
                v-model="waiting_time_milk"
                type="number"
                placeholder="..."
                class="input daysInput"
              ></b-form-input
              ><span class="icon">dag(en)</span></b-form-group
            >
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500 inputWrapper" label="Wachtdagen vlees"
              ><b-form-input
                v-model="waiting_time_meat"
                placeholder="..."
                type="number"
                class="input daysInput"
              ></b-form-input>
              <span class="icon">dag(en)</span>
            </b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Overige notities"
              ><b-form-input v-model="notes" placeholder="Notities..." class="input"></b-form-input>
            </b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Medicijn opslaan" :state="saveSuccess">
              <template #invalid-feedback
                ><p>Er is iets fout gegaan met het toevoegen van een medicijn.</p></template
              >
              <template #valid-feedback><p>Medicijn opgeslagen</p></template>
              <b-button type="submit" variant="success" :disabled="isDisabled" class="px-4 save"
                >Opslaan</b-button
              >
            </b-form-group>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosInstance } from "@/services/axios";

export default {
  name: "MedicineAdd",
  data() {
    return {
      saveSuccess: null,
      selected: "",
      name: "",
      toedieningswijzeOptions: [
        { value: null, text: "Selecteer..." },
        { value: "bek", text: "In de bek" },
        { value: "spier", text: "In de spier" },
        { value: "onderhuids", text: "Injectie onderhuids" },
        { value: "huid-in", text: "In de huid" },
        { value: "huid-op", text: "Op de huid" },
        { value: "else", text: "Anders" },
      ],
      toedieningswijzeOption: "",
      toedieningswijzeOptionElse: "",
      waiting_time_milk: null,
      waiting_time_meat: null,
      notes: "",
    };
  },
  computed: {
    isDisabled() {
      return !this.name || !this.selected;
    },
  },

  methods: {
    handleSave() {
      const medicine = {
        animal_type: this.selected,
        name: this.name,
        description:
          this.toedieningswijzeOption === "else"
            ? this.toedieningswijzeOptionElse
            : this.toedieningswijzeOption,
        waiting_time_milk: parseInt(this.waiting_time_milk),
        waiting_time_meat: parseInt(this.waiting_time_meat),
        notes: this.notes,
      };
      axiosInstance
        .post("/medicines", medicine)
        .then(() => {
          this.saveSuccess = true;
          setTimeout(() => {
            this.saveSuccess = null;
            this.$router.push("/settings/medicines");
          }, 2000);
        })

        .catch(() => {
          this.saveSuccess = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  color: #0e0649;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: url("../assets/images/icons/filter.svg") !important;
  padding: 5px;
}

.inputWrapper {
  position: relative;
  .input + .icon {
    position: absolute;
    font-size: 0.875rem;
    right: 1rem;
    top: 8px;
    bottom: 0;
    height: 100%;
  }
}

.input {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  font-size: 0.875rem;
  margin-right: 8px;
  margin-bottom: 8px;
  max-height: 43px;
  width: 348px;
  background-color: white;
}

.daysInput {
  width: 224px;
}
.save {
  width: 224px;
  border-radius: 21.5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
