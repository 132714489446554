<template>
  <div class="sidebar main-sidebar">
    <div class="sidebar-wrapper h-100">
      <div class="mt-2 ml-4 logo">
        <a href="#" class="simple-text logo__container">
          <div :class="vueEnv" class="logo-img"></div>
        </a>
      </div>

      <slot name="content"></slot>
      <ul class="nav nav-main__links">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
            @click="closeNavbar"
          >
            <i :class="link.icon"></i>
            <p>{{ link.name }}</p>
          </sidebar-link>
        </slot>
      </ul>
      <ul v-if="$slots['bottom-links']" class="nav nav-bottom">
        <slot name="bottom-links"></slot>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import SidebarLink from "./SidebarLink.vue";
import { boot } from "@/services/intercom";

export default {
  components: {
    SidebarLink,
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  props: {
    title: {
      type: String,
      default: "Gribb",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        const acceptedValues = ["primary", "info", "success", "warning", "danger"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      vueEnv: process.env.VUE_APP_ENV,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  beforeMount() {
    boot({
      email: this.user.email,
      name: this.user.name,
    });
  },
};
</script>
<style>
.sidebar .sidebar-wrapper {
  display: flex;
  flex-direction: column;
}
.sidebar .nav-main__links {
  flex: 1;
}
.sidebar .sidebar-wrapper .logo .logo__container {
  padding-left: 10px;
}
</style>
