import { mapActions } from "vuex";
import { axiosInstance } from "@/services/axios";
import { createQuery } from "@/helpers";
import sort from "@/mixins/sort";
import filter from "@/mixins/filter";

const defaultListPage = (
  pageUrl,
  statisticsType = undefined,
  noDefaultList = false,
  raw = false,
) => ({
  mixins: [sort, filter],
  data() {
    return {
      statistics: null,
      includes: false,
    };
  },
  created() {
    const { page, startDate, endDate } = this.$route.query;

    if ((page || startDate, endDate)) {
      this.initializePagesFromQuery({ page, startDate, endDate });
    } else {
      this.setPage(1);
    }

    Promise.all([this.handleFetchItems({})])
      .then(() => this.pageLoader_resolveData())
      .catch((err) => {
        console.error(err);
        this.pageLoader_resolveData();
      });

    this.$on("sorted", () => this.handleFetchItems({}));
    this.$on("filtered", () => this.handleFetchItems({}));
  },
  watch: {
    includes() {
      this.handleFetchItems({ reset: false });
    },
    period() {
      this.handleFetchAnimals({ reset: false });
    },
  },
  methods: {
    ...mapActions("default_lists", ["fetchItems", "initializePagesFromQuery"]),
    handleFetchItems({ reset }) {
      const filter = {};
      let url = pageUrl;

      filter.page = this.pagination.page;
      filter.start_date = this.period.startDate;
      filter.end_date = this.period.endDate;
      if (this.$route.params.id) {
        filter.id = this.$route.params.id;
      }
      if (url.includes("details")) {
        url = url.replace("details", this.$route.params.range);
      }
      if (url.includes("animals/doses")) {
        url = url.replace("doses", `${(filter.id = this.$route.params.id)}/doses`);
      }
      if (url.includes("death_reasons/cause")) {
        url = url.replace("cause", this.$route.params.cause);
      }
      if (url.includes("medicines/medicine")) {
        url = url.replace("/medicine", `/${this.$route.params.medicine}`);
        filter.medicine = true;
      }
      // if (url.includes("milk_yields")) {
      //   filter.short = true;
      // }
      if (this.includes) filter.filter_animals = !this.includes;
      if (statisticsType) {
        axiosInstance
          .get(`/statistics/${statisticsType}?${createQuery(filter, this.sort, this.filters)}`)
          .then((res) => {
            this.statistics = res.data;
          });
      }

      return this.fetchItems({
        url,
        filter,
        sort: this.sort,
        extraFilters: this.filters,
        reset,
        noDefaultList,
        raw,
      }).then(() => this.setQueryParams());
    },
    fetchPaginatedItems(page) {
      this.setPage(page);
      if (this.includes) this.handleFetchItems({ reset: false });
      else this.handleFetchItems({ reset: false });
    },
    setPage(page) {
      this.$store.commit("default_lists/setPage", page);
    },
    setPeriod(period) {
      const { startDate, endDate } = period;
      this.$store.commit("default_lists/setPeriod", { startDate, endDate });
    },
    setQueryParams() {
      const { page } = this.pagination;
      const { startDate, endDate } = this.period;
      this.$router.push({ query: { page, startDate, endDate } });
    },
    handlePeriodChange(period) {
      this.setPage(1);
      this.setPeriod(period);
      if (this.includes) this.handleFetchItems({ reset: false });
      else this.handleFetchItems({ reset: false });
    },
  },
});
export default defaultListPage;
