<template>
  <div class="characteristics">
    <div v-for="(characteristic, index) in characteristics" :key="index" class="itemContainer">
      <div v-if="promptRemove && promptRemoveIndex === index" class="promptRemoveContainer">
        <span>Zeker weten?</span>
        <div class="promptBtnContainer">
          <button class="btn cancel" @click="promptRemove = false">Nee</button>
          <button class="btn btn-success proceed" @click="handleRemove(index)">Ja</button>
        </div>
      </div>
      <div class="inputWrapper">
        <div v-if="selectedOptionType !== 'edit'" class="inputWrapper">
          <input
            :value="!!characteristic.name ? characteristic.name : characteristic.characteristic"
            :disabled="selectedOptionType !== 'edit'"
            :class="selectedOptionType !== 'edit' ? 'disabledInput' : 'input'"
          />
        </div>
        <b-dropdown
          v-else
          variant="light"
          class="dropdown-input"
          toggle-class="text-decoration-none"
        >
          <template #button-content>
            {{ characteristic.name }}
          </template>
          <b-dropdown-item
            v-for="char in chars"
            :key="char.id"
            @click="
              updateCharacteristic({ target: { value: char.id } }, index, 'charId') ||
                updateCharacteristic({ target: { value: char.name } }, index, 'charName')
            "
          >
            {{ char.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <button
        v-if="selectedOptionType === 'remove'"
        class="btn remove"
        @click="handlePromptRemove(index)"
      >
        <b-icon-trash-fill class="removeIcon" />
      </button>
    </div>
    <div v-if="selectedOptionType === 'add' && addInput === true" class="addInputs">
      <div class="rowInputs">
        <div class="inputWrapper">
          <b-dropdown variant="light" class="dropdown-input" toggle-class="text-decoration-none">
            <template #button-content>
              {{ currentCharacteristicName }}
            </template>
            <b-dropdown-item
              v-for="char in chars"
              :key="char.id"
              @click="setCharacteristicForIndex({ target: { value: char.id } })"
            >
              {{ char.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <b-button class="btn button" variant="warning" @click="submitInput"
        >Toediening bevestigen</b-button
      >
    </div>
    <button v-if="selectedOptionType === 'add'" class="btn addInputButton" @click="addInput = true">
      Kenmerk toevoegen<b-icon-plus class="plusIcon" />
    </button>
  </div>
</template>

<script>
import { BIconTrashFill, BIconPlus } from "bootstrap-vue";

export default {
  name: "CharacteristicsModal",
  components: {
    BIconTrashFill,
    BIconPlus,
  },
  props: {
    selectedAddOption: {
      type: String,
      default: "",
    },
    selectedEditOption: {
      type: String,
      default: "",
    },
    selectedRemoveOption: {
      type: String,
      default: "",
    },
    selectedOptionType: {
      type: String,
      default: "",
    },
    selectedAnimalsCharacteristics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      characteristics: [],
      chars: [],
      currentCharacteristic: null,
      inputValue: { characteristic: "" },
      promptRemove: false,
      promptRemoveIndex: 0,
      addInput: false,
    };
  },
  computed: {
    currentCharacteristicName() {
      return this.currentCharacteristic?.name ?? this.characteristics[0]?.name ?? "Characteristic";
    },
  },
  watch: {
    characteristics() {
      this.$emit("passData", this.characteristics);
    },
  },
  created() {
    if (this.selectedOptionType !== "add") {
      this.characteristics = this.selectedAnimalsCharacteristics.map((characteristic) => {
        return { characteristic };
      });
    }

    this.$http.secured
      .get("/characteristics")
      .then((response) => {
        this.chars = response.data.data.map((characteristic) => characteristic.attributes);
        // eslint-disable-next-line prefer-destructuring
        this.currentCharacteristic = this.chars[0];
      })
      .catch((error) => this.setError(error, "Er ging is fout bij het ophalen van de kenmerken."));
  },
  methods: {
    submitInput() {
      if (this.currentCharacteristic) {
        this.addInput = false;
        this.characteristics.push(this.currentCharacteristic);
        // eslint-disable-next-line prefer-destructuring
        this.currentCharacteristic = this.chars[0];
      }
    },
    handleRemove(index) {
      this.characteristics.splice(index, 1);
      this.promptRemove = false;
    },
    handlePromptRemove(index) {
      this.promptRemove = true;
      this.promptRemoveIndex = index;
    },
    updateCharacteristic(event, index, key) {
      const characteristics = [...this.characteristics];
      characteristics[index][key] = event.target.value;
      this.characteristics = characteristics;
    },
    setCharacteristicForIndex(event) {
      this.currentCharacteristic = this.chars.find((char) => char.id === event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.characteristics {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}
</style>
