<template>
  <div class="col-4">
    <div class="list-filter d-flex mb-3">
      <div class="flex-grow-1">{{ filter.attributes.label }}</div>
      <span class="value">{{ filter.attributes.value_label }}</span>
      <font-awesome-icon class="gray-icon" icon="trash-alt" @click="removeFilter" />
    </div>
  </div>
</template>
<script>
export default {
  name: "ListFilter",
  props: ["filter"],
  methods: {
    removeFilter() {
      this.$emit("delete-list-filter", this.filter.id);
    },
  },
};
</script>
<style></style>
