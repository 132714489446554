<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Gewicht</h1>
          </div>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <!-- <ul class="links">
            <li class="d-inline">
              <a v-on:click="setRangeQueryParams('1-5')">1 – 5 kg</a>
            </li>
            <li><a v-on:click="setRangeQueryParams('6-10')">6 – 10 kg</a></li>
            <li><a v-on:click="setRangeQueryParams('11-15')">11 – 15 kg</a></li>
            <li><a v-on:click="setRangeQueryParams('16-20')">16 – 20 kg</a></li>
            <li><a v-on:click="setRangeQueryParams('21-40')">21 – 40 kg</a></li>
            <li><a v-on:click="setRangeQueryParams('41-60')">41 – 60 kg</a></li>
            <li><a v-on:click="setRangeQueryParams('61-80')">61 – 80 kg</a></li>
            <li>
              <a v-on:click="setRangeQueryParams('81-100')">81 – 100 kg</a>
            </li>
          </ul>-->

          <div>
            <period-filter :period="period" @change="handlePeriodChange" />
            <export-options
              :period="period"
              :url="'default_lists/weighings/range'"
            ></export-options>
          </div>
        </div>
        <div v-if="weighings">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="weighings"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
              >
                <template #name="{ item: weighing }">
                  <router-link
                    :to="{
                      path: `/lists/weight-details/${weighing.name}`,
                    }"
                  >
                    {{ weighing.name }} kg
                  </router-link>
                </template>
                <template #empty>Geen gewichten doorgegeven</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter";
import ExportOptions from "@/components/Lists/ExportOptions";

export default {
  name: "Birth",
  components: {
    PeriodFilter,
    ExportOptions,
  },
  mixins: [pageLoader, defaultListPage("weighings/range")],
  data() {
    return {
      columns: {
        name: "Gewicht tussen",
        number_of_animals_of_type_3: "Aantal schapen",
        number_of_animals_of_type_4: "Aantal geiten",
      },
    };
  },
  computed: {
    ...mapState({
      weighings: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    handleFetchItems({ reset }) {
      const filter = {};
      const url = "weighings/range";
      filter.page = this.pagination.page;
      filter.start_date = this.period.startDate;
      filter.end_date = this.period.endDate;
      filter.range = "1-5";

      return this.fetchItems({ url, filter, reset }).then(() => this.setQueryParams());
    },
    setRangeQueryParams(range) {
      const query = { range, ...this.$route.query };
      this.$router.push({ query });
    },
    keyExtractor(weighing) {
      return weighing.id;
    },
  },
};
</script>
<style></style>
