<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright text-center">
        &copy;
        <a href="https://www.gribb.nl" target="_blank">Gribb v1.3.0</a> - {{ title }}.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    };
  },
};
</script>
<style></style>
