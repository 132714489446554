<template>
  <div class="text-center">
    <div>
      <img class="mb-3 mt-5" alt="Vue logo" src="img/gribb-logo-wit-groot.svg" />
      <form class="form-signin" @submit.prevent="login">
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span id="basic-addon1" class="input-group-text">@</span>
          </div>
          <input
            id="email"
            v-model="email"
            type="email"
            class="form-control"
            placeholder="E-mail"
          />
        </div>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span id="basic-addon1" class="input-group-text">@</span>
          </div>
          <input
            id="password"
            v-model="password"
            type="password"
            class="form-control"
            placeholder="Wachtwoord"
          />
        </div>
        <button type="submit" class="btn btn-primary mb-5 w-100">Inloggen</button>
        <router-link to="/password" class="mt-5 font-weight-bold text-white"
          >Wachtwoord vergeten?</router-link
        >
        <!-- <router-link to="/signup" class="mt-5 font-weight-bold text-white">Account aanmaken</router-link> -->
      </form>
    </div>
    <content-footer></content-footer>
  </div>
</template>

<script>
import ContentFooter from "@/layout/ContentFooter.vue";
import { boot, shutdown } from "@/services/intercom";

export default {
  name: "Signin",
  components: {
    ContentFooter,
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
      apiurl: process.env.API_URL,
    };
  },
  beforeMount() {
    boot();
  },
  beforeDestroy() {
    shutdown();
  },
  methods: {
    login() {
      this.$store
        .dispatch("auth/loginWithEmailAndPassword", {
          user: { email: this.email, password: this.password },
        })
        .then(() => {
          this.$store.dispatch("auth/setUserMeta").then(() => this.$router.push("/dashboard"));
        })
        .catch((errorMessage) => {
          this.$toasted.error(errorMessage, {
            position: "top-center",
            duration: 5000,
          });
        });
    },
  },
};
</script>
