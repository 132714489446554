<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="offset-xl-1 col-xl-10 offset-0 col-lg-12 mb-4">
            <h1>
              {{ user.name }}
              <select
                v-if="user.location_options.length > 1"
                v-model="currentLocation"
                class="select-css"
                @change="setCurrentLocation($event)"
              >
                <option
                  v-for="location_option in user.location_options"
                  :key="location_option.value"
                  :value="location_option.value"
                >
                  {{ location_option.label }}
                </option>
              </select>
            </h1>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="offset-xl-1 col-xl-7 col-8">
            <h6>Diersoort</h6>
            <select
              v-model="currentAnimalType"
              class="select-css"
              @change="setCurrentAnimalType($event)"
            >
              <option
                v-for="animal_types_option in user.animal_type_options"
                :key="animal_types_option.value"
                :value="animal_types_option.value"
              >
                {{ animal_types_option.label }}
              </option>
            </select>
          </div>
          <div class="col-xl-3 col-lg-4">
            <h6>Snel naar paspoort</h6>
            <base-autocomplete
              type="text"
              placeholder="Vul een werknummer in"
              class="form-group search"
              :search-data="lifeNumbers"
              @onResult="handleResult"
            ></base-autocomplete>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="offset-xl-1 col-xl-10 offset-0 col-lg-12 mb-4">
            <hr class="mb-4" />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="offset-xl-1 col-xl-7 col-md-8">
            <h6>Statistieken van dit jaar</h6>
            <div class="row dashboard">
              <div class="col-6">
                <div class="border-box">
                  <router-link :to="{ path: 'lists/birth' }">
                    <h6>Voortplanting</h6>
                  </router-link>
                  <div class="row ml-0">
                    <h2>{{ statistics.number_of_births }}</h2>
                    <span class="ml-1"
                      >(<span v-b-tooltip.hover role="button" title="vrouwelijk">{{
                        statistics.number_of_female_births
                      }}</span
                      >)</span
                    >
                    <span class="mx-1">|</span>
                    <span
                      >(<span v-b-tooltip.hover role="button" title="mannelijke">{{
                        statistics.number_of_male_births
                      }}</span
                      >)</span
                    >
                  </div>
                  <span class="text-muted-light">levend geboren</span>
                  <hr />
                  <h2>{{ statistics.number_of_total_mother_births }}</h2>
                  <span class="text-muted-light">moederdieren dit jaar</span>
                </div>
                <div class="border-box">
                  <router-link :to="{ path: 'lists/all' }">
                    <h6>Dieraantallen</h6>
                  </router-link>
                  <div class="row ml-0">
                    <h2>{{ statistics.number_of_female_animals[currentAnimalType] }}</h2>
                    <span class="ml-1"
                      >(<span v-b-tooltip.hover role="button" title="jonger dan 1 jaar">{{
                        statistics.number_of_year_young_female_animals[currentAnimalType]
                      }}</span
                      >)</span
                    >
                    <span class="mx-1">|</span>
                    <span
                      >(<span v-b-tooltip.hover role="button" title="ouder dan 1 jaar">{{
                        statistics.number_of_year_older_female_animals[currentAnimalType]
                      }}</span
                      >)</span
                    >
                  </div>
                  <span class="text-muted-light">vrouwen</span>
                  <hr />
                  <div class="row ml-0">
                    <h2>{{ statistics.number_of_male_animals[currentAnimalType] }}</h2>
                    <span class="ml-1"
                      >(<span v-b-tooltip.hover role="button" title="jonger dan 1 jaar">{{
                        statistics.number_of_year_young_male_animals[currentAnimalType]
                      }}</span
                      >)</span
                    >
                    <span class="mx-1">|</span>
                    <span
                      >(<span v-b-tooltip.hover role="button" title="ouder dan 1 jaar">{{
                        statistics.number_of_year_older_male_animals[currentAnimalType]
                      }}</span
                      >)</span
                    >
                  </div>
                  <span class="text-muted-light">mannen</span>
                  <hr />
                  <div class="row ml-0">
                    <h2>{{ statistics.number_of_genderless_animals[currentAnimalType] }}</h2>
                    <span class="ml-1"
                      >(<span v-b-tooltip.hover role="button" title="jonger dan 1 jaar">{{
                        statistics.number_of_year_young_genderless_animals[currentAnimalType]
                      }}</span
                      >)</span
                    >
                    <span class="mx-1">|</span>
                    <span
                      >(<span v-b-tooltip.hover role="button" title="ouder dan 1 jaar">{{
                        statistics.number_of_year_older_genderless_animals[currentAnimalType]
                      }}</span
                      >)</span
                    >
                  </div>
                  <span class="text-muted-light">onbekend</span>
                </div>
              </div>
              <div class="col-6">
                <div class="border-box">
                  <router-link :to="{ path: 'transports' }">
                    <h6>Transport</h6>
                  </router-link>
                  <h2>{{ statistics.number_of_imports }}</h2>
                  <span class="text-muted-light">aanvoer</span>
                  <hr />
                  <h2>{{ statistics.number_of_exports }}</h2>
                  <span class="text-muted-light">afvoer</span>
                </div>
                <div class="border-box">
                  <router-link :to="{ path: 'lists/death' }">
                    <h6>Sterfte</h6>
                  </router-link>
                  <h2>{{ statistics.number_of_deaths }}</h2>
                  <span class="text-muted-light">gestorven</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4">
            <h6>
              Berichten <span class="text-muted">({{ missingRecent.length }})</span>
            </h6>
            <div v-if="missingRecent.length" id="messages">
              <router-link :to="{ path: 'lists/messages/missing' }">
                <div v-for="message in missingRecent" :key="message.id" class="message p-3 mb-3">
                  <h6>{{ message.signalering_code_reden }}</h6>
                  <span class="mb-0">{{ message.human_message }}</span>
                </div>
              </router-link>
            </div>
            <hr />

            <h6 v-show="user.rvo_enabled">
              Levensnummers
              <span class="text-muted">({{ statistics.remaining_life_numbers["number"] }})</span>
            </h6>
            <div v-show="user.rvo_enabled" id="lifenumbers" class="mt-3">
              <router-link :to="{ path: '/levensnummers-bestellen' }">
                <div class="message p-3 mb-3">
                  <div v-if="statistics.remaining_life_numbers['percentage'] < 10">
                    <h6 class="text-danger">Let op!</h6>
                    <p>
                      Nog maar
                      <strong>{{ statistics.remaining_life_numbers["number"] }}</strong>
                      beschikbaar.
                    </p>
                  </div>
                  <div v-else>
                    <h6>Beschikbaar</h6>
                    <p>
                      Nog
                      <strong>{{ statistics.remaining_life_numbers["number"] }}</strong>
                      beschikbaar.
                    </p>
                  </div>
                  <p class="mb-0 text-blue">Bestel nieuwe Levensnummers</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "Dashboard",
  mixins: [pageLoader],
  data() {
    return {
      error: "",
      currentAnimalType: "",
      currentLocation: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
      missingRecent: "messages/missingRecent",
    }),
    ...mapState({
      lifeNumbers: (state) => state.animals.lifeNumbers,
      statistics: (state) => state.statistics.period,
      animalTypeOptions: (state) => state.auth.user.animal_type_options,
      locationOptions: (state) => state.auth.user.locationOptions,
      primaryAnimalType: (state) => state.auth.user.primaryAnimalType,
      messages: (state) => state.messages.missingItems,
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.fetchLocationData();
  },
  methods: {
    ...mapActions("animals", ["fetchAnimalLifeNumbers"]),
    ...mapActions("statistics", ["fetchStatistics"]),
    ...mapActions("messages", ["fetchMissingMessages"]),
    setCurrentAnimalType(event) {
      localStorage.setItem("currentAnimalType", event.target.value);
    },
    setCurrentLocation(event) {
      this.$http.secured
        .put("/users/update_location", { location_id: event.target.value })
        .then(() => {
          this.$store.dispatch("auth/setUserMeta").then(() => {
            this.currentLocation = event.target.value;
            this.fetchLocationData();
          });
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    handleResult(animalId) {
      this.$router.push({
        name: "passport",
        params: { id: animalId },
      });
    },
    fetchLocationData() {
      Promise.all([
        this.fetchStatistics(),
        this.fetchAnimalLifeNumbers(),
        this.fetchMissingMessages(),
      ])
        .then(() => {
          const storageAnimalType = localStorage.getItem("currentAnimalType") || "";
          if (storageAnimalType == "") {
            const { primary_animal_type } = this.user;
            localStorage.setItem("currentAnimalType", primary_animal_type);
            this.currentAnimalType = primary_animal_type;
          } else {
            this.currentAnimalType = storageAnimalType;
          }
          this.currentLocation = this.user.current_location_id;
          this.pageLoader_resolveData();
        })
        .catch(() => {
          this.pageLoader_resolveData();
        });
    },
  },
};
</script>
<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: transparent;
  border: none;
  color: #2e994c;
  text-transform: lowercase;
  font-variant: normal;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #2e994c;
}
.style-chooser .vs__selected-options {
  display: inline;
  flex-grow: 0;
  flex-basis: unset;
}
.style-chooser .vs__selected {
  color: #2e994c;
  font-size: 2em;
  padding-left: 0px;
}
</style>
