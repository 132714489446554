<template>
  <div class="sidebar sub-sidebar">
    <div class="sidebar-wrapper h-100">
      <ul class="nav flex-column nav-main__links">
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path: '/settings/contacts' }">Contacten</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path: '/settings/medicines' }"
            >Medicijnen</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path: '/settings/treatmentplans' }"
            >Behandelplannen</router-link
          >
        </li>
      </ul>
      <ul class="nav nav-bottom">
        <li v-show="user.rvo_enabled" class="nav-item">
          <div v-if="dismissCountDown" class="p-3">
            <b-alert
              :show="dismissCountDown"
              variant="light"
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChanged"
            >
              <p>Taak gestart. Verwerking kan een paar minuten duren</p>
            </b-alert>
          </div>
          <a v-else class="nav-link" @click="handleTriggerRvoComparison"
            >RVO verschillen oplossen</a
          >
        </li>
        <li v-show="user.rvo_enabled" class="nav-item">
          <a class="nav-link" @click="handleTriggerSync">Forceer RVO update</a>
        </li>
        <li v-show="user.rvo_enabled" class="nav-item">
          <a class="nav-link" @click="handleFullTriggerSync">Forceer RVO volledige update</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      activeNotifications: false,
      lists: [],
      error: "",
      query: "",
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    };
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    handleTriggerRvoComparison() {
      this.$http.secured
        .get("/animals/trigger_rvo_differences_worker")
        .then(() => {
          this.showAlert();
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    handleTriggerSync() {
      this.$http.secured
        .get("/animals/trigger_sync")
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    handleFullTriggerSync() {
      this.$http.secured
        .get("/animals/trigger_sync?mode=full")
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
<style>
.sidebar,
.sub-sidebar {
  display: flex;
  flex-direction: column;
}
.sidebar .nav-main__links {
  flex: 1;
}
.sub-sidebar .nav-main__links {
  margin-top: 5.5rem;
}
</style>
