<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">
              Sponzen
              <small v-if="animals"> ({{ pagination.totalCount }}) </small>
            </h1>
          </div>
        </div>
        <div v-if="statistics" class="row">
          <core-stat-card
            category="Totaal ingebrachte sponzen"
            :value="statistics.number_of_inserted_sponges"
          />
          <core-stat-card
            category="Totaal uitgenomen sponzen"
            :value="statistics.number_of_extracted_sponges"
          />
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <search-input class="mr-auto" column="animal_life_number" @search="onSearch" />
          <export-options :period="period" :url="'sponges'"></export-options>
        </div>
        <div v-if="animals">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="animals"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #animal_life_number="{ item }">
                  <router-link
                    class="animal_numbers"
                    :to="{
                      path: '/passport/' + item.animal.id + '/rvo',
                    }"
                  >
                    <span>{{ item.animal.animal_numbers.life_number }}</span>
                    <span>{{ item.animal.animal_numbers.work_number }}</span>
                  </router-link>
                </template>
                <template #animal_type="{ item }">
                  {{ item.animal.human_animal_type }}
                </template>
                <template #date_of_birth="{ item }">
                  {{ item.animal.date_of_birth }}
                </template>
                <template #inserted_at="{ item }">
                  {{ item.inserted_at | formatDate }}
                </template>
                <template #extracted_at="{ item }">
                  {{ item.extracted_at | formatDate }}
                </template>
                <template #empty>
                  <template v-if="animals.length === 0">Geen dieren</template>
                  <template v-else>Geen dieren gevonden</template>
                </template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import SearchInput from "@/components/SearchInput.vue";
import ExportOptions from "@/components/Lists/ExportOptions.vue";
import animalTypes from "@/enums/animalTypes";
import CoreStatCard from "@/components/Cards/CoreStatCard.vue";

export default {
  name: "Sponzen",
  components: {
    ExportOptions,
    SearchInput,
    CoreStatCard,
  },
  mixins: [pageLoader, defaultListPage("sponges", "sponges", true)],
  data() {
    return {
      period: {
        startDate: new Date(0),
        endDate: new Date(2524608000000),
      },
      columns: {
        animal_life_number: "(Werk)nummer",
        animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        date_of_birth: { title: "Geboren", filter: { type: "date" } },
        inserted_at: { title: "Inbrengdatum", filter: { type: "date" } },
        extracted_at: { title: "Uitneemdatum", filter: { type: "date" } },
      },
    };
  },
  computed: {
    ...mapState({
      animals: (state) => {
        return state.default_lists.items.map((item) => {
          const animal = state.default_lists.included.find(
            (includedAnimal) => includedAnimal.id === item.relationships.animal.data.id,
          );
          return {
            ...item,
            animal: {
              id: animal.id,
              ...animal.attributes,
              animal_numbers: {
                life_number: animal.attributes.life_number.substring(
                  0,
                  animal.attributes.life_number.length - 5,
                ),
                work_number: animal.attributes.work_number.substring(
                  animal.attributes.work_number.length - 5,
                ),
              },
            },
          };
        });
      },
      pagination: (state) => state.default_lists.pagination,
    }),
  },
  methods: {
    keyExtractor(animal) {
      return animal.id;
    },
  },
};
</script>
<style></style>
