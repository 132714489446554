import { render, staticRenderFns } from "./DeathDetails.vue?vue&type=template&id=90b08868&"
import script from "./DeathDetails.vue?vue&type=script&lang=js&"
export * from "./DeathDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports