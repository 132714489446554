<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Paspoort</h1>
            <base-autocomplete
              type="text"
              placeholder="Vul een werknummer in"
              class="form-group search"
              :search-data="lifeNumbers"
              @onResult="handleResult"
            ></base-autocomplete>
          </div>
        </div>
        <div v-if="!$route.params.id">
          <div class="row justify-content-center">
            <div class="col-12">
              <div
                class="d-flex align-items-center justify-content-center empty-message text-center"
              >
                <span>Zoek op werknummer om een paspoort te bekijken</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mt-4 mb-4 d-flex justify-content-between align-items-center">
            <tabs
              v-if="user.rvo_enabled === false"
              :items="['Overzicht', 'Medicijnen']"
              :active-index="selectedTabIndex"
              @change="onTabChange"
            />
            <tabs
              v-else
              :items="['Overzicht', 'Medicijnen', 'Verblijfsplaatsen', 'RVO details']"
              :active-index="selectedTabIndex"
              @change="onTabChange"
            />
          </div>
          <Overview v-if="selectedTabIndex === 0" />
          <MedicineHistory v-if="selectedTabIndex === 1" />
          <WhereAbouts v-if="selectedTabIndex === 2" />
          <RvoOverview v-if="selectedTabIndex === 3" />
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Overview from "@/components/Animals/Overview.vue";
import MedicineHistory from "@/components/Animals/MedicineHistory.vue";
import WhereAbouts from "@/components/Animals/WhereAbouts.vue";
import RvoOverview from "@/components/Animals/RvoOverview.vue";
import pageLoader from "@/mixins/pageLoader";
import Tabs from "@/components/Tabs.vue";

export default {
  name: "Passport",
  components: {
    Tabs,
    Overview,
    MedicineHistory,
    WhereAbouts,
    RvoOverview,
  },
  mixins: [pageLoader],
  data() {
    return {
      selectedTabIndex: 0,
      animalLoaded: false,
      animals: [],
      animalId: "",
      tabIndex: 0,
    };
  },
  computed: {
    ...mapState({
      lifeNumbers: (state) => state.animals.lifeNumbers,
      groups: (state) => state.groups.items,
      user: (state) => state.auth.user,
    }),
  },
  created() {
    if (this.$route.params.tab !== undefined) {
      switch (this.$route.params.tab) {
        case "overview":
          this.tabIndex = 0;
          break;
        case "medicine":
          this.tabIndex = 1;
          break;
        case "whereabouts":
          this.tabIndex = 2;
          break;
        case "rvo":
          this.tabIndex = 3;
          break;
        default:
          this.tabIndex = 0;
      }
    } else {
      this.tabIndex = 0;
    }
    Promise.all([this.fetchAnimalLifeNumbers(), this.fetchGroups()])
      .then(() => this.pageLoader_resolveData())
      .catch(() => {
        this.pageLoader_resolveData();
      });
  },
  methods: {
    ...mapActions("animals", ["fetchAnimalLifeNumbers"]),
    ...mapActions("groups", ["fetchGroups"]),
    handleResult(animalId) {
      this.$router.push({
        name: "passport",
        params: { id: animalId, tab: "overview" },
      });
    },
    onTabChange(tabIndex) {
      this.selectedTabIndex = tabIndex;
    },
  },
};
</script>
<style></style>
