<template>
  <div class="tabs">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="tab"
      :class="{ active: index === activeIndex }"
      @click="onTabClick(index)"
    >
      {{ item }}
    </div>
  </div>
</template>
<script>
export default {
  name: "Tabs",
  props: {
    items: Array,
    activeIndex: Number,
  },
  methods: {
    onTabClick(index) {
      if (this.activeIndex !== index) {
        this.$emit("change", index);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/bootstrap/custom-bootstrap.scss";

.tabs {
  display: flex;
  flex-direction: row;
  border: 1px solid $gray;
  box-sizing: border-box;
  border-radius: 8px;

  .tab {
    cursor: pointer;
    margin: -1px;
    padding: 0.758rem 1.125rem;
    box-sizing: border-box;
    font-weight: bold;
    color: $blueLighter;

    &.active {
      background: $grayLight;
      border: 1px solid $gray;
      border-radius: 8px;
      color: $blueDark;
    }
  }
}
</style>
