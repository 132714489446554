<template>
  <div>
    <div v-if="message">
      <b-form @submit="onSubmit">
        <p>{{ message.human_message }}</p>
        <div class="row my-4">
          <div class="col-3">
            <h6>Ontvanger</h6>
            <p>{{ message.meldingeenheid_tweede_partij }}</p>
          </div>
          <div class="col-5">
            <b-form-group id="input-group-2" label="Vervoerder" label-for="input-2">
              <b-form-select
                v-model="form.carrierId"
                required
                :options="selectOptions"
              ></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-2" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.licensePlate"
                required
                placeholder="Kenteken"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4">
            <div>
              <b-form-group id="input-group-2" label="Afvoer datum" label-for="input-2">
                <b-form-datepicker
                  id="example-datepicker"
                  v-bind="labels"
                  v-model="form.scheduled"
                  :start-weekday="weekday"
                  placeholder="Kies een datum"
                  :min="min"
                  :max="max"
                  :required="required"
                  :hide-header="hideHeader"
                  locale="nl"
                  class="mb-2"
                ></b-form-datepicker>
              </b-form-group>
            </div>
          </div>
        </div>

        <h6>Dieren ({{ [message.diergegevens].flat().length }})</h6>
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Werknummer</th>
              <th>Soort</th>
              <th>Geboren</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="animal in [message.diergegevens].flat()" :key="animal.dier_levensnummer">
              <td>{{ animal.full_life_number }}</td>
              <td>{{ animal.dier_werknummer }}</td>
              <td>{{ animal.human_animal_type }}</td>
              <td>{{ animal.geboortedatum }}</td>
            </tr>
          </tbody>
        </table>
        <b-button v-if="!response && !errors" type="submit" variant="success">
          <span v-if="pageLoader_isDataLoaded"> Melden aan RVO</span>
          <b-spinner v-if="!pageLoader_isDataLoaded" small></b-spinner>
        </b-button>
      </b-form>
    </div>

    <div v-if="response">
      <div v-if="response.status == 201">
        <b-alert variant="success" show>De ontbrekende afvoer is opgelost!</b-alert>
        <router-link to="/dashboard">
          <b-button variant="success">Terug naar dashboard</b-button>
        </router-link>
      </div>
    </div>
    <div v-if="errors">
      <div v-for="errorMessage in [errors].flat()" :key="errorMessage">
        <b-alert variant="danger" show>
          <strong>Levensnummer</strong> {{ errorMessage.life_number }}<br />
          {{ errorMessage.error_message }}
          <small>Code {{ errorMessage.error_code }}</small>
        </b-alert>
      </div>
      <p>Oplossen? Neem contact op met Gribb via +31 (0)6 461 352 90 of help@gribb.nl</p>
      <router-link to="/dashboard">
        <b-button variant="success">Terug naar dashboard</b-button>
      </router-link>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "Export",
  mixins: [pageLoader],
  props: ["message"],
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      contacts: [],
      selectedDate: Date.now(),
      destinationContact: "",
      response: "",
      min: today,
      max: today,
      hideHeader: true,
      required: true,
      error: "",
      errors: "",
      query: "",
      weekday: 1,
      labels: {
        labelPrevDecade: "Vorig decennia",
        labelPrevYear: "Vorig jaar",
        labelPrevMonth: "Vorheriger maand",
        labelCurrentMonth: "Deze maand",
        labelNextMonth: "Volgende maand",
        labelNextYear: "Volgend jaar",
        labelNextDecade: "Volgend decennia",
        labelToday: "Vandaag",
        labelSelected: "Geselecteerde datum",
        labelNoDateSelected: "Geen datum",
        labelCalendar: "Kalender",
        labelNav: "Kalendernavigatie",
        labelHelp: "Je kunt met de pijltjes navigeren",
      },
      form: {
        licensePlate: "",
        scheduled: Date.now(),
        carrierId: "",
        destinationId: "",
      },
    };
  },
  computed: {
    selectOptions() {
      return [this.contacts].flat().map((contact) => {
        return { value: contact.id, text: contact.attributes.company_name };
      });
    },
  },
  created() {
    this.form.licensePlate = this.$props.message.kenteken;
    this.form.scheduled = moment(this.$props.message.gebeurtenisdatum, "DD-MM-YYYY").toDate();
    this.max = moment(this.$props.message.gebeurtenisdatum, "DD-MM-YYYY").toDate();
    this.min = moment(this.$props.message.gebeurtenisdatum, "DD-MM-YYYY")
      .subtract(1, "days")
      .toDate();
    this.getContacts();
  },
  methods: {
    getContacts() {
      this.$http.secured
        .get("/contacts")
        .then((response) => {
          this.contacts = response.data.data;
          this.getContact();
          this.pageLoader_resolveData();
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    getContact() {
      const destinationContact = [this.contacts]
        .flat()
        .find(
          (contact) => contact.attributes.ubn == this.$props.message.meldingeenheid_tweede_partij,
        );
      if (!destinationContact) {
        this.$http.secured
          .post("/contacts/create_missing", {
            create_missing: {
              ubn: this.$props.message.meldingeenheid_tweede_partij,
              animal_type: this.$props.message.dier_soort,
              life_number: [this.$props.message.diergegevens].flat()[0].dier_levensnummer,
            },
          })
          .then((response) => {
            this.form.destinationId = response.data.data.attributes.id;
            this.getContacts();
          })
          .catch((error) => this.setError(error, "Something went wrong"));
      } else {
        this.form.destinationId = destinationContact.attributes.id;
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.saveExport();
    },
    saveExport() {
      const animals = [this.$props.message.diergegevens].flat().map((animal) => {
        return {
          life_number: animal.full_life_number,
          animal_type: this.$props.message.dier_soort,
        };
      });
      this.$http.secured
        .post("/exports/", {
          export: {
            scheduled: this.form.scheduled,
            license_plate: this.form.licensePlate,
            carrier_id: this.form.carrierId,
            destination_id: this.form.destinationId,
            animals,
          },
        })
        .then((response) => {
          this.response = response;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>
<style></style>
