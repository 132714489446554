<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Dieren met kenmerken</h1>
          </div>
        </div>
        <b-form-checkbox
          id="included-checkbox"
          v-model="includes"
          name="included-checkbox"
          :value="true"
          :unchecked-value="false"
          class="mt-2 mb-2"
        >
          Inclusief niet aanwezige dieren
        </b-form-checkbox>
        <div class="mt-2 mb-2 d-flex justify-content-between align-items-center">
          <search-input class="mr-auto" @search="onSearch" />
          <!-- <period-filter :period="period" @change="handlePeriodChange" /> -->
          <export-options
            :period="period"
            :url="'default_lists/characteristics/animals'"
          ></export-options>
        </div>
        <div v-if="animals">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="animals"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #life_number="{ item: animal }">
                  <router-link
                    :to="{
                      path: '/passport/' + animal.id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ animal.animal_numbers.lifeNumber }}</span>
                    <span>{{ animal.animal_numbers.workNumber }}</span>
                  </router-link>
                </template>
                <template #gender="{ item: animal }">
                  {{ animal.human_gender }}
                </template>
                <template #animal_type="{ item: animal }">
                  {{ animal.human_animal_type }}
                </template>
                <template #characteristics="{ item: animal }">
                  {{ animal.human_characteristics }}
                </template>
                <template #empty>Geen kenmerken</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
// import PeriodFilter from "@/components/Lists/PeriodFilter";
import ExportOptions from "@/components/Lists/ExportOptions";
import SearchInput from "@/components/SearchInput";
import genders from "@/enums/genders";
import animalTypes from "@/enums/animalTypes";

export default {
  name: "Birth",
  components: {
    ExportOptions,
    SearchInput,
  },
  mixins: [pageLoader, defaultListPage("characteristics/animals")],
  data() {
    return {
      columns: {
        life_number: "(Werk)nummer",
        animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        gender: { title: "Geslacht", filter: { type: "enum", values: genders } },
        date_of_birth: { title: "Geboren", filter: { type: "date" } },
        characteristics: {
          title: "Kenmerken",
          filter: {
            type: "characteristics",
            values: this.fetchItems({ url: "characteristics/characteristics", filter: {} }).then(
              (result) =>
                result.map((item) => ({
                  value: encodeURIComponent(item.name),
                  label: item.name,
                })),
            ),
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      animals: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(animal) {
      return animal.id;
    },
  },
};
</script>
<style></style>
