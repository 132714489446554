export default {
  data() {
    return {
      filters: null,
    };
  },
  methods: {
    onSearch(search) {
      this.filters = { ...this.filters, [search.key]: { type: "text", value: search.value } };
      this.$emit("filtered");
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, ...filter };
      this.$emit("filtered");
    },
  },
};
