<template>
  <div class="calendar-years">
    <div class="calendar-years-section-selectors">
      <button class="switch-button" @click="lastSection">
        <div class="left" />
      </button>
      <button class="switch-button" @click="nextSection">
        <div class="right" />
      </button>
    </div>
    <div class="calendar-years-content">
      <div class="calendar-options">
        <div v-for="row in 3" :key="row" class="calendar-options-row">
          <year
            v-for="index in 7"
            :key="index"
            :year="1992 + yearSection + row * 7 + index"
            :start-date="startDate"
            :end-date="endDate"
            :on-select-date="selectedDate"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Year from "./Year.vue";

export default {
  name: "PeriodFilterYears",
  components: { Year },
  props: {
    singleDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    startDate: {
      type: Date,
      default: undefined,
      required: false,
    },
    endDate: {
      type: Date,
      default: undefined,
      required: false,
    },
    onDatesChange: {
      type: Function,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      yearSection: Math.floor(Number(`${moment().year()}`.slice(2)) / 10) * 10,
    };
  },
  methods: {
    selectedDate(year) {
      let start = this.startDate;
      let end = this.singleDate ? this.startDate : this.endDate;

      // Check if the end or start year is selected to uncheck these dates.
      // This makes it easier to change years.
      if (end && year === moment(end).year()) {
        this.onDatesChange({ start, end: undefined });
        return;
      }
      if (start && year === moment(start).year()) {
        this.onDatesChange({ start: undefined, end });
        return;
      }

      if (!start || this.singleDate) {
        start = moment().year(year).startOf("year").toDate();
        if (this.singleDate) {
          end = moment().year(year).endOf("year").toDate();
        }
      } else {
        end = moment().year(year).endOf("year").toDate();
      }

      // Make sure end date and start date are not reversed.
      if (moment(end).isBefore(start, "year")) {
        const tempStart = start;
        start = end;
        end = tempStart;
      }

      this.onDatesChange({ start, end });
    },
    lastSection() {
      this.yearSection -= 21;
    },
    nextSection() {
      this.yearSection += 21;
    },
  },
};
</script>
<style scope lang="scss">
.calendar-years {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  > .calendar-years-section-selectors {
    display: flex;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  > .calendar-years-content {
    display: flex;
    height: 100%;
    flex-direction: row;
    flex: 1;

    > div {
      padding: 16px;
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
