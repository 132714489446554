var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('router-link',{staticClass:"back",attrs:{"to":{ path: '/settings/treatmentplans' }}},[_c('div',{staticClass:"icon-chevron-back"}),_vm._v(" Alle behandelplannen ")]),_c('h1',{staticClass:"page-title"},[_vm._v("Nieuw behandelplan")])],1)]),_c('hr',{staticClass:"my-4"}),_c('div',{staticClass:"col-12"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row mb-4"},[_c('b-form-group',{staticClass:"font-m-500",attrs:{"label":"Dit medicijn is voor"}},[_c('b-form-radio-group',{attrs:{"required":"","options":[
                { text: 'Schapen', value: 3 },
                { text: 'Geiten', value: 4 } ],"name":"animal-type-selector"},model:{value:(_vm.treatmentplan.animal_type),callback:function ($$v) {_vm.$set(_vm.treatmentplan, "animal_type", $$v)},expression:"treatmentplan.animal_type"}})],1)],1),_c('div',{staticClass:"row mb-4"},[_c('b-form-group',{staticClass:"font-m-500",attrs:{"label":"Aandoening"}},[_c('b-form-input',{staticClass:"input",attrs:{"required":""},model:{value:(_vm.treatmentplan.disease),callback:function ($$v) {_vm.$set(_vm.treatmentplan, "disease", $$v)},expression:"treatmentplan.disease"}})],1)],1),_c('div',{staticClass:"row mb-4"},[_c('b-form-group',{staticClass:"font-m-500",attrs:{"label":"Eerste keus medicijnen"}},[(!_vm.openMedicine1 && _vm.treatmentplan.medicine_first_choice_attributes.medicine_id)?_c('b-form-input',{staticClass:"input",attrs:{"disabled":"","value":_vm.treatmentplan.medicine_first_choice_attributes.medicine_name}}):_vm._e(),(!_vm.openMedicine1 && !_vm.treatmentplan.medicine_first_choice_attributes.medicine_id)?_c('add-button',{attrs:{"text":"Medicijn toevoegen","size":"l"},on:{"handleAddClick":function($event){_vm.openMedicine1 = true}}}):_vm._e(),(_vm.openMedicine1)?_c('medicine-select',{staticClass:"mb-4",attrs:{"medicines":_vm.medicines},on:{"submit":_vm.getMedicine1}}):_vm._e(),_c('div',{staticClass:"row pl-3"},[(
                  !_vm.openCombi1 &&
                  _vm.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
                    .medicine_id
                )?_c('b-form-input',{staticClass:"input m",attrs:{"disabled":"","value":_vm.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
                    .medicine_name}}):_vm._e(),(
                  !_vm.openCombi1 &&
                  !_vm.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
                    .medicine_id
                )?_c('add-button',{attrs:{"text":"In combinatie met...","size":"m"},on:{"handleAddClick":function($event){_vm.openCombi1 = true}}}):_vm._e(),(_vm.openCombi1)?_c('medicine-select',{staticClass:"mb-4",attrs:{"medicines":_vm.medicines},on:{"submit":_vm.getCombi1}}):_vm._e(),(
                  !_vm.openAlt1 &&
                  _vm.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
                    .medicine_id
                )?_c('b-form-input',{staticClass:"input m",attrs:{"disabled":"","value":_vm.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
                    .medicine_name}}):_vm._e(),(
                  !_vm.openAlt1 &&
                  !_vm.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
                    .medicine_id
                )?_c('add-button',{attrs:{"text":"Alternatief toevoegen","size":"m"},on:{"handleAddClick":function($event){_vm.openAlt1 = true}}}):_vm._e(),(_vm.openAlt1)?_c('medicine-select',{staticClass:"mb-4",attrs:{"medicines":_vm.medicines},on:{"submit":_vm.getAlt1}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"row mb-4"},[_c('b-form-group',{staticClass:"font-m-500",attrs:{"label":"Tweede keus medicijnen"}},[(!_vm.openMedicine2 && _vm.treatmentplan.medicine_second_choice_attributes.medicine_id)?_c('b-form-input',{staticClass:"input",attrs:{"disabled":"","value":_vm.treatmentplan.medicine_second_choice_attributes.medicine_name}}):_vm._e(),(
                !_vm.openMedicine2 && !_vm.treatmentplan.medicine_second_choice_attributes.medicine_id
              )?_c('add-button',{attrs:{"text":"Medicijn toevoegen","size":"l"},on:{"handleAddClick":function($event){_vm.openMedicine2 = true}}}):_vm._e(),(_vm.openMedicine2)?_c('medicine-select',{staticClass:"mb-4",attrs:{"medicines":_vm.medicines},on:{"submit":_vm.getMedicine2}}):_vm._e(),_c('div',{staticClass:"row pl-3"},[(
                  !_vm.openCombi2 &&
                  _vm.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes &&
                  _vm.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
                    .medicine_id
                )?_c('b-form-input',{staticClass:"input m",attrs:{"disabled":"","value":_vm.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
                    .medicine_name}}):_vm._e(),(
                  !_vm.openCombi2 &&
                  _vm.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes &&
                  !_vm.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
                    .medicine_id
                )?_c('add-button',{attrs:{"text":"In combinatie met...","size":"m"},on:{"handleAddClick":function($event){_vm.openCombi2 = true}}}):_vm._e(),(_vm.openCombi2)?_c('medicine-select',{staticClass:"mb-4",attrs:{"medicines":_vm.medicines},on:{"submit":_vm.getCombi2}}):_vm._e(),(
                  !_vm.openAlt2 &&
                  _vm.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes
                    .medicine_id
                )?_c('b-form-input',{staticClass:"input m",attrs:{"disabled":"","value":_vm.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes
                    .medicine_name}}):_vm._e(),(
                  !_vm.openAlt2 &&
                  !_vm.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes
                    .medicine_id
                )?_c('add-button',{attrs:{"text":"Alternatief toevoegen","size":"m"},on:{"handleAddClick":function($event){_vm.openAlt2 = true}}}):_vm._e(),(_vm.openAlt2)?_c('medicine-select',{staticClass:"mb-4",attrs:{"medicines":_vm.medicines},on:{"submit":_vm.getAlt2}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"row mb-4"},[_c('b-form-group',{staticClass:"font-m-500",attrs:{"label":"Overige notities"}},[_c('b-form-input',{staticClass:"input",attrs:{"placeholder":"Notitie..."},model:{value:(_vm.treatmentplan.notes),callback:function ($$v) {_vm.$set(_vm.treatmentplan, "notes", $$v)},expression:"treatmentplan.notes"}})],1)],1),_c('div',{staticClass:"row mb-4"},[_c('b-form-group',{staticClass:"font-m-500",attrs:{"label":"Behandelplan opslaan","state":_vm.saveSuccess},scopedSlots:_vm._u([{key:"invalid-feedback",fn:function(){return [_c('p',[_vm._v("Er is iets fout gegaan met het toevoegen van een behandelplan.")])]},proxy:true},{key:"valid-feedback",fn:function(){return [_c('p',[_vm._v("Behandelplan opgeslagen")])]},proxy:true}])},[_c('b-button',{staticClass:"px-4 save",attrs:{"type":"submit","variant":"success"}},[_vm._v("Opslaan")])],1)],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }