<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <router-link class="back" :to="{ path: '/lists/weight-range' }">
              <div class="icon-chevron-back"></div>
              Alle gewicht ranges
            </router-link>
            <h1 class="page-title">Gewicht tussen {{ $route.params.range }}</h1>
          </div>
        </div>
        <b-form-checkbox
          id="included-checkbox"
          v-model="includes"
          name="included-checkbox"
          :value="true"
          :unchecked-value="false"
          class="mt-2 mb-2"
        >
          Inclusief niet aanwezige dieren
        </b-form-checkbox>
        <div class="mt-2 mb-2 d-flex justify-content-between align-items-center">
          <search-input class="mr-auto" column="animal_life_number" @search="onSearch" />
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options
            :period="period"
            :url="`default_lists/weighings/range/${$route.params.range}`"
          ></export-options>
        </div>
        <div v-if="weighings">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="weighings"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #animal_life_number="{ item: weighing }">
                  <router-link
                    :to="{
                      path: '/passport/' + weighing.id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ weighing.animal_numbers.lifeNumber }}</span>
                    <span>{{ weighing.animal_numbers.workNumber }}</span>
                  </router-link>
                </template>
                <template #animal_gender="{ item: weighing }">
                  {{ weighing.human_gender }}
                </template>
                <template #animal_animal_type="{ item: weighing }">
                  {{ weighing.human_animal_type }}
                </template>
                <template #weight="{ item: weighing }">
                  {{ weighing.last_weight }}
                </template>
                <template #weight_date="{ item: weighing }">
                  {{ formatDate(weighing.weighings[weighing.weighings.length - 1].created_at) }}
                </template>
                <template #empty>Geen gewichten doorgegeven</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
import ExportOptions from "@/components/Lists/ExportOptions.vue";
import SearchInput from "@/components/SearchInput.vue";
import genders from "@/enums/genders";
import animalTypes from "@/enums/animalTypes";

export default {
  name: "WeighingsRangeDetails",
  components: {
    PeriodFilter,
    ExportOptions,
    SearchInput,
  },
  mixins: [pageLoader, defaultListPage(`weighings/range/details`)],
  data() {
    return {
      columns: {
        animal_life_number: "(Werk)nummer",
        animal_animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        animal_gender: { title: "Geslacht", filter: { type: "enum", values: genders } },
        date_of_birth: { title: "Geboren", filter: { type: "date" } },
        weight: { title: "Gewicht", filter: { type: "number" } },
        weight_date: { title: "Gewogen op", filter: { type: "date" } },
      },
    };
  },
  computed: {
    ...mapState({
      weighings: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    keyExtractor(weighing) {
      return weighing.id;
    },
  },
};
</script>
<style></style>
