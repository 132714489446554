<template>
  <button
    :class="{
      'calendar-option': true,
      'calendar-option-selected': isDateSelected(),
      'calendar-option-between-dates': isDateBetweenStartAndEnd(),
      'calendar-option-disabled': !isDateClickable(),
      'calendar-option-is-start-date': isStartDate(),
      'calendar-option-is-end-date': isEndDate(),
    }"
    @click="handleDateSelect()"
  >
    {{ namedMonth }}
  </button>
</template>
<script>
import moment from "moment";

export default {
  name: "Month",
  props: {
    singleDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    month: {
      type: Number,
      default: 1,
      required: true,
    },
    year: {
      type: Number,
      default: 1970,
      required: true,
    },
    startDate: {
      type: Date,
      default: undefined,
    },
    endDate: {
      type: Date,
      default: undefined,
    },
    startSelection: {
      type: Boolean,
      default: true,
    },
    onSelectDate: {
      type: Function,
      required: true,
    },
  },
  computed: {
    namedMonth() {
      return new Date(
        Date.parse(`${this.year}-${`0${this.month + 1}`.slice(-2)}-01`),
      ).toLocaleDateString("nl-NL", {
        month: "short",
      });
    },
  },
  methods: {
    handleDateSelect() {
      this.onSelectDate(this.startSelection, this.month, this.year);
    },
    isDateSelected() {
      const date = moment().month(this.month).year(this.year);
      if (this.startSelection) {
        return (
          !!this.startDate &&
          date.isSame(this.startDate, "month") &&
          date.isSame(this.startDate, "year")
        );
      }
      if (
        moment(this.startDate).isSame(this.endDate, "month") &&
        moment(this.startDate).isSame(this.endDate, "year")
      ) {
        return false;
      }
      return this.startSelection
        ? !!this.startDate &&
            date.isSame(this.startDate, "month") &&
            date.isSame(this.startDate, "year")
        : date.isSame(this.endDate, "month") && date.isSame(this.endDate, "year") && !!this.endDate;
    },
    isDateBetweenStartAndEnd() {
      if (this.singleDate) return false;
      const date = moment().month(this.month).year(this.year);
      if (!this.startDate || !this.endDate) return false;
      if (
        moment(this.startDate).isSame(this.endDate, "month") &&
        moment(this.startDate).isSame(this.endDate, "year")
      ) {
        return false;
      }
      return (
        (date.isSame(this.startDate, "month") && date.isSame(this.startDate, "year")) ||
        (date.isSame(this.endDate, "month") && date.isSame(this.endDate, "year")) ||
        date.isBetween(this.startDate, this.endDate, "month")
      );
    },
    isDateClickable() {
      if (this.singleDate) return true;
      const date = moment().month(this.month).year(this.year);
      if (
        this.startSelection &&
        moment(this.startDate).isSame(this.endDate, "month") &&
        moment(this.startDate).isSame(this.endDate, "year")
      ) {
        return true;
      }
      return this.startSelection
        ? !this.endDate || (date.isBefore(this.endDate, "month") && !!this.endDate)
        : date.isAfter(this.startDate, "month") && !!this.startDate;
    },
    isStartDate() {
      if (this.singleDate) return false;
      const date = moment().month(this.month).year(this.year);
      if (
        moment(this.startDate).isSame(this.endDate, "month") &&
        moment(this.startDate).isSame(this.endDate, "year")
      ) {
        return false;
      }
      return !!this.startDate && date.isSame(this.startDate, "month") && !!this.endDate;
    },
    isEndDate() {
      if (this.singleDate) return false;
      const date = moment().month(this.month).year(this.year);
      if (
        moment(this.startDate).isSame(this.endDate, "month") &&
        moment(this.startDate).isSame(this.endDate, "year")
      ) {
        return false;
      }
      return !!this.endDate && date.isSame(this.endDate, "month") && !!this.startDate;
    },
  },
};
</script>
<style scope lang="scss"></style>
