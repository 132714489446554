<template>
  <div class="medicine-select-container">
    <div class="col-12">
      <div class="row mb-2">
        <span class="description">
          Geef de minimale behandelingsduur aan om het medicijn toe te voegen aan deze aandoening.
        </span>
      </div>
      <div class="row mb-2">
        <b-form-select
          v-model="selectedMedicine"
          class="selectMedicine"
          :options="medicineOptions"
        ></b-form-select>
      </div>
      <div class="row">
        <b-form-group class="font-s-500 inputWrapper" label="Minimale behandelingsduur">
          <b-form-input v-model="treatment_duration" class="input daysInput"></b-form-input>
          <span class="icon">dag(en)</span>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group class="font-s-500 inputWrapper" label="Dosering">
          <b-form-input v-model="dosage" class="input"></b-form-input>
          <span class="icon">aantal</span>
          <b-form-radio-group
            v-model="dosage_unit"
            required
            :options="[
              { text: 'ml per dier', value: 'ml' },
              { text: 'gram per dier', value: 'g' },
              { text: 'per stuk', value: 'apiece' },
            ]"
            name="dose-select"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group class="font-s-500" label="Frequentie">
          <div class="row pl-3">
            <div class="inputWrapper">
              <b-form-input v-model="frequency" class="input frequencyInput"></b-form-input>
              <span class="icon">{{ frequency_unit === "once" ? "keer" : "keer per" }}</span>
            </div>
            <div>
              <b-form-select
                v-model="frequency_unit"
                required
                :options="[
                  { text: 'Selecteer...', value: '' },
                  { text: 'Uur', value: 'hour' },
                  { text: 'Dag', value: 'day' },
                  { text: 'Eenmalig', value: 'once' },
                  { text: 'Anders', value: 'other' },
                ]"
                name="radio-doses"
              ></b-form-select>
            </div>
            <div v-if="frequency_unit === 'other'">
              <b-form-input
                v-model="frequency_custom"
                placeholder="Anders, nameklijk..."
                class="input frequencyInput ml-2"
              ></b-form-input>
            </div>
          </div>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group class="font-s-500" label="Overige notitie">
          <b-form-input v-model="notes" class="input"></b-form-input>
        </b-form-group>
      </div>
      <div class="row mb-1">
        <b-button
          variant="success"
          :disabled="verifySave()"
          class="px-4 save"
          @click.prevent="handleSubmit"
          >Opslaan</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MedicineSelect",
  props: {
    medicines: {
      type: Array,
      required: true,
    },
    oldMedicine: {
      type: String,
      required: false,
      default: null,
    },
    oldTreatmentPlanMedicines: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedMedicine: null,
      treatment_duration: "",
      dosage: "",
      dosage_unit: "",
      frequency: "",
      frequency_unit: "",
      frequency_custom: "",
      notes: "",
      medicine_id: "",
      loaded: false,
    };
  },
  computed: {
    isLoaded() {
      if (this.medicines.length > 0 && this.oldMedicine && this.oldTreatmentPlanMedicines) {
        return true;
      }
      return false;
    },

    selectedMedicineName() {
      if (this.oldMedicine && this.oldTreatmentPlanMedicines) {
        return this.oldTreatmentPlanMedicines.find((medicine) => medicine.id === this.oldMedicine)
          .attributes;
      }
      return null;
    },
    medicineOptions() {
      return this.medicines.map((medicine) => {
        return {
          text: medicine.attributes.name,
          value: medicine,
        };
      });
    },
  },

  created() {
    const medicine = this.oldTreatmentPlanMedicines.find((med) => med.id === this.oldMedicine);
    const selectedMedicine = this.medicines.find((med) => {
      return med.id === medicine.relationships.medicine.data.id;
    });
    const tempObj = {
      ...medicine.attributes,
      medicine_id: medicine.id,
    };
    this.treatment_duration = tempObj.treatment_duration;
    this.dosage = tempObj.dosage;
    this.dosage_unit = tempObj.dosage_unit;
    this.frequency = tempObj.frequency;
    this.frequency_unit = tempObj.frequency_unit;
    this.frequency_custom = tempObj.frequency_custom;
    this.notes = tempObj.notes;
    this.medicine_id = tempObj.medicine_id;
    this.selectedMedicine = selectedMedicine;
  },

  methods: {
    handleSubmit() {
      this.$emit("submit", {
        treatment_duration: this.treatment_duration,
        dosage: this.dosage,
        dosage_unit: this.dosage_unit,
        frequency: this.frequency,
        frequency_unit: this.frequency_unit,
        frequency_custom: this.frequency_custom,
        notes: this.notes,
        medicine_id: this.selectedMedicine.id,
        medicine_name: this.selectedMedicine.attributes.name,
      });
    },
    verifySave() {
      if (
        this.selectedMedicine !== null &&
        this.treatment_duration !== "" &&
        this.dosage !== "" &&
        this.frequency !== "" &&
        this.frequency_unit !== ""
      ) {
        if (this.frequency_unit === "other" && this.frequency_custom === "") {
          return true;
        }
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.description {
  font-size: 0.875rem;
  font-weight: normal;
}
.medicine-select-container {
  background: #e4e4e4;
  width: 100%;
  height: fit-content;
  padding: 24px;
}

.inputWrapper {
  position: relative;
  .input + .icon {
    position: absolute;
    font-size: 0.875rem;
    right: 1rem;
    top: 8px;
    bottom: 0;
    height: 100%;
  }
}

.input {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 14px 16px;
  font-size: 0.875rem;
  margin-right: 8px;
  margin-bottom: 8px;
  max-height: 43px;
  width: 348px;
  background-color: white;
}

.daysInput {
  width: 224px !important;
}
.frequencyInput {
  width: 192px !important;
}

.selectMedicine {
  width: 348px;
}
</style>
