export default {
  methods: {
    translateDaypart(value) {
      if (value === "afternoon") {
        return "Middag";
      }
      if (value === "morning") {
        return "Ochtend";
      }
      if (value === "evening") {
        return "Avond";
      }
      return "Nacht";
    },
    translateAnimalType(value) {
      if (value === "sheep") {
        return "Schaap";
      }

      return "Geit";
    },
  },
};
