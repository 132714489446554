import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;
const securedAxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const plainAxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

securedAxiosInstance.interceptors.request.use((config) => {
  const gribbUuid = localStorage.getItem("gribb-uuid");
  const token = localStorage.getItem("gribb-jwt");
  const method = config.method.toUpperCase();
  if (method !== "OPTIONS") {
    config.headers = {
      ...config.headers,
      Authorization: token,
      jwt_aud: gribbUuid,
    };
  }
  return config;
});

securedAxiosInstance.interceptors.response.use(null, (error) => {
  if (error.response && error.response.config && error.response.status === 401) {
    const gribbUuid = localStorage.getItem("gribb-uuid");
    const token = localStorage.getItem("gribb-jwt");
    return plainAxiosInstance
      .post("/refresh", {}, { headers: { Authorization: token, jwt_aud: gribbUuid } })
      .then((response) => {
        localStorage.setItem("gribb-jwt", response.headers.authorization);
        localStorage.signedIn = true;

        const retryConfig = error.response.config;
        retryConfig.headers.Authorization = localStorage.getItem("gribb-jwt");
        retryConfig.headers.jwt_aud = localStorage.getItem("gribb-uuid");
        return plainAxiosInstance.request(retryConfig);
      })
      .catch((error) => {
        localStorage.removeItem("gribb-uuid");
        localStorage.removeItem("gribb-jwt");
        delete localStorage.signedIn;

        location.replace("/");
        return Promise.reject(error);
      });
  }
  return Promise.reject(error);
});

export { securedAxiosInstance, plainAxiosInstance };
