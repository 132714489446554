<template>
  <button
    :class="{
      'calendar-option': true,
      'calendar-option-selected': isDateSelected(),
      'calendar-option-between-dates': isDateBetweenStartAndEnd(),
      'calendar-option-is-start-date': isStartDate(),
      'calendar-option-is-end-date': isEndDate(),
    }"
    @click="handleDateSelect()"
  >
    {{ year }}
  </button>
</template>
<script>
import moment from "moment";

export default {
  name: "Year",
  props: {
    year: {
      type: Number,
      default: 1970,
      required: true,
    },
    startDate: {
      type: Date,
      default: undefined,
    },
    endDate: {
      type: Date,
      default: undefined,
    },
    onSelectDate: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleDateSelect() {
      this.onSelectDate(this.year);
    },
    isDateSelected() {
      const date = moment().year(this.year);
      return (
        (!!this.startDate && date.isSame(this.startDate, "year")) ||
        (!!this.endDate && date.isSame(this.endDate, "year"))
      );
    },
    isDateBetweenStartAndEnd() {
      const date = moment().year(this.year);
      if (!this.startDate || !this.endDate) return false;
      if (moment(this.startDate).isSame(this.endDate, "year")) {
        return false;
      }
      return (
        date.isSame(this.startDate, "year") ||
        date.isSame(this.endDate, "year") ||
        date.isBetween(this.startDate, this.endDate, "year")
      );
    },
    isStartDate() {
      const date = moment().year(this.year);
      if (moment(this.startDate).isSame(this.endDate, "year")) {
        return false;
      }
      return !!this.startDate && date.isSame(this.startDate, "year") && !!this.endDate;
    },
    isEndDate() {
      const date = moment().year(this.year);
      if (moment(this.startDate).isSame(this.endDate, "year")) {
        return false;
      }
      return !!this.endDate && date.isSame(this.endDate, "year") && !!this.startDate;
    },
  },
};
</script>
<style scope lang="scss"></style>
