<template>
  <div>
    <div class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Melkgift per melkbeurt</h1>
          </div>
        </div>
        <div v-if="statistics" class="row">
          <core-stat-card
            category="Gem. melkgift ochtend in L"
            :value="statistics.average_morning_milking_amount | roundDecimal"
          />
          <core-stat-card
            category="Gem. melkgift middag in L"
            :value="statistics.average_afternoon_milking_amount | roundDecimal"
          />
          <core-stat-card
            category="Gem. melkgift avond in L"
            :value="statistics.average_evening_milking_amount | roundDecimal"
          />
          <core-stat-card
            category="Gem. totale melkgift in L"
            :value="statistics.average_milking_amount | roundDecimal"
          />
          <core-stat-card
            category="Gem. totale melkgift per dier"
            :value="statistics.average_milking_amount_per_animal | roundDecimal"
          />
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <!-- TODO: -->
          <!-- <period-filter :period="period" @change="handlePeriodChange" /> -->
          <!-- <export-options :period="period" :url="'default_lists/deaths/animals'"></export-options> -->
        </div>
        <div v-if="milkyields">
          <div class="row">
            <div class="col-12">
              <Table :columns="columns" :data="milkyields" :key-extractor="keyExtractor">
                <template #animal_type="{ item: milkyield }">{{
                  translateAnimalType(milkyield.attributes.animal_type)
                }}</template>
                <template #day_part="{ item: milkyield }">{{
                  translateDaypart(milkyield.attributes.day_part)
                }}</template>
                <template #empty>Geen melkgift gevonden</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
        <div v-else>
          <AppSpinner />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
// import ExportOptions from "@/components/Lists/ExportOptions.vue";
import CoreStatCard from "@/components/Cards/CoreStatCard.vue";
import { axiosInstance } from "@/services/axios";
import parseTranslations from "@/mixins/parseTranslations";

export default {
  name: "MilkPerGroup",
  components: {
    // PeriodFilter,
    // ExportOptions,
    CoreStatCard,
  },
  mixins: [parseTranslations],
  data() {
    return {
      milkyields: null,
      statistics: null,
      pagination: null,
      columns: {
        "attributes.amount": "Liters",
        animal_type: "Diersoort",
        "attributes.date": "Datum",
        day_part: "Dagdeel",
      },
    };
  },
  created() {
    axiosInstance.get("/statistics/milkings").then((res) => (this.statistics = res.data));
    axiosInstance.get("/milkings").then((res) => {
      this.milkyields = res.data.data.map((milkyield) => milkyield);
      this.pagination = res.data.meta;
    });
  },

  methods: {
    keyExtractor(item) {
      return item.id;
    },
  },
};
</script>
<style></style>
