<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Hermerken</h1>
          </div>
        </div>
        <div class="mt-4 mb-2 d-flex align-items-center">
          <div>
            <search-input class="mr-auto" column="old_life_number" @search="onSearch" />
            <period-filter :period="period" @change="handlePeriodChange" />
            <export-options
              :period="period"
              :url="'default_lists/life_number_replacements/animals'"
            ></export-options>
          </div>
        </div>
        <div v-if="life_number_replacements">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="life_number_replacements"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #animal_animal_type="{ item }">
                  {{ item.animal_human_animal_type }}
                </template>
                <template #empty>Geen hernummering</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter";
import ExportOptions from "@/components/Lists/ExportOptions";
import SearchInput from "@/components/SearchInput";
import animalTypes from "@/enums/animalTypes";

export default {
  name: "Birth",
  components: {
    PeriodFilter,
    ExportOptions,
    SearchInput,
  },
  mixins: [pageLoader, defaultListPage("life_number_replacements/animals")],
  data() {
    return {
      columns: {
        old_life_number: "Oude nummer",
        new_life_number: "Nieuwe nummer",
        animal_animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        created_at: { title: "Datum", filter: { type: "date" } },
      },
    };
  },
  computed: {
    ...mapState({
      life_number_replacements: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(life_number_replacement) {
      return life_number_replacement.id;
    },
  },
};
</script>
<style></style>
