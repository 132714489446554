<template>
  <dropdown :open="open" :dropdown-class="dropdownClass" @clickOutside="cancel">
    <div v-if="headerText" class="header">{{ headerText }}</div>
    <div class="items">
      <div
        v-for="item in filteredItems"
        :key="item.value"
        class="d-flex align-items-center item"
        :class="{ indented: item.level === 1 }"
        @click.self="onItemClick(item)"
      >
        <b-icon-chevron-down
          v-if="item.hasChildren"
          class="chevron"
          :flip-v="opened.includes(item.value)"
          @click="onChevronClick(item)"
        ></b-icon-chevron-down>
        <b-form-checkbox
          class="checkbox"
          :checked="checked.includes(item.value)"
          :indeterminate="
            item.hasChildren &&
            getUncheckedChildCount(item) !== 0 &&
            getUncheckedChildCount(item) !== item.children.length
          "
        >
          {{ item.label }}
        </b-form-checkbox>
      </div>
    </div>
    <div class="footer d-flex justify-content-between align-items-center">
      <b-button variant="success" @click="submit">Ok</b-button>
      <b-button variant="link" class="cancel" @click="cancel">Annuleren</b-button>
    </div>
  </dropdown>
</template>
<script>
import { BIconChevronDown } from "bootstrap-vue";
import Dropdown from "@/components/Dropdowns/Dropdown";

export default {
  components: {
    BIconChevronDown,
    Dropdown,
  },
  props: {
    open: Boolean,
    headerText: String,
    dropdownClass: String,
    items: Array,
    initialChecked: Array,
  },
  data() {
    return {
      lastChecked: [],
      checked: this.initialChecked || [],
      opened: [],
    };
  },
  computed: {
    flattenedItems() {
      return this.items.reduce((result, item) => {
        if (item.children && item.children.length > 0) {
          return [
            ...result,
            { ...item, level: 0, hasChildren: true },
            ...item.children.map((child) => ({
              ...child,
              level: 1,
              parent: item.value,
            })),
          ];
        }

        return [...result, { ...item, level: 0, hasChildren: false }];
      }, []);
    },
    filteredItems() {
      return this.flattenedItems.filter(
        (item) => item.parent === undefined || this.opened.includes(item.parent),
      );
    },
  },
  watch: {
    open(current, previous) {
      if (!previous && current) {
        this.lastChecked = [...this.checked];
        this.opened = [];
      }
    },
    initialChecked(current, previous) {
      if (JSON.stringify(current) !== JSON.stringify(previous)) {
        this.checked = current;
      }
    },
  },
  methods: {
    onChevronClick(item) {
      if (this.opened.includes(item.value)) {
        this.opened = this.opened.filter((value) => value !== item.value);
      } else {
        this.opened = [...this.opened, item.value];
      }
    },
    onItemClick(item) {
      const children = item.children?.map((child) => child.value) || [];

      if (this.checked.includes(item.value)) {
        this.checked = this.checked.filter(
          (value) => value !== item.value && !children.includes(value),
        );
      } else {
        this.checked = [...this.checked, item.value, ...children];
      }

      this.checkParentChecked(item);
    },
    checkParentChecked(child) {
      if (!child.parent) {
        return;
      }

      const parent = this.items.find((item) => item.value === child.parent);
      const uncheckedChildCount = this.getUncheckedChildCount(parent);

      if (!this.checked.includes(parent.value) && uncheckedChildCount === 0) {
        this.checked = [...this.checked, parent.value];
      } else if (this.checked.includes(parent.value) && uncheckedChildCount > 0) {
        this.checked = this.checked.filter((value) => value !== parent.value);
      }
    },
    getUncheckedChildCount(parent) {
      return parent.children.filter((item) => !this.checked.includes(item.value)).length;
    },
    submit() {
      this.$emit("change", this.checked);
    },
    cancel() {
      this.checked = [...this.lastChecked];
      this.$emit("cancel");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/bootstrap/custom-bootstrap.scss";

.items {
  max-height: 240px;
  overflow-y: auto;
}

.header,
.item {
  border-bottom: 1px solid $gray;
  padding: 0.75rem;
  font-size: 0.875rem;

  &:last-child {
    border-bottom: 0;
  }
}

.header {
  color: $grayDark;
  font-style: italic;
}

.item {
  cursor: pointer;

  &:hover {
    background-color: $grayLight;
  }

  &.indented {
    padding-left: 3.25rem;
  }

  .chevron {
    margin-right: 0.5rem;
    color: $blueLighter;
  }

  .checkbox {
    pointer-events: none;
  }
}

.footer {
  border-top: 1px solid $gray;
  padding: 0.75rem;

  .cancel {
    color: $blueLighter;
    font-weight: 500;
    font-size: 0.875rem;
    cursor: pointer;
  }
}
</style>
