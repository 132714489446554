var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pageLoader_isDataLoaded)?_c('div',{staticClass:"content"},[(_vm.animalId)?_c('div',[(
          Object.prototype.hasOwnProperty.call(_vm.animal.meta, 'verwerkingsresultaat') &&
          _vm.animal.meta['verwerkingsresultaat']['succes_indicator'] == 'N'
        )?_c('div',{staticClass:"row justify-content-center my-4"},[_c('div',{staticClass:"col-12 text-red"},[_vm._v(" "+_vm._s(_vm.animal.meta["verwerkingsresultaat"]["foutmelding"])+" ")])]):_c('div',[_c('div',{staticClass:"row justify-content-center my-4"},[_c('div',{staticClass:"col-6"},[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("Dier details")]),(Object.prototype.hasOwnProperty.call(_vm.animal.meta, 'dier_details'))?_c('div',_vm._l((_vm.animal.meta['dier_details']),function(value,k){return _c('div',{key:k},[_c('label',[_vm._v(_vm._s(_vm.humanize(k)))]),_vm._v(" : "+_vm._s(_vm.humanize(value) || "-")+" ")])}),0):_c('div',[_vm._v("—")])])],1),_c('div',{staticClass:"col-6"},[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("RVO Vlaggen")]),(Object.prototype.hasOwnProperty.call(_vm.animal.meta, 'dier_vlaggen'))?_c('ul',{staticClass:"list-group list-group-flush",attrs:{"slot":"raw-content"},slot:"raw-content"},_vm._l(([_vm.animal.meta['dier_vlaggen']].flat()),function(flag){return _c('li',{key:flag,staticClass:"list-group-item"},_vm._l((flag),function(value,k){return _c('span',{key:k,staticClass:"d-block"},[_c('label',[_vm._v(_vm._s(_vm.humanize(k)))]),_vm._v(" : "+_vm._s(value)+" ")])}),0)}),0):_c('div',[_vm._v("—")])])],1)]),_c('div',{staticClass:"row justify-content-center my-4"},[_c('div',{staticClass:"col-8"},[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("Verblijf")]),(
                  Object.prototype.hasOwnProperty.call(
                    _vm.animal.meta,
                    'dier_details_verblijfplaatsen'
                  )
                )?_c('ul',{staticClass:"list-group list-group-flush",attrs:{"slot":"raw-content"},slot:"raw-content"},_vm._l(([_vm.animal.meta['dier_details_verblijfplaatsen']].flat()),function(location){return _c('li',{key:location,staticClass:"list-group-item"},[_c('address',_vm._l((location),function(value,k){return _c('span',{key:k,staticClass:"d-block"},[_c('label',[_vm._v(_vm._s(_vm.humanize(k)))]),_vm._v(" : "+_vm._s(value)+" ")])}),0)])}),0):_c('div',[_vm._v("—")])])],1),_c('div',{staticClass:"col-4"},[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("RVO resultaat")]),(Object.prototype.hasOwnProperty.call(_vm.animal.meta, 'verwerkingsresultaat'))?_c('div',[_vm._v(" "+_vm._s(_vm.animal.meta["verwerkingsresultaat"])+" ")]):_vm._e()])],1)])])]):_vm._e()]):_c('div',{staticClass:"content"},[_c('AppSpinner')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }