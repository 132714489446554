<template>
  <button :class="`btn addInputButton ${size}`" @click.prevent="handleClick">
    {{ text }}<b-icon-plus class="plusIcon" />
  </button>
</template>
<script>
import { BIconPlus } from "bootstrap-vue";

export default {
  name: "AddButton",
  components: {
    BIconPlus,
  },
  props: {
    text: {
      type: String,
      default: "Nieuwe toevoegen",
    },
    size: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$emit("handleAddClick");
    },
  },
};
</script>
<style lang="scss" scoped>
.addInputButton {
  position: relative;
  border: 1px dashed #949494;
  border-radius: 8px;
  padding: 14px 16px;
  font-size: 0.875rem;
  margin-right: 8px;
  margin-bottom: 8px;
  max-height: 43px;
  min-width: 224px;
  color: #949494;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.l {
  width: 348px !important;
}
.m {
  width: 224px !important;
}
</style>
