<template>
  <div
    :class="{
      'd-inline-block': true,
      'period-picker': true,
      'picker-disabled': disabled,
    }"
  >
    <div class="period-picker-button range-text" @click="onClickPicker">
      <label>{{ singleDate ? "Peildatum" : "Periode" }}</label>
      &nbsp;
      <span>{{ getRangeText(localSubmittedViewType, period.startDate, period.endDate) }}</span>
    </div>
    <period-filter-modal
      arrow-anabled
      :single-date="singleDate"
      :open="open"
      :default-view-type="defaultViewType"
      :period="period"
      :disabled="disabled"
      :disable-views="disableViews"
      @change="handleChange"
      @cancel="handleCancel"
    ></period-filter-modal>
  </div>
</template>
<script>
import PeriodFilterModal from "./PeriodFilter/PeriodFilterModal.vue";
import rangeText from "./PeriodFilter/rangeText";

export default {
  name: "PeriodFilter",
  components: {
    PeriodFilterModal,
  },
  mixins: [rangeText],
  props: {
    singleDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultViewType: {
      type: String,
      required: false,
      default: "months",
    },
    period: {
      type: Object,
      required: true,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableViews: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      localSubmittedViewType: this.defaultViewType ?? "months",
      open: false,
    };
  },
  methods: {
    onClickPicker() {
      if (!this.disabled) {
        this.togglePicker(null);
      }
    },
    togglePicker(value) {
      if (typeof value === "boolean") {
        this.open = value;
      } else {
        this.open = !this.open;
      }
    },
    handleCancel() {
      this.togglePicker(false);
      this.$emit("cancel");
    },
    handleChange(data) {
      this.localSubmittedViewType = data.type;
      this.togglePicker(false);
      this.$emit("change", data);
    },
  },
};
</script>
<style scope lang="scss">
.period-picker {
  position: relative;

  .period-picker-button {
    margin-right: 15px;
    cursor: pointer;
  }

  .range-text {
    color: #2e994c;
    margin-left: 1rem;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    display: inline-block;
    padding-top: 0.65rem;
    padding-right: 2rem;
    background-color: transparent;
    background-image: url("~@/assets/images/icons/chevron-green-down.svg");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 1.2em, 0 0;
    background-size: 0.65em auto, 100%;
  }
}

.picker-disabled {
  cursor: default !important;

  > .period-picker-button {
    > span {
      opacity: 0.5;
    }
  }
}
</style>
