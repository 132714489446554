import querystring from "query-string";
import moment from "moment";

export const rejectError = ({ response = null }) => {
  let message = "Ooops, something went wrong";

  if (response && response.data) {
    message = response.data;
  }

  return Promise.reject(message);
};

export const createQuery = (filters, sort, extraFilters) => {
  let params = { ...filters };

  if (sort) {
    params = { ...params, "query[s]": `${sort.key} ${sort.order === 1 ? "asc" : "desc"}` };
  }

  if (extraFilters) {
    Object.keys(extraFilters).forEach((key) => {
      const filter = extraFilters[key];

      if (filter.type === "text" && filter.value) {
        params = { ...params, [`query[${key}_cont]`]: filter.value };
      }

      if (filter.type === "characteristics") {
        params = { ...params, [`query[${key}_cont_any][]`]: filter.value };
      }

      if (filter.type === "enum" || filter.type === "async") {
        params = { ...params, [`query[${key}_in][]`]: filter.value };
      }

      if (
        filter.type === "number" &&
        (filter.value.lessThan ||
          filter.value.equalTo ||
          filter.value.greaterThan ||
          filter.value.inBetween) &&
        !(
          filter.value.lessThan &&
          filter.value.equalTo &&
          filter.value.greaterThan &&
          filter.value.inBetween
        )
      ) {
        let matcher = `${key}_`;

        if (filter.value.lessThan && filter.value.greaterThan) {
          matcher += "not_eq";
        }

        if (filter.value.inBetween) {
          params = {
            ...params,
            [`query[${key}_gteq]`]: filter.value.value,
            [`query[${key}_lteq]`]: filter.value.value2,
          };

          return;
        }
        if (filter.value.lessThan) {
          matcher += "lt";
        } else if (filter.value.greaterThan) {
          matcher += "gt";
        }

        if (filter.value.equalTo) {
          matcher += "eq";
        }

        params = { ...params, [`query[${matcher}]`]: filter.value.value };
      }

      if (filter.type === "date") {
        params = {
          ...params,
          [`query[${key}_gteq]`]: moment(filter.value.startDate).format("YYYY-MM-DD"),
          [`query[${key}_lteq]`]: moment(filter.value.endDate).format("YYYY-MM-DD"),
        };
      }
    });
  }

  return querystring.stringify(params, { encode: true });
};

export const applyFilters = (url, filter) => {
  if (filter) {
    let filteredEntities = "";
    if (url.indexOf("?") === -1) {
      url += "?";
    } else {
      url += "&";
    }

    Object.keys(filter).forEach((key) => {
      filteredEntities += `${key}=${filter[key]}&`;
    });

    if (filteredEntities.slice(-1) === "&") {
      filteredEntities = filteredEntities.slice(0, -1);
    }

    return url + filteredEntities;
  }

  return url;
};

export const processLocation = (location) => {
  return location
    .toLowerCase()
    .replace(/[\s,]+/g, "")
    .trim();
};

export const camelToSnakeCase = (text) => {
  if (!text) return "";
  return text
    .replace(/(.)([A-Z][a-z]+)/, "$1_$2")
    .replace(/([a-z0-9])([A-Z])/, "$1_$2")
    .toLowerCase();
};
