import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { plainAxiosInstance } from "@/services/axios";
import { rejectError } from "@/helpers";

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false,
  },

  getters: {
    authUser(state) {
      return state.user || null;
    },
    currentAnimalType(state) {
      return state.user.currentAnimalType || null;
    },
    isAuthenticated(state) {
      return !!state.user;
    },
  },
  actions: {
    loginWithEmailAndPassword({ commit }, userData) {
      if (localStorage.getItem("gribb-uuid") === null) {
        localStorage.setItem("gribb-uuid", uuidv4());
      }
      const gribbUuid = localStorage.getItem("gribb-uuid");
      return plainAxiosInstance
        .post("/sign_in", userData, {
          headers: {
            jwt_aud: gribbUuid,
          },
        })
        .then((response) => {
          const user = response.data;
          localStorage.setItem("gribb-jwt", response.headers.authorization);
          localStorage.setItem("user", JSON.stringify(user));
          commit("setAuthUser", user);
          const userData = response.data;
          userData.authorization = response.headers.authorization;
          commit("SET_USER_DATA", userData);
        })
        .catch((err) => rejectError(err));
    },
    requestNewPassword({ commit }, userData) {
      return plainAxiosInstance
        .post("/password", userData)
        .then(() => {
          commit("setAuthUser", null);
        })
        .catch((err) => rejectError(err));
    },
    updatePassword({ commit }, userData) {
      return plainAxiosInstance
        .put("/password", userData)
        .then(() => {
          commit("setAuthUser", null);
        })
        .catch((err) => rejectError(err));
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        localStorage.removeItem("gribb-uuid");
        localStorage.removeItem("gribb-jwt");
        localStorage.removeItem("user");
        commit("setAuthUser", null);
        resolve(true);
      });
    },
    setUserMeta({ commit }) {
      const config = {
        headers: {
          "Cache-Control": "no-cache",
        },
      };
      const gribbUuid = localStorage.getItem("gribb-uuid");
      const jwt = localStorage.getItem("gribb-jwt");
      axios.defaults.headers.common.Authorization = jwt;
      axios.defaults.headers.common.jwt_aud = gribbUuid;
      axios
        .get("/users/me", config)
        .then((response) => {
          const user = response.data.data.attributes;
          commit("SET_USER_DATA", user);
          return user;
        })
        .catch((err) => {
          commit("CLEAR_USER_DATA");
          return err;
        });
    },
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      const gribbUuid = localStorage.getItem("gribb-uuid");
      state.user = userData;
      localStorage.setItem("user", JSON.stringify(userData));
      axios.defaults.headers.common.Authorization = userData.authorization;
      axios.defaults.headers.common.jwt_aud = gribbUuid;
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem("user");
      localStorage.removeItem("gribb-uuid");
      localStorage.removeItem("gribb-jwt");
      location.reload();
    },
    setAuthUser(state, user) {
      return (state.user = user);
    },
    setCurrentAnimalType(state, animalType) {
      return (state.user.currentAnimalType = animalType);
    },
    setAuthState(state, authState) {
      return (state.isAuthResolved = authState);
    },
  },
};
