<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 class="page-title">Nieuw contact</h1>
        </div>
      </div>
      <hr class="my-4" />
      <div class="col-12">
        <b-form @submit.prevent="handleSave">
          <!-- COMPANY NAME * -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Bedrijf naam (verplicht)">
              <b-form-input v-model="company_name" required class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- CONTACT TYPE * -->
          <div class="row mb-4">
            <b-form-group class="font-m-500 inputWrapper" label="Soort contact">
              <b-form-select
                v-model="contact_type"
                class="option input"
                :options="contact_type_options"
              ></b-form-select
            ></b-form-group>
          </div>

          <!-- KVK -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="KvK nummer">
              <b-form-input v-model="chamber_of_commerce" type="number" class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- UBN -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="UBN nummer">
              <b-form-input v-model="ubn" type="number" class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- Address 1  -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Adres 1">
              <b-form-input v-model="address1" class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- Address 2  -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Adres 2">
              <b-form-input v-model="address2" class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- ZIP -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Postcode">
              <b-form-input v-model="zipcode" class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- CITY -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Stad">
              <b-form-input v-model="city" class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- COUNTRY -->
          <div class="row mb-4">
            <b-form-group class="font-m-500 inputWrapper" label="Land">
              <country-select
                v-model="country"
                class="option input custom-select"
                :country="country"
                top-country="NL"
              />
            </b-form-group>
          </div>

          <!-- EMAIL -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Email">
              <b-form-input v-model="email" type="email" class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- PHONE -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Telefoon nummer">
              <b-form-input v-model="phone" type="number" class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- DEFAULT LICENSE PLATE -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Standaard kentekenplaat">
              <b-form-input v-model="default_license_plate" class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- SUBMIT -->
          <div class="row mb-4">
            <b-button type="submit" variant="success" :disabled="isDisabled" class="px-4 save"
              >Opslaan</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import vueCountryRegionSelect from "vue-country-region-select";
import { axiosInstance } from "@/services/axios";

Vue.use(vueCountryRegionSelect);

export default {
  name: "ContactAdd",
  data() {
    return {
      company_name: "",
      contact_type_options: [
        { text: "Dierenarts", value: "dierenarts" },
        { text: "Melker", value: "milker" },
        { text: "Anders", value: "other" },
      ],
      contact_type: "",
      ubn: "",
      address1: "",
      address2: "",
      zipcode: "",
      city: "",
      country: "",
      email: "",
      phone: "",
      chamber_of_commerce: "",
      default_license_plate: "",
    };
  },
  computed: {
    isDisabled() {
      return this.company_name === "";
    },
  },
  methods: {
    handleSave() {
      const contact = {
        company_name: this.company_name,
        contact_type: this.contact_type,
        ubn: this.ubn,
        address1: this.address1,
        address2: this.address2,
        zipcode: this.zipcode,
        city: this.city,
        country: this.country,
        email: this.email,
        phone: this.phone,
        chamber_of_commerce: this.chamber_of_commerce,
        default_license_plate: this.default_license_plate,
      };
      axiosInstance.post("/contacts", contact).then(() => {
        this.$router.push("/settings/contacts");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  color: #0e0649;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: url("../assets/images/icons/filter.svg") !important;
  padding: 5px;
}

.inputWrapper {
  position: relative;
  .input + .icon {
    position: absolute;
    font-size: 0.875rem;
    right: 1rem;
    top: 8px;
    bottom: 0;
    height: 100%;
  }
}

.input {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  font-size: 0.875rem;
  margin-right: 8px;
  margin-bottom: 8px;
  max-height: 43px;
  width: 348px;
  background-color: white;
}

.daysInput {
  width: 224px;
}
.save {
  width: 224px;
  border-radius: 21.5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
