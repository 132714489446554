<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <b-overlay :variant="'dark'" :show="showConfirm" no-wrap @shown="onShown">
        <template #overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="bg-light text-center p-3 rounded"
          >
            <p>
              <strong id="form-confirm-label">Verwijderen?</strong>
            </p>
            <div class="d-flex">
              <b-button variant="outline-danger" class="mr-3" @click="onCancel">Annuleren</b-button>
              <b-button variant="outline-success" @click="onDeleteList">OK</b-button>
            </div>
          </div>
        </template>
      </b-overlay>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">
              <quick-edit
                v-model="list.name"
                :classes="vueQuickEditClasses"
                button-cancel-text="Annuleren"
                @input="onSaveListName"
              ></quick-edit>
            </h1>
          </div>
        </div>
        <div class="row">
          <ListFilter
            v-for="filter in filters"
            :key="filter.id"
            :filter="filter"
            @delete-list-filter="onDeleteListFilter"
          />
          <div class="col-4">
            <AddFilter
              :filter-templates="filterTemplates"
              :list-id="list.id"
              @save-list-filter="onSaveListFilter"
            />
          </div>
        </div>
        <div class="row my-3">
          <div class="col-8">
            <label v-if="animals">{{ animals.length }} Dieren gevonden</label>
            <!-- <a v-b-modal="'my-modal'">Lijst opslaan</a> -->
            <!-- <router-link :to="{ path: '/passport/' }">Lijst opslaan</router-link> -->
          </div>
          <div class="col-4 text-right">
            <span class="delete-link" @click="onDelete">Verwijder lijst</span>
          </div>
        </div>
        <div v-if="animals">
          <div class="row mt-3">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="animals"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
              >
                <template #life_number="{ item: animal }">
                  <router-link
                    :to="{
                      path: '/passport/' + animal.id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ animal.attributes.animal_numbers.lifeNumber }}</span>
                    <span>{{ animal.attributes.animal_numbers.workNumber }}</span>
                  </router-link>
                </template>
                <template #empty>
                  <template v-if="filters.length === 0"> Gebruik minimaal 1 filter </template>
                  <template v-else> Geen resultaten </template>
                </template>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import QuickEdit from "vue-quick-edit";
import pageLoader from "@/mixins/pageLoader";
import AddFilter from "@/components/Lists/AddFilter.vue";
import ListFilter from "@/components/Lists/ListFilter.vue";
import sort from "@/mixins/sort";
import { createQuery } from "@/helpers";

export default {
  name: "Lists",
  components: {
    QuickEdit,
    AddFilter,
    ListFilter,
  },
  mixins: [pageLoader, sort],
  data() {
    return {
      listId: "",
      list: [],
      filters: [],
      filterTemplates: [],
      animals: [],
      showConfirm: false,
      error: "",
      query: "",
      vueQuickEditClasses: {
        wrapper: "form-group form-inline mr-3",
        input: "form-control input-sm mr-3",
        buttons: "btn-group btn-group-sm",
        buttonOk: "btn btn-primary",
        buttonCancel: "btn btn-secondary",
      },
      columns: {
        life_number: "(Werk)nummer",
        "attributes.work_number": "Werknummer",
        "attributes.human_animal_type": "Soort",
        "attributes.date_of_birth": "Geboren",
        "attributes.human_gender": "Geslacht",
        "attributes.group_name": "Groep",
        "attributes.offspring.length": "Nakomelingen",
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.listId = this.$route.params.id;
      this.loadFilterTemplates();
    }
    this.fetchList();
    this.$on("sorted", this.fetchList);
  },
  methods: {
    fetchList() {
      const key = this.sort?.key.substring(this.sort.key.lastIndexOf(".") + 1);
      this.$http.secured
        .get(
          `/lists/${this.listId}?${createQuery(
            undefined,
            this.sort && { key, order: this.sort.order },
          )}`,
        )
        .then((response) => {
          this.pageLoader_resolveData();
          this.list = response.data.data.attributes;
          this.filters = response.data.data.attributes.filters.data;

          const newAnimals = response.data.data.attributes?.animals?.data.map((animal) => {
            return {
              ...animal,
              attributes: {
                ...animal.attributes,
                animal_numbers: {
                  lifeNumber: animal.attributes.life_number.substring(
                    0,
                    animal.attributes.life_number.length - 5,
                  ),
                  workNumber: animal.attributes.life_number.substring(
                    animal.attributes.life_number.length - 5,
                  ),
                },
              },
            };
          });

          this.animals = newAnimals;
        });
    },
    onSaveListName() {
      this.$http.secured
        .put(`/lists/${this.listId}`, {
          list: {
            name: this.list.name,
          },
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    onSaveListFilter(filter) {
      this.pageLoader_loadingData();
      this.$http.secured
        .put(`/lists/${this.listId}`, {
          list: {
            filters_attributes: [
              {
                filter_type: filter.filterType,
                values: filter.values,
              },
            ],
          },
        })
        .then((response) => {
          this.pageLoader_resolveData();
          this.filters = response.data.data.attributes.filters.data;
          this.animals = response.data.data.attributes?.animals?.data || [];
          this.loadFilterTemplates();
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    onDeleteListFilter(filterId) {
      this.pageLoader_loadingData();
      this.animals = [];
      this.$http.secured
        .put(`/lists/${this.listId}`, {
          list: {
            id: this.listId,
            filters_attributes: [
              {
                id: filterId,
                _destroy: "1",
              },
            ],
          },
        })
        .then((response) => {
          this.pageLoader_resolveData();
          this.filters = response.data.data.attributes.filters.data;
          this.animals = response.data.data.attributes?.animals?.data || [];
          this.loadFilterTemplates();
        });
    },
    onDeleteList() {
      this.$http.secured.delete(`/lists/${this.listId}`).then(() => {
        this.$router.push("/lists/all");
      });
    },
    loadFilterTemplates() {
      this.$http.secured.get(`lists/${this.listId}/filter_templates`).then((response) => {
        this.filterTemplates = response.data.data;
      });
    },
    onShown() {
      // Focus the dialog prompt
      // this.$refs.dialog.focus();
    },
    onCancel() {
      this.showConfirm = false;
    },
    onDelete() {
      this.showConfirm = true;
    },
    keyExtractor(animal) {
      return animal.id;
    },
  },
};
</script>
<style></style>
