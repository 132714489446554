<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Kenmerken</h1>
          </div>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <div>
            <export-options
              :period="period"
              :url="'default_lists/characteristics/characteristics'"
            ></export-options>
          </div>
        </div>
        <div v-if="characteristicGroups">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="characteristicGroups"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
              >
                <template #name="{ item: characteristicGroup }">
                  <span class="text-link" @click="handleCharacteristicClick(characteristicGroup)">{{
                    characteristicGroup.name
                  }}</span>
                </template>
                <template #empty>Geen kenmerken</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import ExportOptions from "@/components/Lists/ExportOptions";

export default {
  name: "Birth",
  components: {
    ExportOptions,
  },
  mixins: [pageLoader, defaultListPage("characteristics/characteristics")],
  data() {
    return {
      columns: {
        name: "Kenmerk",
        human_animal_type: "Diersoort",
        animal_count: "Aantal dieren",
      },
    };
  },
  computed: {
    ...mapState({
      characteristicGroups: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    ...mapActions("default_lists", ["setItem"]),
    handleCharacteristicClick(characteristic) {
      this.setItem(characteristic);
      this.$router.push(`characteristics/${characteristic.id}`);
    },
    keyExtractor(characteristicGroup) {
      return characteristicGroup.id;
    },
  },
};
</script>
<style></style>
