<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Groepen</h1>
            <base-input
              v-model="query"
              type="text"
              class="w-25 mb-0"
              placeholder="Zoek een groep"
            ></base-input>
          </div>
        </div>
        <hr class="my-4" />
        <div v-if="filteredGroups">
          <div class="row">
            <div class="col-12">
              <Table :columns="columns" :data="filteredGroups" :key-extractor="keyExtractor">
                <template #name="{ item: group }">
                  <router-link :to="{ path: '/groups/' + group.attributes.id }">
                    {{ group.attributes.name }}
                  </router-link>
                </template>
                <template #empty>
                  <template v-if="groups.length === 0"> Er zijn nog geen groepen gemaakt </template>
                  <template v-else> Geen groepen gevonden </template>
                </template>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "Groups",
  mixins: [pageLoader],
  data() {
    return {
      groups: [],
      error: "",
      query: "",
      columns: {
        name: "Naam",
        "attributes.number_of_animals": "Aantal dieren",
        "attributes.created_at": "Aangemaakt",
      },
    };
  },
  computed: {
    filteredGroups() {
      return this.groups.filter((group) => {
        return group.attributes.name?.toLowerCase()?.match(this.query);
      });
    },
  },
  created() {
    this.$http.secured
      .get("/groups")
      .then((response) => {
        this.pageLoader_resolveData();
        this.groups = response.data.data;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    keyExtractor(group) {
      return group.attributes.id;
    },
  },
};
</script>
<style></style>
