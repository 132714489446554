import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 110000,
  headers: {
    "Content-Type": "application/json",
  },
});
const plainAxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("gribb-jwt") || "";
    const gribbUuid = localStorage.getItem("gribb-uuid") || "";

    if (token) {
      config.headers.Authorization = `${token}`;
    }
    if (gribbUuid) {
      config.headers.jwt_aud = `${gribbUuid}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  },
);

export { axiosInstance, plainAxiosInstance };
