<template>
  <div class="border-box col-3 m-3 core-stat">
    <h3 class="value" :title="value">{{ value || "-" }}</h3>
    <span class="text-muted-light category">{{ category }}</span>
  </div>
</template>
<script>
export default {
  name: "CoreStatsCard",
  props: {
    category: {
      type: String,
      description: "The category for which the value belongs",
      default: undefined,
    },
    value: {
      type: [String, Number],
      description: "The actual value which will be shown",
      default: undefined,
    },
  },
};
</script>
<style>
.core-stat > .value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.core-stat > .category {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
