<template>
  <div class="overlayBackground">
    <div class="animalModal">
      <div class="modalHeader">
        <span class="modalTitle">{{ selectedOptionTypeLabel }}</span>
        <span class="modalSubtitle">{{ selectedAnimals.length }} dier(en) geselecteerd</span>
      </div>
      <div v-if="selectedOption === 'kenmerken'" class="modalBody">
        <CharacteristicsModal
          :selected-add-option="selectedAddOption"
          :selected-edit-option="selectedEditOption"
          :selected-remove-option="selectedRemoveOption"
          :selected-option-type="selectedOptionType"
          :selected-animals-characteristics="selectedAnimalsDetailed.characteristics"
          @passData="getDataFromChild"
        />
      </div>
      <div v-if="selectedOption === 'gewichten'" class="modalBody">
        <WeighingsModal
          :selected-add-option="selectedAddOption"
          :selected-edit-option="selectedEditOption"
          :selected-remove-option="selectedRemoveOption"
          :selected-animals-weighings="selectedAnimalsDetailed.weighings"
          :selected-option-type="selectedOptionType"
          @passData="getDataFromChild"
        />
      </div>
      <div v-if="selectedOption === 'medicijnen'" class="modalBody">
        <DosesModal
          :selected-add-option="selectedAddOption"
          :selected-edit-option="selectedEditOption"
          :selected-remove-option="selectedRemoveOption"
          :selected-animals-doses="selectedAnimalsDetailed.doses"
          :selected-option-type="selectedOptionType"
          @passData="getDataFromChild"
        />
      </div>
      <div class="modalButtons">
        <b-button variant="success" class="rounded-pill success" @click="handleSave"
          >Opslaan</b-button
        >
        <button class="btn cancel" @click="handleModalCancel">Annuleren</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import CharacteristicsModal from "@/components/animalModals/CharacteristicsModal.vue";
import WeighingsModal from "@/components/animalModals/WeighingsModal.vue";
import DosesModal from "@/components/animalModals/DosesModal.vue";

export default {
  name: "Modal",
  components: {
    CharacteristicsModal,
    WeighingsModal,
    DosesModal,
  },
  props: {
    selectedAddOption: {
      type: String,
      default: "",
    },
    selectedEditOption: {
      type: String,
      default: "",
    },
    selectedRemoveOption: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: false,
    },
    selectedAnimals: {
      type: Array,
      default: () => [],
    },
    selectedAnimalsDetailed: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    selectedOption() {
      if (
        this.selectedAddOption === "kenmerken" ||
        this.selectedEditOption === "kenmerken" ||
        this.selectedRemoveOption === "kenmerken"
      ) {
        return "kenmerken";
      }
      if (
        this.selectedAddOption === "gewichten" ||
        this.selectedEditOption === "gewichten" ||
        this.selectedRemoveOption === "gewichten"
      ) {
        return "gewichten";
      }

      return "medicijnen";
    },
    selectedOptionType() {
      if (this.selectedAddOption) {
        return "add";
      }
      if (this.selectedEditOption) {
        return "edit";
      }
      return "remove";
    },
    selectedOptionTypeLabel() {
      if (this.selectedOptionType === "add") {
        return "Toevoegen";
      }
      if (this.selectedOptionType === "edit") {
        return "Wijzigen";
      }
      return "Verwijderen";
    },
  },

  methods: {
    ...mapActions("animals", [
      "bulkAddAnimalsCharacteristics",
      "bulkDeleteAnimalsCharacteristics",
      "bulkAddAnimalsWeighings",
      "bulkUpdateAnimalsWeighings",
      "bulkDeleteAnimalsWeighings",
      "bulkAddAnimalsDoses",
      "bulkUpdateAnimalsDoses",
      "bulkDeleteAnimalsDoses",
    ]),
    handleModalCancel() {
      this.$emit("cancel");
    },
    async handleSave() {
      switch (this.selectedOption) {
        case "kenmerken": {
          switch (this.selectedOptionType) {
            case "add": {
              await this.bulkAddAnimalsCharacteristics({
                animalIds: this.selectedAnimals,
                characteristics: this.data.map((raw) => raw.name),
              });
              break;
            }
            default: {
              const newCharacteristics = this.data.map(({ characteristic }) => characteristic);
              await this.bulkDeleteAnimalsCharacteristics({
                animalIds: this.selectedAnimals,
                characteristics: this.selectedAnimalsDetailed.characteristics.filter(
                  (characteristic) => !newCharacteristics.includes(characteristic),
                ),
              });
              break;
            }
          }
          break;
        }
        case "gewichten": {
          switch (this.selectedOptionType) {
            case "add": {
              await this.bulkAddAnimalsWeighings(
                this.data.map(({ weight, created_at }) => ({
                  animalIds: this.selectedAnimals,
                  weight: parseInt(weight),
                  weightDate: created_at,
                })),
              );
              break;
            }
            case "edit": {
              const old = this.selectedAnimalsDetailed.weighings.filter((weighing) =>
                this.data.some(
                  (w) =>
                    w.id === weighing.id &&
                    (w.weight !== weighing.weight || w.created_at !== weighing.created_at),
                ),
              );

              const newer = this.data
                .map((w) => ({
                  ...w,
                  old: old.find((weighing) => w.id === weighing.id),
                }))
                .filter((w) => w.old);

              const changedWeighings = this.selectedAnimalsDetailed.items
                .map((animal) =>
                  animal.weighings
                    .map((weighing) => ({
                      ...weighing,
                      commonId: old.find(
                        (w) => weighing.weight === w.weight && weighing.created_at === w.created_at,
                      )?.id,
                    }))
                    .filter((w) => w.commonId),
                )
                .reduce((acc, v) => [...acc, ...v], []);

              await this.bulkUpdateAnimalsWeighings(
                newer.reduce(
                  (acc, value) => [
                    ...acc,
                    {
                      weight: value.weight,
                      weightDate: value.created_at,
                      weighingIds: changedWeighings
                        .filter((w) => w.commonId === value.id)
                        .map((w) => w.id),
                    },
                  ],
                  [],
                ),
              );
              break;
            }
            default: {
              const removedCommonWeighings = this.selectedAnimalsDetailed.weighings.filter(
                (weighing) => !this.data.some((w) => w.id === weighing.id),
              );

              const removedWeighings = this.selectedAnimalsDetailed.items
                .map((animal) =>
                  animal.weighings
                    .map((weighing) =>
                      removedCommonWeighings.find(
                        (w) => weighing.weight === w.weight && weighing.created_at === w.created_at,
                      )?.id
                        ? weighing.id
                        : null,
                    )
                    .filter(Boolean),
                )
                .reduce((acc, v) => [...acc, ...v], []);

              await this.bulkDeleteAnimalsWeighings(removedWeighings);
              break;
            }
          }
          break;
        }
        default: {
          switch (this.selectedOptionType) {
            case "add": {
              await this.bulkAddAnimalsDoses(
                this.data.map(({ unit, amount, date, medicineId }) => ({
                  animalIds: this.selectedAnimals,
                  unit,
                  amount: parseInt(amount),
                  doseDate: date,
                  medicineId,
                })),
              );
              break;
            }
            case "edit": {
              const old = this.selectedAnimalsDetailed.doses.filter((dose) =>
                this.data.some(
                  (d) =>
                    d.doseId === dose.id &&
                    (d.amount !== dose.attributes.amount ||
                      d.date !== moment(dose.attributes.dose_date).format("DD-MM-YYYY") ||
                      d.medicineId !== dose.relationships.medicine.data.id ||
                      d.medicineName !== dose.attributes.medicine_name ||
                      d.unit !== dose.attributes.unit),
                ),
              );

              const newer = this.data
                .map((d) => ({
                  ...d,
                  old: old.find((dose) => d.doseId === dose.id),
                }))
                .filter((d) => d.old);

              const changedDoses = this.selectedAnimalsDetailed.items
                .map((animal) =>
                  animal.doses
                    .map((dose) => ({
                      ...dose,
                      commonId: old.find(
                        (d) =>
                          dose.attributes.amount === d.attributes.amount &&
                          dose.attributes.date === d.attributes.date &&
                          dose.relationships.medicine.data.id ===
                            d.relationships.medicine.data.id &&
                          dose.attributes.medicine_name === d.attributes.medicine_name &&
                          dose.attributes.unit === d.attributes.unit,
                      )?.id,
                    }))
                    .filter((d) => d.commonId),
                )
                .reduce((acc, v) => [...acc, ...v], []);

              await this.bulkUpdateAnimalsDoses(
                newer.reduce(
                  (acc, value) => [
                    ...acc,
                    {
                      unit: value.unit,
                      amount: value.amount,
                      doseDate: value.date,
                      medicineId: value.medicineId,
                      medicineName: value.medicineName,
                      doseIds: changedDoses
                        .filter((d) => d.commonId === value.doseId)
                        .map((d) => d.id),
                    },
                  ],
                  [],
                ),
              );
              break;
            }
            default: {
              const removedCommonDoses = this.selectedAnimalsDetailed.doses.filter(
                (dose) => !this.data.some((d) => d.doseId === dose.id),
              );

              const removedDoses = this.selectedAnimalsDetailed.items
                .map((animal) =>
                  animal.doses
                    .map((dose) =>
                      removedCommonDoses.find(
                        (d) =>
                          dose.attributes.amount === d.attributes.amount &&
                          dose.attributes.date === d.attributes.date &&
                          dose.relationships.medicine.data.id ===
                            d.relationships.medicine.data.id &&
                          dose.attributes.medicine_name === d.attributes.medicine_name &&
                          dose.attributes.unit === d.attributes.unit,
                      )?.id
                        ? dose.id
                        : null,
                    )
                    .filter(Boolean),
                )
                .reduce((acc, v) => [...acc, ...v], []);

              await this.bulkDeleteAnimalsDoses(removedDoses);
              break;
            }
          }
          break;
        }
      }
      this.$emit("cancel");
    },
    getDataFromChild(data) {
      this.data = data;
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/bootstrap/custom-bootstrap.scss";
.overlayBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.animalModal {
  width: fit-content;
  padding: 32px;
  background: #f0f0f0;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  height: fit-content;

  .modalHeader {
    display: flex;
    flex-direction: column;

    .modalTitle {
      margin-bottom: 8px;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .modalSubtitle {
      font-size: 0.875rem;
    }
  }
  .modalBody {
    margin-top: 24px;

    .addInputs {
      position: relative;
      display: flex;
    }
    .input {
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      padding: 14px 16px;
      font-size: 0.875rem;
      margin-right: 8px;
      margin-bottom: 8px;
      max-height: 43px;
      min-width: 224px;
    }

    .inputWrapper {
      position: relative;
      width: fit-content;
      min-width: 224px;
      margin-right: 8px;
      margin-bottom: 8px;
      font-size: 0.875rem;

      .input {
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        padding: 14px 16px;

        max-height: 43px;
        min-width: 224px;
      }
      .disabledInput {
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        padding: 14px 16px;

        max-height: 43px;
        min-width: 224px;
      }
      .input + .icon {
        position: absolute;
        right: 11px;
        top: 0;
        bottom: 0;
        height: fit-content;
        color: $grayDark;
        padding: 14px 16px;
      }
      .disabledInput + .icon {
        position: absolute;
        right: 11px;
        top: 0;
        bottom: 0;
        height: fit-content;
        color: $grayDark;
        padding: 14px 16px;
      }
    }

    .itemContainer {
      position: relative;
      display: flex;
      .disabledInput {
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        padding: 14px 16px;
        font-size: 0.875rem;
        margin-right: 8px;
        margin-bottom: 8px;
        max-height: 43px;
        background-color: #f0f0f0;
        min-width: 224px;
      }
      .remove {
        position: relative;
        right: 0;
        top: 0;
        bottom: 0;
        height: fit-content;
        padding: 14px 16px;
        .removeIcon {
          color: $grayDark;
        }
      }
      .promptRemoveContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 8px;
        padding: 14px 16px;
        font-size: 0.875rem;
        z-index: 5;
        background: $gray;
        border: 1px solid #d8d8d8;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cancel {
          color: $grayDark;
        }

        .proceed {
          border-radius: 8px;
        }
      }
    }
    .addInputButton {
      position: relative;
      border: 1px dashed #949494;
      border-radius: 8px;
      padding: 14px 16px;
      font-size: 0.875rem;
      margin-right: 8px;
      margin-bottom: 8px;
      max-height: 43px;
      min-width: 224px;
      color: #949494;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .modalButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .success {
      width: 224px;
      height: 43px;
    }

    .cancel {
      color: $success;
      font-weight: 500;
      font-size: 0.875rem;
      cursor: pointer;
    }
  }
  .button {
    width: fit-content;
    height: 43px;
  }
}
</style>
