import Vue from "vue";

import { axiosInstance } from "@/services/axios";

import { applyFilters } from "@/helpers";

export default {
  namespaced: true,

  state: {
    items: [],
    repairableItems: [],
    retractableItems: [],
    item: {},
    pagination: {
      count: 0,
      pageCount: 0,
      pageSize: 3,
      page: 1,
    },
  },
  actions: {
    fetchRetractableReports({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setRetractableItems", []);
      }
      const url = applyFilters("/messages/retractable", options.filter);

      return axiosInstance.get(url).then((res) => {
        const messages = res.data;

        commit("setRetractableItems", messages);
        return state.retractableItems;
      });
    },
    fetchRepairableReports({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setRepairableItems", []);
      }

      const url = applyFilters("/messages/repairable", options.filter);

      return axiosInstance.get(url).then((res) => {
        const messages = res.data;
        commit("addRepairableItems", messages);
        return state.repairableItems;
      });
    },
    fetchAnimalById({ state, commit }, animalId) {
      commit("setItem", { resource: "messages", item: {} }, { root: true });
      return axiosInstance.get(`/messages/${animalId}/weighings`).then((res) => {
        const animal = res.data;
        commit("setItem", { resource: "messages", item: animal });
        return state.item;
      });
    },
    initializePagesFromQuery({ commit }, { pageSize, pageNum }) {
      commit("setPage", pageNum);
      commit("setPageSize", pageSize);
    },
  },
  mutations: {
    setRetractableItems(state, items) {
      Vue.set(state, "retractableItems", items);
    },
    setRepairableItems(state, items) {
      Vue.set(state, "repairableItems", items);
    },
    addRepairableItems(state, items) {
      const newItems = { ...state.repairableItems, ...items };
      Vue.set(state, "repairableItems", newItems);
    },
    setPagination(state, { count, pageCount }) {
      Vue.set(state.pagination, "count", count);
      Vue.set(state.pagination, "pageCount", pageCount);
    },
    setPage(state, page) {
      Vue.set(state.pagination, "page", parseInt(page));
    },
    setPageSize(state, pageSize) {
      Vue.set(state.pagination, "pageSize", pageSize);
    },
  },
};
