var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-wrapper",on:{"scroll":_vm.handleScroll}},[_c('table',{class:{
        table: true,
        'first-column-titles': _vm.firstColumnTitles,
      }},[_c('thead',[_c('tr',[_vm._t("checkAll"),_vm._l((_vm.columns),function(value,key){return _c('th',{key:key,ref:("column-" + key),refInFor:true,staticClass:"column",class:{ scrolled: _vm.scrolled }},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(typeof value === "string" ? value : value.title)+" "),(_vm.sortable || _vm.getSortType(value))?_c('span',{staticClass:"sort",class:_vm.getSortClass(key),on:{"click":function($event){return _vm.onSortClick(key)}}}):_vm._e(),(_vm.getFilterType(value))?_c('span',{staticClass:"filter-filled",class:_vm.getFilterClass(key),on:{"click":function($event){return _vm.onFilterClick(key, value)}}}):_vm._e(),(_vm.getFilterType(value) === 'number')?_c('portal',{attrs:{"to":"overlay"}},[_c('number-filter-dropdown',{style:({ left: _vm.getColumnOffset(key) }),attrs:{"dropdown-class":"filter-dropdown","open":_vm.activeFilterKey === key},on:{"change":_vm.applyActiveFilter,"cancel":_vm.resetActiveFilter}})],1):_vm._e(),(_vm.getFilterType(value) === 'enum')?_c('portal',{attrs:{"to":"overlay"}},[(_vm.getFilterType(value) === 'enum')?_c('multi-select-dropdown',{style:({ left: _vm.getColumnOffset(key) }),attrs:{"dropdown-class":"filter-dropdown","open":_vm.activeFilterKey === key,"items":Object.keys(value.filter.values).map(function (key) { return ({
                      value: key,
                      label: value.filter.values[key],
                    }); }),"initial-checked":Object.keys(value.filter.values)},on:{"change":_vm.applyActiveFilter,"cancel":_vm.resetActiveFilter}}):_vm._e()],1):_vm._e(),(
                  _vm.getFilterType(value) === 'async' || _vm.getFilterType(value) === 'characteristics'
                )?_c('portal',{attrs:{"to":"overlay"}},[(
                    _vm.getFilterType(value) === 'async' || _vm.getFilterType(value) === 'characteristics'
                  )?_c('async-multi-select-dropdown',{style:({ left: _vm.getColumnOffset(key) }),attrs:{"dropdown-class":"filter-dropdown","open":_vm.activeFilterKey === key,"async-items":value.filter.values},on:{"change":_vm.applyActiveFilter,"cancel":_vm.resetActiveFilter}}):_vm._e()],1):_vm._e(),(_vm.getFilterType(value) === 'date')?_c('portal',{attrs:{"to":"overlay"}},[(_vm.getFilterType(value) === 'date')?_c('period-filter-modal',{staticClass:"filter-dropdown",style:({ left: '50%', transform: 'translateX(-50%)' }),attrs:{"default-view-type":"days","open":_vm.activeFilterKey === key,"period":_vm.periods[key],"filter":true},on:{"change":_vm.onPeriodChange,"cancel":_vm.resetActiveFilter}}):_vm._e()],1):_vm._e()],1)])})],2)]),_c('tbody',[_vm._l((_vm.data),function(item){return _c('tr',{key:_vm.keyExtractor(item)},[_vm._t("checkItem",null,{"item":item}),_vm._l((_vm.columns),function(_,key){return _c('td',{key:key,staticClass:"column",class:{ scrolled: _vm.scrolled },style:(_vm.getColumnStyle(key))},[_vm._t(key,function(){return [(!!_vm.getValue(item, key) && typeof _vm.getValue(item, key) === 'object')?_c('div',[(_vm.getValue(item, key).type == 'icon')?_c('div',[_c('font-awesome-icon',{class:_vm.getValue(item, key).class,attrs:{"icon":_vm.getValue(item, key).icon}})],1):_vm._e(),(_vm.getValue(item, key).type == 'header')?_c('div',{staticClass:"table-header"},[_vm._v(" "+_vm._s(_vm.getValue(item, key).value)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.getValue(item, key).value)+" ")])]):_c('div',[_vm._v(" "+_vm._s(_vm.getValue(item, key))+" ")])]},{"item":item})],2)})],2)}),(_vm.loading)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":Object.keys(_vm.columns).length}},[_c('AppSpinner')],1)]):_vm._e(),(!_vm.loading && _vm.data.length == 0)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":Object.keys(_vm.columns).length}},[_vm._t("empty")],2)]):_vm._e()],2)])]),_c('portal-target',{staticClass:"overlay",attrs:{"name":"overlay","multiple":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }