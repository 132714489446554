<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Conditiescore</h1>
          </div>
        </div>
        <b-form-checkbox
          id="included-checkbox"
          v-model="includes"
          name="included-checkbox"
          :value="true"
          :unchecked-value="false"
          class="mt-2 mb-2"
        >
          Inclusief niet aanwezige dieren
        </b-form-checkbox>
        <div class="mt-2 mb-2 d-flex justify-content-between align-items-center">
          <search-input class="mr-auto" column="animal_life_number" @search="onSearch" />
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options :period="period" :url="'conditions'"></export-options>
        </div>
        <div v-if="conditions">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="conditions"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #animal_life_number="{ item }">
                  <router-link
                    :to="{
                      path: '/passport/' + item.animal.id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ item.animal.animal_numbers.lifeNumber }}</span>
                    <span>{{ item.animal.animal_numbers.workNumber }}</span>
                  </router-link>
                </template>
                <template #human_animal_type="{ item }">
                  {{ item.animal.human_animal_type }}
                </template>
                <template #animal_gender="{ item }">
                  {{ item.animal.human_gender }}
                </template>
                <template #empty>Er zijn nog geen resultaten</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import ExportOptions from "@/components/Lists/ExportOptions";
import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
import SearchInput from "@/components/SearchInput";
import genders from "@/enums/genders";
import animalTypes from "@/enums/animalTypes";

export default {
  name: "Birth",
  components: {
    ExportOptions,
    PeriodFilter,
    SearchInput,
  },
  mixins: [pageLoader, defaultListPage("conditions", undefined, true)],
  data() {
    return {
      columns: {
        animal_life_number: "(Werk)nummer",
        human_animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        animal_gender: { title: "Geslacht", filter: { type: "enum", values: genders } },
        score: "Conditiescore",
        measured_at: { title: "Gemeten op", filter: { type: "date" } },
      },
    };
  },
  computed: {
    ...mapState({
      conditions: (state) => {
        return state.default_lists.items.map((item) => {
          const animal = state.default_lists.included.find(
            (includedAnimal) => includedAnimal.id === item.relationships.animal.data.id,
          );

          return {
            score: item.score,
            measured_at: item.measured_at,
            animal: {
              id: animal.id,
              ...animal.attributes,
              animal_numbers: {
                lifeNumber: animal.attributes.life_number.substring(
                  0,
                  animal.attributes.life_number.length - 5,
                ),
                workNumber: animal.attributes.life_number.substring(
                  animal.attributes.life_number.length - 5,
                ),
              },
            },
          };
        });
      },
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(condition) {
      return condition.id;
    },
  },
};
</script>
<style></style>
