<template>
  <div class="text-center">
    <div>
      <img class="mb-3 mt-5" alt="Vue logo" src="/img/gribb-logo-wit-groot.svg" />
      <form class="form-signin" @submit.prevent="updatePassword">
        <div v-if="error" class="text-red">{{ error }}</div>
        <div class="text-red">{{ apiurl }}</div>
        <p class="text-white">
          Reset wachtwoord Donec ullamcorper nulla non metus auctor fringilla. Maecenas faucibus
          mollis interdum.
        </p>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <div class="input-group-text">*</div>
          </div>
          <input
            id="password"
            v-model="password"
            type="password"
            class="form-control"
            placeholder="Wachtwoord"
          />
        </div>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <div class="input-group-text">*</div>
          </div>
          <input
            id="password_confirmation"
            v-model="password_confirmation"
            type="password"
            class="form-control"
            placeholder="Wachtwoord bevestigen"
          />
        </div>
        <button type="submit" class="btn btn-primary mb-5 w-100">Versturen</button>
        <!-- <router-link to="/password" class="mt-5 font-weight-bold text-white">Wachtwoord vergeten?</router-link> -->
        <!-- <router-link to="/signup" class="mt-5 font-weight-bold text-white">Account aanmaken</router-link> -->
      </form>
    </div>
    <content-footer></content-footer>
  </div>
</template>

<script>
import ContentFooter from "@/layout/ContentFooter.vue";
import { boot, shutdown } from "@/services/intercom";

export default {
  name: "Password",
  components: {
    ContentFooter,
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      error: "",
      apiurl: process.env.API_URL,
    };
  },
  beforeMount() {
    boot();
  },
  beforeDestroy() {
    shutdown();
  },
  methods: {
    updatePassword() {
      const resetPasswordToken = this.$route.query.reset_password_token;
      this.$store
        .dispatch("auth/updatePassword", {
          user: {
            reset_password_token: resetPasswordToken,
            password: this.password,
            password_confirmation: this.password_confirmation,
          },
        })
        .then(() => this.$router.push("/sign_in"));
    },
  },
};
</script>
