<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <router-link class="back" :to="{ path: '/settings/medicines' }">
            <div class="icon-chevron-back"></div>
            Alle medicijnen
          </router-link>
          <h1 class="page-title">Medicijn aanpassen</h1>
          <div class="pl-3 pr-3 row d-flex justify-content-between">
            <div class="w-25 mb-0"></div>
            <b-button variant="danger" class="px-4 save" @click="handleDelete"
              >Verwijderen</b-button
            >
          </div>
        </div>
      </div>
      <hr class="my-4" />
      <div class="col-12">
        <b-form @submit.prevent="handleSave">
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Dit medicijn is voor">
              <b-form-radio-group
                v-model="selected"
                required
                :options="[
                  { text: 'Schapen', value: 3 },
                  { text: 'Geiten', value: 4 },
                ]"
                name="radio-inline"
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Medicijn">
              <b-form-input v-model="name" required class="input"></b-form-input
            ></b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500 inputWrapper" label="Toedieningswijze">
              <b-form-select
                v-model="toedieningswijzeOption"
                class="option input"
                :options="toedieningswijzeOptions"
              ></b-form-select
            ></b-form-group>
          </div>
          <div v-if="toedieningswijzeOption === 'else'" class="row mb-4">
            <b-form-group class="font-m-500" label="Anders">
              <b-form-input
                v-model="toedieningswijzeOptionElse"
                placeholder="Anders..."
                class="input"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500 inputWrapper" label="Wachtdagen melk">
              <b-form-input
                v-model="waiting_time_milk"
                type="number"
                placeholder="..."
                class="input daysInput"
              ></b-form-input
              ><span class="icon">dag(en)</span></b-form-group
            >
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500 inputWrapper" label="Wachtdagen vlees"
              ><b-form-input
                v-model="waiting_time_meat"
                placeholder="..."
                type="number"
                class="input daysInput"
              ></b-form-input>
              <span class="icon">dag(en)</span>
            </b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Overige notities"
              ><b-form-input v-model="notes" placeholder="Notities..." class="input"></b-form-input>
            </b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Medicijn opslaan" :state="saveSuccess">
              <template #invalid-feedback
                ><p>Er is iets fout gegaan met het toevoegen van een medicijn.</p></template
              >
              <template #valid-feedback><p>Medicijn opgeslagen</p></template>
              <b-button type="submit" variant="success" class="px-4 save">Wijzigen</b-button>
            </b-form-group>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosInstance } from "@/services/axios";

export default {
  name: "Medicine",
  data() {
    return {
      oldMedicine: {},
      error: "",
      saveSuccess: null,
      selected: "",
      name: "",
      toedieningswijzeOptions: [
        { value: null, text: "Selecteer..." },
        { value: "bek", text: "In de bek" },
        { value: "spier", text: "In de spier" },
        { value: "onderhuids", text: "Injectie onderhuids" },
        { value: "huid-in", text: "In de huid" },
        { value: "huid-op", text: "Op de huid" },
        { value: "else", text: "Anders" },
      ],
      toedieningswijzeOption: "",
      toedieningswijzeOptionElse: "",
      waiting_time_milk: null,
      waiting_time_meat: null,
      notes: "",
    };
  },
  watch: {
    oldMedicine() {
      this.name = this.oldMedicine.name;
      this.waiting_time_milk = this.oldMedicine.waiting_time_milk;
      this.waiting_time_meat = this.oldMedicine.waiting_time_meat;

      this.checkIfAdministationMethodIsNotOther();
      this.checkAnimalType();
    },
  },
  created() {
    this.$http.secured
      .get(`/medicines/${this.$route.params.id}`)
      .then((response) => {
        this.oldMedicine = response.data.data.attributes;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    checkIfAdministationMethodIsNotOther() {
      if (
        this.oldMedicine.description === "bek" ||
        this.oldMedicine.description === "spier" ||
        this.oldMedicine.description === "onderhuids" ||
        this.oldMedicine.description === "huid-in" ||
        this.oldMedicine.description === "huid-op"
      ) {
        this.toedieningswijzeOption = this.oldMedicine.description;
      } else {
        this.toedieningswijzeOption = "else";
        this.toedieningswijzeOptionElse = this.oldMedicine.description;
      }
    },
    checkAnimalType() {
      if (this.oldMedicine.animal_type === "schaap") {
        this.selected = 3;
      } else {
        this.selected = 4;
      }
    },
    handleSave() {
      const medicine = {
        animal_type: this.selected,
        name: this.name,
        description:
          this.toedieningswijzeOption === "else"
            ? this.toedieningswijzeOptionElse
            : this.toedieningswijzeOption,
        waiting_time_milk: parseInt(this.waiting_time_milk),
        waiting_time_meat: parseInt(this.waiting_time_meat),
        notes: this.notes,
      };
      axiosInstance
        .patch(`/medicines/${this.$route.params.id}`, medicine)
        .then(() => {
          this.saveSuccess = true;
          setTimeout(() => {
            this.saveSuccess = null;
            this.$router.push("/settings/medicines");
          }, 2000);
        })

        .catch(() => {
          this.saveSuccess = false;
        });
    },
    handleDelete() {
      axiosInstance
        .delete(`/medicines/${this.$route.params.id}`)
        .then(() => {
          setTimeout(() => {
            this.$router.push("/settings/medicines");
          }, 2000);
        })
        .catch((err) => {
          this.setError(err, "Er is iets fout gegaan met het verwijderen");
        });
    },
  },
};
</script>
<style>
.red {
  color: red !important;
}
</style>
