<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 class="page-title">
            Alle dieren
            <small v-if="animals"> ({{ pagination.totalCount }}) </small>
          </h1>
        </div>
      </div>
      <div v-if="statistics" class="row">
        <core-stat-card
          category="Vrouwelijke dieren"
          :value="
            statistics.number_of_female_animals['3'] + statistics.number_of_female_animals['4']
          "
        />
        <core-stat-card
          category="Mannelijke dieren"
          :value="statistics.number_of_male_animals['3'] + statistics.number_of_male_animals['4']"
        />
        <core-stat-card
          category="Onbekende dieren"
          :value="
            statistics.number_of_genderless_animals['3'] +
            statistics.number_of_genderless_animals['4']
          "
        />
      </div>
      <b-form-checkbox
        id="included-checkbox"
        v-model="includes"
        name="included-checkbox"
        :value="true"
        :unchecked-value="false"
        class="mt-2 mb-2"
      >
        Inclusief niet aanwezige dieren
      </b-form-checkbox>
      <div class="mt-4 mb-2 d-flex align-items-center">
        <search-input class="mr-auto" @search="onSearch" />
        <period-filter
          :period="period"
          :single-date="true"
          default-view-type="days"
          @change="handlePeriodChange"
        ></period-filter>
        <column-filter
          class="mr-4"
          :items="columnFilterItems"
          :initial-checked="initialColumns"
          @change="onColumnFilterChange"
        ></column-filter>
        <export-options
          :period="period"
          :url="'animals/paginated_index'"
          :present_on="period.startDate"
          :columns="Object.keys(columns)"
          :includes="!includes"
        ></export-options>
      </div>
      <div v-if="animals">
        <div class="row">
          <div class="col-12">
            <Table
              :columns="columns"
              :data="animals"
              :loading="!pageLoader_isDataLoaded"
              :key-extractor="keyExtractor"
              :sort="sort"
              @sort="onSort"
              @filterChange="onFilterChange"
            >
              <template #checkAll>
                <td>
                  <b-form-checkbox
                    id="checkbox-all"
                    v-model="allStatus"
                    name="checkbox-all"
                    value="checked"
                    unchecked-value="unchecked"
                  >
                  </b-form-checkbox>
                </td>
              </template>
              <template #checkItem="{ item: animal }">
                <td>
                  <b-form-checkbox v-model="selectedAnimals" :value="`${animal.id}`">
                  </b-form-checkbox>
                </td>
              </template>
              <template #life_number="{ item: animal }">
                <router-link
                  :to="{
                    path: '/passport/' + animal.id,
                  }"
                  class="animal_numbers"
                >
                  <span>{{ animal.animal_numbers.lifeNumber }}</span>
                  <span>{{ animal.animal_numbers.workNumber }}</span>
                </router-link>
              </template>
              <template #gender="{ item: animal }">
                {{ animal.human_gender }}
              </template>
              <template #animal_type="{ item: animal }">
                {{ animal.human_animal_type }}
              </template>
              <template #group_name="{ item: animal }">
                <router-link v-if="animal.group_id" :to="'/groups/' + animal.group_id">
                  {{ animal.group_name }}
                </router-link>
              </template>
              <template #breed="{ item: animal }">
                <div v-if="animal.breed">
                  {{ animal.breed }}
                </div>
                <div
                  v-for="(breed, index) in animal.breed_percentages"
                  v-else
                  :key="index + breed.breed"
                >
                  <p>{{ breed.breed }} - {{ breed.percentage }}%</p>
                </div>
              </template>
              <template #empty>Geen dieren</template>
            </Table>
            <paginate
              v-if="pagination.totalPages > 1"
              v-model="pagination.page"
              :page-count="pagination.totalPages"
              :click-handler="fetchPaginatedAnimals"
              :prev-text="'Vorige'"
              :next-text="'Volgende'"
              :container-class="'pagination justify-content-center pt-3'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'page-item prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'page-item next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
              :first-last-button="false"
            ></paginate>
          </div>
        </div>
      </div>
    </div>
    <EditBar
      :selected-animals-array="selectedAnimals"
      :selected-animals-detailed="selectedAnimalsDetailed"
      @deselect="handleEditBarDeselect"
    />
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment";
import pageLoader from "@/mixins/pageLoader";
import sort from "@/mixins/sort";
import filter from "@/mixins/filter";
import { axiosInstance } from "@/services/axios";
import ExportOptions from "@/components/Lists/ExportOptions.vue";
import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
import ColumnFilter from "@/components/Lists/ColumnFilter.vue";
import CoreStatCard from "@/components/Cards/CoreStatCard.vue";
import EditBar from "@/components/EditBar.vue";
import SearchInput from "@/components/SearchInput.vue";
import genders from "@/enums/genders";
import animalTypes from "@/enums/animalTypes";
// import breeds from "@/enums/breeds"; //TODO: temp
import { createQuery } from "@/helpers";

export default {
  name: "AllAnimals",
  components: {
    ExportOptions,
    CoreStatCard,
    ColumnFilter,
    PeriodFilter,
    EditBar,
    SearchInput,
  },
  mixins: [pageLoader, sort, filter],
  data() {
    const initialColumns = [
      "base",
      "life_number",
      "name",
      "date_of_birth",
      "gender",
      "age_in_year_month",
      "age_in_days",
      "animal_type",
      "breed",
      "days_at_company",
      "group_name",
    ];

    const columnDefinitions = {
      life_number: { title: "(Werk)nummer", sort: true },
      gender: { title: "Geslacht", filter: { type: "enum", values: genders }, sort: true },
      date_of_birth: { title: "Geboren", filter: { type: "date" }, sort: true },
      age_in_year_month: "Leeftijd (jr/mnd)",
      age_in_days: "Leeftijd (dagen)",
      animal_type: {
        title: "Diersoort",
        filter: { type: "enum", values: animalTypes },
        sort: true,
      },
      breed: {
        title: "Ras",
        filter: {
          type: "async",
          values: this.$http.secured.get("/animals/breeds").then((response) => {
            const breeds = [];
            response.data.map((breed) => {
              breeds.push({
                value: breed,
                label: breed,
              });
            });

            return breeds;
          }),
        },
        sort: true,
      },
      days_at_company: "Dagen aanwezig op bedrijf",
      last_pregnancy_date: { title: "Laatste drachtdatum", filter: { type: "date" } },
      last_pregnancy_status: "Laatste drachtstatus",
      last_breeding_program_date: {
        title: "Laatste dekdatum",
        filter: { type: "date" },
      },
      last_dose_medicine_waiting_time_milk: "Einde wachttijd melk",
      last_dose_medicine_waiting_time_meat: "Einde wachttijd vlees",
      last_dose_medicine_name: "Laatste medicijn",
      last_dose_date: "Datum laatste medicijn",
      imported_at: { title: "Aanvoerdatum", filter: { type: "date" }, sort: true },
      origination_ubn: "UBN herkomst",
      exported_at: { title: "Afvoerdatum", filter: { type: "date" }, sort: true },
      destination_ubn: "UBN bestemming",
      date_of_death: { title: "Sterftedatum", filter: { type: "date" }, sort: true },
      reason_of_death: {
        title: "Sterfte oorzaak",
        sort: true,
        filter: {
          type: "async",
          values: this.$http.secured
            .get("/default_lists/deaths/death_reasons_all")
            .then((response) => {
              const death_reasons = [];
              response.data.data.map((deathReason) => {
                death_reasons.push({
                  value: deathReason.attributes.reason_of_death,
                  label: deathReason.attributes.reason_of_death,
                });
              });

              return death_reasons;
            }),
        },
      },
      group_name: {
        title: "Groep",
        sort: true,
        filter: {
          type: "async",
          values: this.$http.secured.get("/groups").then((response) => {
            const groups = [];
            response.data.data.map((group) => {
              groups.push({
                value: group.attributes.name,
                label: group.attributes.name,
              });
            });

            return groups;
          }),
        },
      },
      birth_weight: "Geboortegewicht",
      last_weight: "Laatste gewicht",
      growth_since_birth_weighing: "Groei sinds geboorte (gram / dag)",
      growth_since_last_weighing: "Groei sinds vorige weging (gram / dag)",
    };

    return {
      allStatus: "unchecked",
      selectedAnimals: [],
      selectedAnimalsDetailed: {},

      columnDefinitions,
      visibleColumns: initialColumns,
      initialColumns,
      includes: false,
      statistics: null,
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
    ...mapState({
      animals: (state) => state.animals.items,
      pagination: (state) => state.animals.pagination,
      period: (state) => {
        if (state.animals.period.startDate) return state.animals.period;
        return {
          startDate: moment().startOf("day").toDate(),
          endDate: moment().endOf("day").toDate(),
        };
      },
    }),
    columns() {
      return Object.keys(this.columnDefinitions).reduce((result, columnKey) => {
        if (this.visibleColumns.includes(columnKey)) {
          return { ...result, [columnKey]: this.columnDefinitions[columnKey] };
        }
        return result;
      }, {});
    },
    columnFilterItems() {
      const mapColumn = (column) => ({
        value: column,
        label:
          typeof this.columnDefinitions[column] === "string"
            ? this.columnDefinitions[column]
            : this.columnDefinitions[column].title,
      });
      return [
        {
          value: "base",
          label: "Basis",
          children: [
            "life_number",
            "date_of_birth",
            "gender",
            "age_in_year_month",
            "age_in_days",
            "animal_type",
            "breed",
            "days_at_company",
          ].map(mapColumn),
        },
        {
          value: "pregnanies",
          label: "Drachtstatus",
          children: ["last_pregnancy_date", "last_pregnancy_status"].map(mapColumn),
        },
        {
          value: "breeding_programs",
          label: "Dekgegevens",
          children: [
            "last_breeding_program_date",
            // "last_breeding_program_male_animal",
          ].map(mapColumn),
        },
        // {
        //   value: "flamming",
        //   label: "Aflamming",
        //   children: [
        //     "last_flamming_date",
        //     "last_flamming_number",
        //     "expected_flamming_date",
        //     "days_in_lactation",
        //     "total_days_in_lactation",
        //     "end_of_waiting_time_colostrum"
        //   ].map(mapColumn),
        // },
        {
          value: "treatment",
          label: "Behandeling",
          children: [
            "last_dose_medicine_waiting_time_milk",
            "last_dose_medicine_waiting_time_meat",
            "last_dose_medicine_name",
            "last_dose_date",
            // "last_treatment_location",
            // "last_date_of_treatment_plan",
            // "last_date_of_treament_q_fever"
          ].map(mapColumn),
        },
        {
          value: "import",
          label: "Aanvoer",
          children: [
            "imported_at",
            "origination_ubn",
            // "origination_name"
          ].map(mapColumn),
        },
        {
          value: "export",
          label: "Afvoer",
          children: [
            "exported_at",
            "destination_ubn",
            // "export_weight"
            // "destination_name"
            // "export_reason"
          ].map(mapColumn),
        },
        {
          value: "death",
          label: "Sterfte",
          children: ["date_of_death", "reason_of_death"].map(mapColumn),
        },
        ...["group_name"].map(mapColumn),
        {
          value: "weighings",
          label: "Gewichten",
          children: [
            "birth_weight",
            // "last_weight_date",
            "last_weight",
            "growth_since_birth_weighing",
            "growth_since_last_weighing",
          ].map(mapColumn),
        },
        // TODO: Update fields when API is updated
      ];
    },
  },
  watch: {
    allStatus(value) {
      if (value === "checked") {
        this.selectedAnimals = this.animals.map((animal) => animal.id);
      } else {
        this.selectedAnimals = [];
      }
    },
    animals(value) {
      this.setSelectedAnimalsDetailed(this.selectedAnimals, value);
    },
    selectedAnimals(value) {
      this.setSelectedAnimalsDetailed(value, this.animals);
    },
    includes() {
      this.handleFetchAnimals({ reset: false });
      this.getStatistics();
    },
    period() {
      this.handleFetchAnimals({ reset: false });
      this.getStatistics();
    },
  },
  created() {
    const { page } = this.$route.query;
    this.pageLoader_loadingData();
    if (page) {
      this.initializePagesFromQuery({ page });
    }

    this.getStatistics();

    Promise.all([this.handleFetchAnimals({})])
      .then(() => this.pageLoader_resolveData())
      .catch(() => {
        this.pageLoader_resolveData();
      });

    this.$on("sorted", () => {
      this.getStatistics();
      this.handleFetchAnimals({ reset: false });
    });
    this.$on("filtered", () => {
      this.getStatistics();
      this.handleFetchAnimals({ reset: false });
    });
  },
  methods: {
    ...mapActions("animals", ["fetchAnimals", "initializePagesFromQuery"]),
    ...mapActions("groups", ["fetchGroups"]),
    setSelectedAnimalsDetailed(selected, animals) {
      this.selectedAnimalsDetailed = selected.reduce(
        (result, animalId, index) => {
          const animal = animals.find((a) => a.id === animalId);

          return {
            items: [...result.items, animal],
            characteristics:
              index === 0
                ? animal.characteristics ?? []
                : result.characteristics.filter((characteristic) =>
                    animal.characteristics.includes(characteristic),
                  ),
            weighings:
              index === 0
                ? animal.weighings ?? []
                : result.weighings.filter((weighing) =>
                    animal.weighings.some(
                      (w) => w.created_at === weighing.created_at && w.weight === weighing.weight,
                    ),
                  ),
            doses:
              index === 0
                ? animal.doses ?? []
                : result.doses.filter((dose) =>
                    animal.doses.some(
                      (d) =>
                        d.attributes.amount === dose.attributes.amount &&
                        d.attributes.dose_date === dose.attributes.dose_date &&
                        d.attributes.medicine_name === dose.attributes.medicine_name &&
                        d.attributes.unit === dose.attributes.unit,
                    ),
                  ),
          };
        },
        {
          items: [],
          characteristics: [],
          weighings: [],
          doses: [],
        },
      );
    },
    handleFetchAnimals({ reset }) {
      const filter = {};
      filter.page = this.pagination.page;
      if (this.period.startDate)
        filter.present_on = moment(this.period.startDate).format("DD-MM-YYYY");
      if (this.includes) filter.filter_animals = !this.includes;

      return this.fetchAnimals({
        filter,
        sort: this.sort,
        extraFilters: this.filters,
        reset,
      }).then(() => this.setQueryPaginationParams());
    },
    fetchPaginatedAnimals(page) {
      this.setPage(page);
      this.handleFetchAnimals({ reset: false });
    },
    setPage(page) {
      this.$store.commit("animals/setPage", page);
    },
    setPeriod(period) {
      const { startDate, endDate } = period;
      this.$store.commit("animals/setPeriod", { startDate, endDate });
    },
    setQueryPaginationParams() {
      const { page } = this.pagination;

      // eslint-disable-next-line no-unused-vars
      this.$router.push({ query: { page } }).catch((err) => {});
    },
    getStatistics() {
      const filter = {};
      if (this.period.startDate)
        filter.present_on = moment(this.period.startDate).format("DD-MM-YYYY");
      if (this.includes) filter.filter_animals = !this.includes;

      const url = `/statistics?${createQuery(filter, this.sort, this.filters)}`;

      axiosInstance.get(url).then((res) => {
        this.statistics = res.data;
      });
    },
    handlePeriodChange(period) {
      this.setPage(1);
      this.setPeriod(period);
      this.handleFetchAnimals({ reset: false });
      this.getStatistics();
    },
    onColumnFilterChange(columns) {
      this.visibleColumns = columns;
      this.getStatistics();
    },
    keyExtractor(animal) {
      return animal.id;
    },
    handleEditBarDeselect() {
      this.selectedAnimals = [];
      this.allStatus = "unchecked";
    },
  },
};
</script>
<style>
.worknumbers {
  display: flex;
  justify-content: space-between;
}
</style>
