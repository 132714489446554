<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <router-link class="back" :to="{ path: '/lists/medicines' }">
              <div class="icon-chevron-back"></div>
              Alle medicijnen
            </router-link>
            <h1 class="page-title">Medicijn: {{ $route.params.medicine }}</h1>
          </div>
        </div>
        <b-form-checkbox
          id="included-checkbox"
          v-model="includes"
          name="included-checkbox"
          :value="true"
          :unchecked-value="false"
          class="mt-2 mb-2"
        >
          Inclusief niet aanwezige dieren
        </b-form-checkbox>
        <div class="mt-2 mb-2 d-flex align-items-center">
          <search-input class="mr-auto" column="animal_life_number" @search="onSearch" />
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options :period="period" :url="'default_lists/medicines/doses'"></export-options>
        </div>
        <div v-if="animals">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="filteredAnimals"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #animal_life_number="{ item: animal }">
                  <router-link
                    :to="{
                      path: '/passport/' + animal.animal_id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ animal.animal_numbers.lifeNumber }}</span>
                    <span>{{ animal.animal_work_number }}</span>
                  </router-link>
                </template>
                <template #animal_animal_type="{ item: animal }">
                  {{ animal.animal_human_animal_type }}
                </template>
                <template #empty>Geen dieren gevonden</template>
              </Table>

              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
import ExportOptions from "@/components/Lists/ExportOptions.vue";
import SearchInput from "@/components/SearchInput";
import animalTypes from "@/enums/animalTypes";

export default {
  name: "MedicineDetails",
  components: {
    PeriodFilter,
    ExportOptions,
    SearchInput,
  },
  mixins: [pageLoader, defaultListPage("medicines/doses")],
  data() {
    return {
      columns: {
        animal_life_number: "(Werk)nummer",
        animal_animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        amount: { title: "Hoeveelheid", filter: { type: "number" } },
        dose_date: { title: "Datum", filter: { type: "date" } },
      },
    };
  },
  computed: {
    ...mapState({
      animals: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
    filteredAnimals() {
      return this.animals.filter((item) => item.medicine_name === this.$route.params.medicine);
    },
  },

  methods: {
    keyExtractor(weighing) {
      return weighing.id;
    },
  },
};
</script>
<style></style>
