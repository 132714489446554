<template>
  <dropdown :open="open" :dropdown-class="dropdownClass" @clickOutside="cancel">
    <div class="wrapper d-flex flex-column">
      <b-button-group class="buttons">
        <b-button :variant="lessThan ? 'secondary' : 'outline-secondary'" @click="toggleLessThan">
          &lt;
        </b-button>
        <b-button :variant="equalTo ? 'secondary' : 'outline-secondary'" @click="toggleEqualTo">
          &#61;
        </b-button>
        <b-button
          :variant="greaterThan ? 'secondary' : 'outline-secondary'"
          @click="toggleGreaterThan"
        >
          &gt;
        </b-button>
        <b-button :variant="inBetween ? 'secondary' : 'outline-secondary'" @click="toggleInBetween">
          &rarr; &larr;
        </b-button>
      </b-button-group>
      <b-form-input v-model="value" type="number"></b-form-input>
      <b-form-input v-if="inBetween" v-model="value2" type="number"></b-form-input>
    </div>
    <div class="footer d-flex justify-content-between align-items-center">
      <b-button variant="success" @click="submit">Ok</b-button>
      <b-button variant="link" class="cancel" @click="cancel">Annuleren</b-button>
    </div>
  </dropdown>
</template>
<script>
import Dropdown from "@/components/Dropdowns/Dropdown";

export default {
  components: {
    Dropdown,
  },
  props: {
    open: Boolean,
    dropdownClass: String,
  },
  data() {
    return {
      lessThan: false,
      equalTo: true,
      greaterThan: false,
      inBetween: false,
      value: 0,
      value2: 0,
      lastValue: null,
    };
  },
  watch: {
    open(current, previous) {
      if (!previous && current) {
        this.lastValue = {
          lessThan: this.lessThan,
          equalTo: this.equalTo,
          inBetween: this.inBetween,
          greaterThan: this.greaterThan,
          value: this.value,
          value2: this.value2,
        };
      }
    },
    inBetween() {
      if (this.inBetween) {
        this.lessThan = false;
        this.equalTo = false;
        this.greaterThan = false;
      }
    },
    lessThan() {
      if (this.lessThan) {
        this.inBetween = false;
      }
    },
    greaterThan() {
      if (this.greaterThan) {
        this.inBetween = false;
      }
    },
    equalTo() {
      if (this.equalTo) {
        this.inBetween = false;
      }
    },
  },
  methods: {
    toggleLessThan() {
      this.lessThan = !this.lessThan;
    },
    toggleEqualTo() {
      this.equalTo = !this.equalTo;
    },
    toggleGreaterThan() {
      this.greaterThan = !this.greaterThan;
    },
    toggleInBetween() {
      this.inBetween = !this.inBetween;
    },
    submit() {
      this.$emit("change", {
        lessThan: this.lessThan,
        equalTo: this.equalTo,
        greaterThan: this.greaterThan,
        inBetween: this.inBetween,
        value: this.value,
        value2: this.value2,
      });
    },
    cancel() {
      this.lessThan = this.lastValue.lessThan;
      this.equalTo = this.lastValue.equalTo;
      this.inBetween = this.lastValue.inBetween;
      this.greaterThan = this.lastValue.greaterThan;
      this.value = this.lastValue.value;
      this.value2 = this.lastValue.value2;
      this.$emit("cancel");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/bootstrap/custom-bootstrap.scss";

.wrapper {
  padding: 0.75rem;

  .buttons {
    margin-bottom: 0.75rem;
  }
}

.footer {
  border-top: 1px solid $gray;
  padding: 0.75rem;

  .cancel {
    color: $blueLighter;
    font-weight: 500;
    font-size: 0.875rem;
    cursor: pointer;
  }
}
</style>
