<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">RVO melding details</h1>
          </div>
        </div>
        <div v-if="retractResponse">
          <b-alert :variant="responseClass" show>{{ retractResponse.message }}</b-alert>
        </div>
        <div v-else>
          <div v-if="message">
            <div class="row">
              <div class="col-6">
                <label>relatienummer_houder</label>
                {{ message.relatienummer_houder }}
                <br />
                <label>UBN</label>
                {{ message.meldingeenheid }}
                <br />
              </div>
            </div>
            <div v-if="message.berichttype == 'AFV'">
              <h2>Afvoermelding</h2>
              <div class="row mb-2">
                <div class="col-6">
                  <label>Afvoerdatum</label>
                  {{ message.gebeurtenisdatum }}
                  <br />
                  <label>Bestemming</label>
                  {{ message.meldingeenheid_bestemming }} - {{ message.naam_me_tweede_partij }}
                  <br />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h4>Transport</h4>
                  <div class="row">
                    <div class="col">
                      <label>Transportnummer</label>
                      {{ message.transportnummer }}
                    </div>
                    <div class="col">
                      <label>Relatienummer vervoerder </label>
                      {{ message.trans_relatienummer_vervoerder }}
                    </div>
                    <div class="col">
                      <label>Naam vervoerder</label>
                      {{ message.trans_naam_vervoerder }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label>Kenteken</label>
                      {{ message.trans_kenteken }}
                    </div>
                    <div class="col">
                      <label>Tijdstip vertrek</label>
                      {{ message.trans_tijdstip_vertrek }}
                    </div>
                    <div class="col">
                      <label>Verwachte transportduur</label>
                      {{ message.trans_verwachte_transportduur }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-12">
                <h3>Dier</h3>
                <div class="row">
                  <div class="col">
                    <label>Levensnummer</label>
                    {{ message.dier_levensnummer }}
                  </div>
                  <div class="col">
                    <label>Diersoort</label>
                    {{ message.diersoort == "3" ? "Schaap" : "Geit" }}
                  </div>
                  <div class="col">
                    <label>Geboortedatum</label>
                    {{ message.geboortedatum }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-6">
                <h3>Berichtgegevens</h3>
                <label>Meldingnr</label>
                {{ message.meldingnummer }}
                <br />
                <label>Melddatum</label>
                {{ message.berichtdatum }}
                <br />
                <label>Datum / tijd verwerking</label>
                {{ message.berichttijd }}
                <br />
                <label>Status / toelichting</label>
                {{ message.melding_status_code }}
                <br />
                <label>Communicatiekanaal</label>
                {{ message.communicatiekanaal }}
                <br />
                <div
                  v-if="
                    message.berichttype == 'AAN' ||
                    message.berichttype == 'AFV' ||
                    message.berichttype == 'DOO' ||
                    message.berichttype == 'GER'
                  "
                >
                  <span
                    class="btn btn-primary mt-3"
                    @click="onRetractMessage(message.meldingnummer)"
                    >Intrekken</span
                  >
                </div>
              </div>
              <div class="col-6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "RvoReport",
  mixins: [pageLoader],
  data() {
    return {
      message: {},
      retractResponse: "",
    };
  },
  computed: {
    responseClass() {
      return this.retractResponse.status == "ok" ? "success" : "danger";
    },
  },
  created() {
    this.pageLoader_loadingData();
    this.$http.secured
      .get(`/messages/${this.$route.params.id}`)
      .then((response) => {
        this.message = response.data;

        this.pageLoader_resolveData();
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    onRetractMessage(id) {
      this.$http.secured
        .post(`/messages/${id}/retract`, {})
        .then((response) => {
          this.retractResponse = response.data;
          this.pageLoader_resolveData();
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
  },
};
</script>
<style></style>
