import Vue from "vue";

import { axiosInstance } from "@/services/axios";
import { applyFilters } from "@/helpers";

export default {
  namespaced: true,

  state: {
    items: [],
    item: {},
    pagination: {
      totalCount: 0,
      totalPages: 0,
      pageSize: 25,
      page: 1,
    },
    period: {
      startDate: null,
      endDate: null,
    },
  },
  actions: {
    fetchScanErrors({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setItems", { resource: "scan_errors", items: [] }, { root: true });
      }

      const url = applyFilters("/scan_errors/paginated_index", options.filter);

      return axiosInstance.get(url).then((res) => {
        const scan_errors = res.data.data.map((g) => g.attributes);
        const pagination = res.data.meta;
        commit(
          "setItems",
          {
            resource: "scan_errors",
            items: scan_errors,
          },
          { root: true },
        );

        commit("setPagination", { pagination });
        return state.items;
      });
    },
    initializePagesFromQuery({ commit }, { page, startDate, endDate }) {
      commit("setPage", page);
      commit("setPeriod", { startDate, endDate });
    },
  },
  mutations: {
    setPagination(state, pagination) {
      Vue.set(state.pagination, "totalCount", parseInt(pagination.pagination.total_count));
      Vue.set(state.pagination, "totalPages", parseInt(pagination.pagination.total_pages));
    },
    setPage(state, currentPage) {
      Vue.set(state.pagination, "page", parseInt(currentPage));
    },
    setPageSize(state, pageSize) {
      Vue.set(state.pagination, "pageSize", pageSize);
    },
    setPeriod(state, { startDate, endDate }) {
      Vue.set(state.period, "startDate", startDate);
      Vue.set(state.period, "endDate", endDate);
    },
  },
};
