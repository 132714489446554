<template>
  <div>
    <div v-if="!selectedMessage">
      <Table
        :columns="columns"
        :data="messages.transport"
        :loading="!pageLoader_isDataLoaded"
        :key-extractor="keyExtractor"
      >
        <template #message="{ item: message }">
          {{ message.signalering_code_reden }}
          <b-button :id="`popover-${message.id}`" variant="link" size="sm">
            <font-awesome-icon class="gray-icon" icon="info-square" />
          </b-button>
          <b-popover :target="`popover-${message.id}`" triggers="hover" placement="top">
            <template #title>Volledig bericht</template>
            {{ message }}
          </b-popover>
        </template>
        <template #solve="{ item: message }">
          <b-button variant="success" size="sm" @click="$emit('select-message', message)">
            Oplossen
          </b-button>
        </template>
        <template #empty>Er zijn nog geen berichten</template>
      </Table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "MissingTable",
  mixins: [pageLoader],
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      columns: {
        message: "Bericht",
        gebeurtenisdatum: "Datum",
        meldingeenheid_tweede_partij: "UBN tweede partij",
        aantal_dieren: "Aantal dieren",
        kenteken: "Kenteken",
        solve: "",
      },
      selectedMessage: "",
      selectedDate: Date.now(),
      max: today,
      error: "",
      query: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
    ...mapState({
      messages: (state) => state.messages.missingItems,
    }),
  },
  created() {
    this.pageLoader_loadingData();
    Promise.all([this.fetchMissingMessagesWithAnimals()])
      .then(() => this.pageLoader_resolveData())
      .catch(() => {
        this.pageLoader_resolveData();
      });
  },
  methods: {
    ...mapActions("messages", ["fetchMissingMessagesWithAnimals"]),
    selectMessage(message) {
      message.berichttype;
      this.selectedDate = moment(message.gebeurtenisdatum, "DD-MM-YYYY");
      this.selectedMessage = message;
    },
    keyExtractor(message) {
      return message.meldingnummer;
    },
  },
};
</script>
<style></style>
