<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Melkgift per dier</h1>
          </div>
        </div>
        <div v-if="statistics" class="row">
          <core-stat-card
            category="Gem. melkgift in L"
            :value="statistics.average_milk_yield | roundDecimal"
          />
          <core-stat-card category="Gem. vet in %" :value="statistics.average_fat | roundDecimal" />
          <core-stat-card
            category="Gem. eiwit in %"
            :value="statistics.average_protein | roundDecimal"
          />
          <core-stat-card
            category="Gem. celgetal"
            :value="statistics.average_cells | roundDecimal"
          />
        </div>
        <div class="mt-4 d-flex justify-content-end"></div>
        <b-form-checkbox
          id="included-checkbox"
          v-model="includes"
          name="included-checkbox"
          :value="true"
          :unchecked-value="false"
          class="mt-2 mb-2"
        >
          Inclusief niet aanwezige dieren
        </b-form-checkbox>
        <div class="mt-2 mb-2 d-flex align-items-center">
          <search-input class="mr-auto" column="animal_life_number" @search="onSearch" />
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options :period="period" :url="'milk_yields'"></export-options>
        </div>
        <div v-if="milkyields">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="milkyields"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #life_number="{ item: milkyield }">
                  <router-link
                    :to="{
                      path: '/passport/' + milkyield.animal.id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ milkyield.animal.attributes.animal_numbers.lifeNumber }}</span>
                    <span>{{ milkyield.animal.attributes.animal_numbers.workNumber }}</span>
                  </router-link>
                </template>
                <template #date="{ item: milkyield }">
                  <span>{{ milkyield.attributes.date | formatDate }}</span>
                </template>
                <template #amount="{ item: milkyield }">
                  <span>{{ milkyield.attributes.amount }}</span>
                </template>
                <template #milker_company_name="{ item: milkyield }">
                  <span>{{ milkyield.milker.attributes.company_name }}</span>
                </template>

                <template #fat="{ item: milkyield }">
                  <span>{{ milkyield.attributes.fat }}</span>
                </template>
                <template #npn_cu="{ item: milkyield }">
                  <span>{{ milkyield.attributes.npn_cu }}</span>
                </template>
                <template #protein="{ item: milkyield }">
                  <span>{{ milkyield.attributes.protein }}</span>
                </template>
                <template #lactose="{ item: milkyield }">
                  <span>{{ milkyield.attributes.lactose }}</span>
                </template>
                <template #solids="{ item: milkyield }">
                  <span>{{ milkyield.attributes.solids }}</span>
                </template>
                <template #npn_cu_a2="{ item: milkyield }">
                  <span>{{ milkyield.attributes.npn_cu_a2 }}</span>
                </template>
                <template #cells="{ item: milkyield }">
                  <span>{{ milkyield.attributes.cells }}</span>
                </template>
                <template #snf="{ item: milkyield }">
                  <span>{{ milkyield.attributes.snf }}</span>
                </template>
                <template #denovo_fatty_acids="{ item: milkyield }">
                  <span>{{ milkyield.attributes.denovo_fatty_acids }}</span>
                </template>
                <template #mixed_fatty_acids="{ item: milkyield }">
                  <span>{{ milkyield.attributes.mixed_fatty_acids }}</span>
                </template>
                <template #preformed_fatty_acids="{ item: milkyield }">
                  <span>{{ milkyield.attributes.preformed_fatty_acids }}</span>
                </template>
                <template #denovo_relative="{ item: milkyield }">
                  <span>{{ milkyield.attributes.denovo_relative }}</span>
                </template>
                <template #mixed_relative="{ item: milkyield }">
                  <span>{{ milkyield.attributes.mixed_relative }}</span>
                </template>
                <template #preformed_relative="{ item: milkyield }">
                  <span>{{ milkyield.attributes.preformed_relative }}</span>
                </template>
                <template #nefa="{ item: milkyield }">
                  <span>{{ milkyield.attributes.nefa }}</span>
                </template>

                <template #empty>Geen melkgiften gevonden</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
        <div v-else>
          <AppSpinner />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
import ExportOptions from "@/components/Lists/ExportOptions.vue";
import SearchInput from "@/components/SearchInput.vue";
import CoreStatCard from "@/components/Cards/CoreStatCard.vue";
import parseTranslations from "@/mixins/parseTranslations";

export default {
  name: "MilkPerAnimal",
  components: {
    PeriodFilter,
    ExportOptions,
    CoreStatCard,
    SearchInput,
  },
  mixins: [parseTranslations, pageLoader, defaultListPage("milk_yields", "milk_yield", true, true)],
  data() {
    return {
      columns: {
        life_number: "(Werk)nummer",
        date: { title: "Datum", filter: { type: "date" } },
        amount: { title: "Liters", filter: { type: "number" } },
        milker_company_name: {
          title: "Melker",
          filter: {
            type: "async",
            values: this.$http.secured.get("/contacts").then((response) => {
              const contacts = [];
              response.data.data
                .filter((contact) => contact.attributes.contact_type === "milker")
                .map((contact) => {
                  contacts.push({
                    value: contact.attributes.company_name,
                    label: contact.attributes.company_name,
                  });
                });

              return contacts;
            }),
          },
        },
        fat: { title: "Vet", filter: { type: "number" } },
        protein: { title: "Eiwit", filter: { type: "number" } },
        lactose: { title: "Lactose", filter: { type: "number" } },
        solids: { title: "Va.stof", filter: { type: "number" } },
        npn_cu_a2: { title: "NPN/CU a2", filter: { type: "number" } },
        cells: { title: "Cellen", filter: { type: "number" } },
        qvalue: { title: "QValue", filter: { type: "number" } },
        snf: { title: "VVDS", filter: { type: "number" } },
        npn_cu: { title: "NPN/CU", filter: { type: "number" } },
        denovo_fatty_acids: { title: "Denovo FA", filter: { type: "number" } },
        mixed_fatty_acids: { title: "Mixed FA", filter: { type: "number" } },
        preformed_fatty_acids: { title: "Preformed FA", filter: { type: "number" } },
        denovo_relative: { title: "Denovo (FA rel)", filter: { type: "number" } },
        mixed_relative: { title: "Mixed (FA rel)", filter: { type: "number" } },
        preformed_relative: { title: "Preformed (FA rel)", filter: { type: "number" } },
        nefa: { title: "NEFA", filter: { type: "number" } },
        distribution: { title: "d(0.9)", filter: { type: "number" } },
      },
    };
  },
  computed: {
    ...mapState({
      milkyields: (state) => {
        return state.default_lists.items.map((milkyield) => {
          const animal = state.default_lists.included.find(
            (animal) => animal.id === milkyield.relationships.animal.data.id,
          );
          const milker = state.default_lists.included.find(
            (milker) => milker.id === milkyield.relationships.milker.data?.id,
          );
          return {
            ...milkyield,
            animal: {
              ...animal,
              attributes: {
                ...animal.attributes,
                animal_numbers: {
                  lifeNumber: animal.attributes.life_number?.substring(
                    0,
                    animal.attributes.life_number.length - 5,
                  ),
                  workNumber: animal.attributes.work_number?.substring(
                    animal.attributes.work_number.length - 5,
                  ),
                },
              },
            },
            milker: {
              ...milker,
              attributes: {
                ...milker?.attributes,
                company_name: milker?.attributes?.company_name,
              },
            },
          };
        });
      },
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(item) {
      return item.id;
    },
  },
};
</script>
<style></style>
