<template>
  <div class="calendar-days">
    <div class="calendar-days-content">
      <div class="calendar-start-date">
        <month-year-selector
          :month="startMonth"
          :year="startYear"
          :on-month-change="setMonth"
          :on-year-change="setYear"
          :start-selection="true"
        />
        <div class="calendar-options">
          <div v-for="week in getWeeksForMonth()" :key="week" class="calendar-options-row">
            <day
              v-for="index in getDaysForWeek(true, week)"
              :key="index"
              :day="index + (week - 1) * 7"
              :month="startMonth"
              :year="startYear"
              :start-date="startDate"
              :end-date="endDate"
              :start-selection="true"
              :on-select-date="selectedDate"
              :single-date="singleDate"
            />
          </div>
        </div>
      </div>
      <div v-if="!singleDate" class="calendar-end-date">
        <month-year-selector
          :month="endMonth"
          :year="endYear"
          :on-month-change="setMonth"
          :on-year-change="setYear"
          :start-selection="false"
        />
        <div class="calendar-options">
          <div v-for="week in getWeeksForMonth()" :key="week" class="calendar-options-row">
            <day
              v-for="index in getDaysForWeek(false, week)"
              :key="index"
              :day="index + (week - 1) * 7"
              :month="endMonth"
              :year="endYear"
              :start-date="startDate"
              :end-date="endDate"
              :start-selection="false"
              :on-select-date="selectedDate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import MonthYearSelector from "./MonthYearSelector.vue";
import Day from "./Day.vue";

export default {
  name: "PeriodFilterDays",
  components: { MonthYearSelector, Day },
  props: {
    singleDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    startDate: {
      type: Date,
      default: undefined,
      required: false,
    },
    endDate: {
      type: Date,
      default: undefined,
      required: false,
    },
    startMonth: {
      type: Number,
      default: undefined,
      required: false,
    },
    endMonth: {
      type: Number,
      default: undefined,
      required: false,
    },
    startYear: {
      type: Number,
      default: undefined,
      required: false,
    },
    endYear: {
      type: Number,
      default: undefined,
      required: false,
    },
    onDatesChange: {
      type: Function,
      required: true,
      // default: undefined,
    },
    onMonthChange: {
      type: Function,
      required: true,
      // default: undefined,
    },
    onYearChange: {
      type: Function,
      required: true,
      default: undefined,
    },
  },
  methods: {
    getDaysForMonth(isStartDate) {
      let month;
      let year;
      if (isStartDate) {
        month = this.startMonth;
        year = this.startYear;
      } else {
        month = this.endMonth;
        year = this.endYear;
      }
      return moment().year(year).month(month).daysInMonth();
    },
    getDaysForWeek(isStartDate, week) {
      const days = this.getDaysForMonth(isStartDate);
      const daysLeft = days - (week - 1) * 7;
      if (daysLeft >= 7) return 7;
      return daysLeft;
    },
    getWeeksForMonth(isStartDate) {
      return Math.ceil(this.getDaysForMonth(isStartDate) / 7);
    },
    setMonth(isStartDate, month) {
      this.onMonthChange({
        start: isStartDate ? month : undefined,
        end: !isStartDate ? month : undefined,
      });
    },
    setYear(isStartDate, year) {
      this.onYearChange({
        start: isStartDate ? year : undefined,
        end: !isStartDate ? year : undefined,
      });
    },
    selectedDate(isStartDate, day) {
      let start = this.startDate;
      let end = this.singleDate ? this.startDate : this.endDate;

      if (isStartDate || this.singleDate) {
        start = moment()
          .year(this.startYear)
          .month(this.startMonth)
          .date(day)
          .startOf("date")
          .toDate();

        if (this.singleDate) {
          end = moment()
            .year(this.startYear)
            .month(this.startMonth)
            .date(day)
            .endOf("date")
            .toDate();
        }
      } else {
        end = moment().year(this.endYear).month(this.endMonth).date(day).endOf("date").toDate();
      }
      this.onDatesChange({ start, end });
    },
  },
};
</script>
<style scope lang="scss">
.calendar-days {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  > .calendar-days-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    flex: 1;

    > div {
      padding: 16px;
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
