import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "nav-item active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user") || false;

  if (to.meta.allowUnAuthenticated) {
    next();
  } else if (loggedIn) {
    next();
  } else {
    next({
      path: "/sign_in",
      params: { nextUrl: to.fullPath },
    });
  }
});

export default router;
