<template>
  <div
    v-if="open"
    v-click-outside="handleCancel"
    class="period-picker-modal"
    :class="{ arrowEnabled }"
  >
    <div v-if="shouldRenderLeftSide" class="view-types">
      <div class="view-types-header">
        <p>Weergave</p>
      </div>
      <view-type
        v-if="canShowField('days')"
        v-model="viewType"
        value="days"
        label="Dagen"
        @change="handleViewChange"
      />
      <view-type
        v-if="canShowField('months')"
        v-model="viewType"
        value="months"
        label="Maanden"
        @change="handleViewChange"
      />
      <view-type
        v-if="canShowField('quarters')"
        v-model="viewType"
        value="quarters"
        label="Kwartalen"
        @change="handleViewChange"
      />
      <view-type
        v-if="canShowField('years')"
        v-model="viewType"
        value="years"
        label="Jaren"
        @change="handleViewChange"
      />
    </div>
    <div class="period-picker-right">
      <div class="period-picker-content">
        <period-filter-days
          v-if="viewType == 'days' && canShowField('days')"
          :single-date="singleDate"
          :start-date="startDate"
          :start-month="selectedStartMonth"
          :start-year="selectedStartYear"
          :end-date="endDate"
          :end-month="selectedEndMonth"
          :end-year="selectedEndYear"
          :on-month-change="handleMonthsChange"
          :on-year-change="handleYearsChange"
          :on-dates-change="handleDatesChange"
        />
        <period-filter-months
          v-if="viewType == 'months' && canShowField('months')"
          :single-date="singleDate"
          :start-date="startDate"
          :start-year="selectedStartYear"
          :end-date="endDate"
          :end-year="selectedEndYear"
          :on-year-change="handleYearsChange"
          :on-dates-change="handleDatesChange"
        />
        <period-filter-quarters
          v-if="viewType == 'quarters' && canShowField('quarters')"
          :single-date="singleDate"
          :start-date="startDate"
          :start-year="selectedStartYear"
          :end-date="endDate"
          :end-year="selectedEndYear"
          :on-year-change="handleYearsChange"
          :on-dates-change="handleDatesChange"
        />
        <period-filter-years
          v-if="viewType == 'years' && canShowField('years')"
          :single-date="singleDate"
          :start-date="startDate"
          :start-year="selectedStartYear"
          :end-date="endDate"
          :end-year="selectedEndYear"
          :on-year-change="handleYearsChange"
          :on-dates-change="handleDatesChange"
        />
      </div>
      <div class="period-picker-footer">
        <p id="no-date" class="no-date"></p>
        <div class="period-picker-footer-options">
          <button class="cancel-button" @click="handleCancel">Annuleren</button>
          <button class="continue-button" @click="handleSubmit">Toepassen</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import rangeText from "./rangeText";
import PeriodFilterDays from "./PeriodFilterDays.vue";
import PeriodFilterMonths from "./PeriodFilterMonths.vue";
import PeriodFilterQuarters from "./PeriodFilterQuarters.vue";
import PeriodFilterYears from "./PeriodFilterYears.vue";
import ViewType from "./ViewType.vue";

export default {
  name: "PeriodFilterModal",
  components: {
    PeriodFilterDays,
    PeriodFilterMonths,
    PeriodFilterQuarters,
    PeriodFilterYears,
    ViewType,
  },
  mixins: [rangeText],
  props: {
    singleDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultViewType: {
      type: String,
      required: false,
      default: "months",
    },
    period: {
      type: Object,
      required: true,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    arrowEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableViews: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      localSubmittedViewType: this.defaultViewType ?? "months",
      viewType: this.defaultViewType ?? "months",
      startDate: this.period.startDate ?? undefined,
      endDate: this.period.endDate ?? undefined,
      selectedStartMonth: moment(this.period.startDate).month(),
      selectedEndMonth: moment(this.period.endDate).month(),
      selectedStartYear: moment(this.period.startDate).year(),
      selectedEndYear: moment(this.period.endDate).year(),
    };
  },
  computed: {
    computedEndDate() {
      return this.singleDate ? undefined : this.endDate;
    },
    shouldRenderLeftSide() {
      if (!this.filter && !this.singleDate) {
        return true;
      }
      return false;
    },
  },
  watch: {
    open(current, previous) {
      if (!previous && current) {
        this.resetValues();
      }
    },
  },
  methods: {
    canShowField(type) {
      return !this.disableViews?.includes(type);
    },
    // Reset all the values to when the picker was opened.
    resetValues(resetViewType = true) {
      if (resetViewType) this.viewType = this.localSubmittedViewType;
      switch (this.viewType) {
        case "days": {
          this.startDate = moment(this.period.startDate).startOf("date").toDate();
          this.endDate = moment(this.period.endDate).endOf("date").toDate();
          break;
        }
        case "months": {
          this.startDate = moment(this.period.startDate).startOf("month").toDate();
          this.endDate = moment(this.period.endDate).endOf("month").toDate();
          break;
        }
        case "quarters": {
          this.startDate = moment(this.period.startDate).startOf("quarter").toDate();
          this.endDate = moment(this.period.endDate).endOf("quarter").toDate();
          break;
        }
        case "years": {
          this.startDate = moment(this.period.startDate).startOf("year").toDate();
          this.endDate = moment(this.period.endDate).endOf("year").toDate();
          break;
        }
        default: {
          break;
        }
      }
      this.selectedStartMonth = moment(this.period.startDate).month();
      this.selectedStartYear = moment(this.period.startDate).year();
      this.selectedEndMonth = moment(this.period.endDate).month();
      this.selectedEndYear = moment(this.period.endDate).year();
    },
    handleViewChange() {
      this.resetValues(false);
    },
    handleMonthsChange(data) {
      if (data.start !== undefined) {
        this.selectedStartMonth = data.start;
        // this.startDate = undefined;
        // this.endDate = undefined;
      }
      if (data.end !== undefined) {
        this.selectedEndMonth = data.end;
        this.endDate = undefined;
      }
    },
    handleYearsChange(data) {
      if (data.start !== undefined) {
        this.selectedStartYear = data.start;
        // this.startDate = undefined;
        // this.endDate = undefined;
      }
      if (data.end !== undefined) {
        this.selectedEndYear = data.end;
        // this.endDate = undefined;
      }
    },
    handleDatesChange(data) {
      // Only update the start or end date if this is provided.
      if ("start" in data) this.startDate = data.start;
      if ("end" in data) this.endDate = data.end;
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleSubmit() {
      this.localSubmittedViewType = this.viewType;

      let { startDate, endDate } = this;
      if (this.singleDate) endDate = undefined;

      switch (this.viewType) {
        case "days": {
          if (!startDate && endDate) {
            startDate = moment(endDate).startOf("day").toDate();
          } else if (!endDate && startDate) {
            endDate = moment(startDate).endOf("day").toDate();
          }
          break;
        }
        case "months": {
          if (!startDate && endDate) {
            startDate = moment(endDate).startOf("month").toDate();
          } else if (!endDate && startDate) {
            endDate = moment(startDate).endOf("month").toDate();
          }
          break;
        }
        case "quarters": {
          if (!startDate && endDate) {
            startDate = moment(endDate).startOf("quarter").toDate();
          } else if (!endDate && startDate) {
            endDate = moment(startDate).endOf("quarter").toDate();
          }
          break;
        }
        case "years": {
          if (!startDate && endDate) {
            startDate = moment(endDate).startOf("year").toDate();
          } else if (!endDate && startDate) {
            endDate = moment(startDate).endOf("year").toDate();
          }
          break;
        }
        default: {
          break;
        }
      }

      const p = document.getElementById("no-date");
      p.innerHTML = "Er is geen datum opgegeven";

      if (Object.prototype.toString.call(startDate) === "[object Date]") {
        if (!isNaN(startDate.getTime())) {
          p.innerHTML = "";
          this.$emit("change", {
            startDate,
            endDate,
            type: this.viewType,
          });
        }
      }
    },
  },
};
</script>
<style scope lang="scss">
.period-picker-modal {
  width: 755px;
  height: 343px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);

  // test styling
  position: absolute;
  border: 1px solid #ddd;
  top: auto;
  left: auto;
  right: -50px;
  z-index: 3001;

  &.arrowEnabled {
    margin-top: 7px;

    &:before {
      right: 73px;
      top: -7px;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom: 7px solid #ccc;
      position: absolute;
      display: inline-block;
      content: "";
    }

    &:after {
      right: 74px;
      top: -6px;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-left: 6px solid transparent;
      position: absolute;
      display: inline-block;
      content: "";
    }
  }

  button {
    background-color: #2e994c;
    color: #fff;
    border: 0;
    border-radius: 8px;
    padding: 8px 12px;

    &:hover {
      background-color: #54ad6d;
    }
  }

  .calendar-option {
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    background-color: transparent;
    color: #333 !important;
    font-weight: 400;
    font-size: 14px;
    &:hover {
      background-color: #f0f0f080;
    }
  }

  .calendar-options {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;

    > .calendar-options-row {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      flex: 1;

      > .calendar-option {
        height: 100%;
        flex: 1;
      }
    }
  }

  .no-date {
    padding: 0;
    margin: 0;
    color: red;
    font-size: 14px;
    font-weight: 400;
  }

  .calendar-option-between-dates {
    background-color: #f0f0f080 !important;
    color: #2e994c !important;
  }

  .calendar-option-selected {
    background-color: #2e994c !important;
    color: #fff !important;
    border-radius: 8px;
  }

  .calendar-option-disabled {
    pointer-events: none;
    cursor: default;
    color: #9c9c9c !important;
  }
  .calendar-option-is-start-date {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .calendar-option-is-end-date {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.view-types {
  width: 138px;
  height: 100%;
  object-fit: contain;
  border-right: 1px solid #d8d8d8;

  .view-types-header {
    padding: 16px;
    padding-top: 24px;

    > p {
      padding: 0;
      margin: 0;
      color: #949494;
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.period-picker-right {
  flex: 1;
  display: flex;
  flex-direction: column;

  > .period-picker-content {
    flex: 1;
    width: 100%;
  }

  > .period-picker-footer {
    height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-top: 1px solid #d8d8d8;

    .selected-date {
      padding: 0;
      margin: 0;
      color: #333;
      font-size: 14px;
      font-weight: 400;
    }

    .cancel-button {
      background: transparent;
      color: #9e9cb1;
    }
  }
}
</style>
