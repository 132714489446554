import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import default_lists from "./modules/default_lists";
import doses from "./modules/doses";
import statistics from "./modules/statistics";
import animals from "./modules/animals";
import groups from "./modules/groups";
import medicines from "./modules/medicines";
import messages from "./modules/messages";
import rvo_reports from "./modules/rvo_reports";
import scan_errors from "./modules/scan_errors";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    default_lists,
    doses,
    statistics,
    animals,
    groups,
    medicines,
    messages,
    rvo_reports,
    scan_errors,
  },
  mutations: {
    setItems(state, { resource, items }) {
      state[resource].items = items;
    },
    setIncluded(state, { resource, included }) {
      state[resource].included = included;
    },
    setItem(state, { resource, item }) {
      state[resource].item = item;
    },
    addItemToArray(state, { item, index, resource }) {
      Vue.set(state[resource].items, index, item);
    },
  },
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
  },
});
