<template>
  <div class="add-filter">
    <a class="add" @click="toggleFilter">
      Filter toevoegen
      <font-awesome-icon icon="plus" />
    </a>
    <ul v-if="filtersVisible && !filterTemplateVisible" id="filter-types">
      <li>
        <a class="add" @click="toggleFilter">
          Filter toevoegen
          <font-awesome-icon icon="minus" />
        </a>
      </li>
      <li v-for="filterTemplate in filterTemplates" :key="filterTemplate.id">
        <a @click="setFilterTemplate(filterTemplate.id)">
          {{ filterTemplate.attributes.label }}
        </a>
      </li>
    </ul>
    <template v-for="filterTemplate in filterTemplates">
      <ul
        v-if="filterTemplate.id == filterTemplateVisible"
        :key="filterTemplate.id"
        class="filter-template"
      >
        <li>
          <a class="remove">
            {{ filterTemplate.attributes.label }}
            <font-awesome-icon icon="trash-alt" @click="removeFilterTemplate" />
          </a>
        </li>
        <template v-if="filterTemplate.attributes.option_type == 'radio'">
          <li v-for="filterOption in filterTemplate.attributes.options" :key="filterOption.value">
            <a
              @click="
                saveFilter({
                  filterType: filterTemplate.id,
                  values: { is: filterOption.value },
                })
              "
              >{{ filterOption.name }}</a
            >
          </li>
        </template>
        <template v-if="filterTemplate.attributes.option_type == 'range'">
          <li v-for="filterOption in filterTemplate.attributes.options" :key="filterOption.value">
            <div class="d-flex">
              <label>{{ filterOption.min }}</label>
              <input
                v-model="filterOption.value"
                type="range"
                :min="filterOption.min"
                :max="filterOption.max"
                value="50"
                class="slider"
              />
              <label>{{ filterOption.max }}</label>
            </div>

            <label>{{ filterOption.value }}</label>
            <a
              @click="
                saveFilter({
                  filterType: filterTemplate.id,
                  values: { is: filterOption.value },
                })
              "
              >Opslaan</a
            >
          </li>
        </template>
      </ul>
    </template>
  </div>
</template>
<script>
export default {
  name: "AddFilter",
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    label: {
      type: String,
      description: "Input label",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    filterTemplates: {
      type: Array,
    },
  },
  data() {
    return {
      filterTemplateVisible: false,
      filtersVisible: false,
    };
  },
  methods: {
    toggleFilter() {
      this.filtersVisible = !this.filtersVisible;
    },
    setFilterTemplate(filterTemplate) {
      this.filterTemplateVisible = filterTemplate;
    },
    removeFilterTemplate() {
      this.filterTemplateVisible = false;
      this.filtersVisible = false;
    },
    saveFilter(filterParams) {
      this.$emit("save-list-filter", filterParams);
      this.removeFilterTemplate();
    },
  },
};
</script>
<style></style>
