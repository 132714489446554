<template>
  <div class="mr-4">
    <div @click="openDropdown">
      <span class="filter"></span>
    </div>
    <multi-select-dropdown
      dropdown-class="column-filter-dropdown"
      :open="dropdownOpen"
      :items="items"
      :initial-checked="initialChecked"
      @change="onChange"
      @cancel="onCancel"
    ></multi-select-dropdown>
  </div>
</template>
<script>
import MultiSelectDropdown from "@/components/Dropdowns/MultiSelectDropdown.vue";

export default {
  components: {
    MultiSelectDropdown,
  },
  props: {
    items: Array,
    initialChecked: Array,
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  methods: {
    openDropdown() {
      if (!this.dropdownOpen) {
        this.dropdownOpen = true;
      }
    },
    onChange(checked) {
      this.dropdownOpen = false;
      this.$emit("change", checked);
    },
    onCancel() {
      this.dropdownOpen = false;
    },
  },
};
</script>
<style lang="scss">
.column-filter-dropdown {
  margin-right: -2rem;
}
</style>
