<template>
  <div class="wrapper">
    <side-bar>
      <sidebar-link to="/dashboard">Dashboard</sidebar-link>
      <sidebar-link to="/technical-figures">Technische kengetallen</sidebar-link>
      <sidebar-link to="/lists">Lijsten</sidebar-link>
      <sidebar-link to="/passport">Paspoort</sidebar-link>
      <sidebar-link to="/groups">Groepen</sidebar-link>
      <sidebar-link to="/transports">Transport</sidebar-link>
      <template slot="bottom-links">
        <sidebar-link to="/settings">Instellingen</sidebar-link>
        <li>
          <a class="nav-link" @click.prevent="signOut">Uitloggen</a>
        </li>
      </template>
    </side-bar>
    <router-view name="sidebar" />
    <div class="main-panel">
      <dashboard-content @click="toggleSidebar"></dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";

export default {
  components: {
    ContentFooter,
    DashboardContent,
  },
  created() {
    this.$sidebar.displaySubSidebar(false);
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSubSidebar) {
        this.$sidebar.displaySubSidebar(false);
      }
    },
    setError(error, text) {
      this.error = (error.response && error.response.data && error.response.data.error) || text;
    },
    signOut() {
      this.$http.secured
        .delete("/sign_out")
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.$store.dispatch("auth/logout");
          this.$router.replace("/sign_in");
        })
        .catch((error) => this.setError(error, "Cannot sign out"));
    },
  },
};
</script>
