<template>
  <div class="monthyear-selector">
    <button class="switch-button" @click="previousIndex">
      <div class="left" />
    </button>
    <div class="switch-selectors">
      <select v-if="showMonth" class="selected-month select-item" @change="handleMonthChange">
        <option
          v-for="(item, index) in getMonths()"
          :key="item"
          :selected="index === month"
          :value="index"
        >
          {{ item }}
        </option>
      </select>
      <select class="selected-year select-item" @change="handleYearChange">
        <option
          v-for="index in YEAR_COUNT"
          :key="index"
          :selected="index + 1969 === year"
          :value="index + 1969"
        >
          {{ index + 1969 }}
        </option>
      </select>
    </div>
    <button class="switch-button" @click="nextIndex">
      <div class="right" />
    </button>
  </div>
</template>
<script>
export default {
  name: "MonthYearSelector",
  props: {
    month: {
      type: Number,
      required: false,
      default: 0,
    },
    year: {
      type: Number,
      required: true,
      default: 1970,
    },
    showMonth: {
      type: Boolean,
      required: false,
      default: true,
    },
    onMonthChange: {
      type: Function,
      required: false,
      default: undefined,
    },
    onYearChange: {
      type: Function,
      required: true,
      default: undefined,
    },
    startSelection: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      YEAR_COUNT: 1001,
    };
  },
  methods: {
    getMonths() {
      const months = [];
      for (let i = 1; i < 13; i += 1) {
        months.push(
          new Date(Date.parse(`${this.year}-${`0${i}`.slice(-2)}-01`)).toLocaleDateString("nl-NL", {
            month: "short",
          }),
        );
      }
      return months;
    },
    handleMonthChange(ev) {
      // eslint-disable-next-line no-unused-expressions
      this.onMonthChange?.(this.startSelection, Number(ev.target.value));
    },
    handleYearChange(ev) {
      this.onYearChange(this.startSelection, Number(ev.target.value));
    },
    previousIndex() {
      if (this.showMonth && this.onMonthChange) {
        if (Number(this.month) - 1 < 0) {
          this.onMonthChange(this.startSelection, 11);
          this.onYearChange(this.startSelection, Number(this.year) - 1);
        } else {
          this.onMonthChange(this.startSelection, Number(this.month) - 1);
        }
        return;
      }
      this.onYearChange(this.startSelection, Number(this.year) - 1);
    },
    nextIndex() {
      if (this.showMonth && this.onMonthChange) {
        if (Number(this.month) + 1 > 11) {
          this.onMonthChange(this.startSelection, 0);
          this.onYearChange(this.startSelection, Number(this.year) + 1);
        } else {
          this.onMonthChange(this.startSelection, Number(this.month) + 1);
        }
        return;
      }
      this.onYearChange(this.startSelection, Number(this.year) + 1);
    },
  },
};
</script>
<style scope lang="scss">
.monthyear-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  > .switch-selectors {
    display: flex;
    flex-direction: row;
    align-items: center;

    > .select-item {
      width: 90px;
      height: 32px;
      border: 0;

      &:nth-of-type(2) {
        margin-left: 10px;
      }
    }
  }
}
.switch-button {
  background: transparent !important;
  color: #333 !important;
  width: 40px;

  height: 40px;

  &:hover {
    background-color: #f0f0f080 !important;
  }

  div {
    width: 60%;
    height: 60%;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
  }
  div.left {
    background-image: url("~@/assets/images/icons/chevron-right.svg");
    transform: scaleX(-1);
  }
  div.right {
    background-image: url("~@/assets/images/icons/chevron-right.svg");
    background-repeat: no-repeat;
  }
}
</style>
