<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div>
        <div v-for="(values, key) in messages" :key="key">
          <h4>{{ key }}</h4>
          <div v-for="(grouped_messages, key) in values" :key="key">
            <h6>{{ key }}</h6>
            <table class="table">
              <thead>
                <tr>
                  <th>Melding</th>
                  <th>Soort</th>
                  <th>Levensnummer</th>
                  <th>Diersoort</th>
                  <th>melding_status</th>
                  <th>Vlagsoort</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="message in grouped_messages" :key="message.meldingnummer">
                  <td>
                    <router-link
                      :to="{
                        path: '/lists/rvo-reports/' + message.meldingnummer,
                      }"
                      >{{ message.meldingnummer }}</router-link
                    >
                  </td>
                  <td>{{ message.berichttype }}</td>
                  <td>{{ message.dier_levensnummer }}</td>
                  <td>{{ message.diersoort == "3" ? "Schaap" : "Geit" }}</td>
                  <td>{{ message.melding_status }}</td>
                  <td>{{ message.vlagsoort }}</td>
                  <td style="width: 20%; font-size: 8px"></td>
                </tr>
                <tr v-if="!pageLoader_isDataLoaded">
                  <td colspan="42" class="text-center">
                    <AppSpinner />
                  </td>
                </tr>
                <tr v-else-if="messages.length == 0">
                  <td v-if="messages.length == 0" colspan="42" class="text-center">
                    Er zijn nog geen berichten
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="text-center">
          <div v-if="loadingPage">
            <AppSpinner />
          </div>
          <div v-else>
            <div v-if="pagination.page != 1">Je kijkt nu {{ pagination.page }} maanden terug</div>
            <hr />
            <b-button variant="success" @click="fetchMoreItems(pagination.page + 1)"
              >Nog een maand extra ophalen</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "RepairableTable",
  mixins: [pageLoader],
  data() {
    return {
      progressInterval: "",
      imports: [],
      error: "",
      query: "",
      loadingPage: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
    ...mapState({
      messages: (state) => state.rvo_reports.repairableItems,
      pagination: (state) => state.rvo_reports.pagination,
    }),
  },
  created() {
    this.setPage(1);
    this.pageLoader_loadingData();

    Promise.all([this.handleFetchItems({})])
      .then(() => {
        this.pageLoader_resolveData();
      })
      .catch(() => {
        this.pageLoader_resolveData();
      });
  },
  methods: {
    ...mapActions("rvo_reports", ["fetchRepairableReports", "initializePagesFromQuery"]),
    handleFetchItems({ reset }) {
      const filter = {};
      filter.page = this.pagination.page;

      return this.fetchRepairableReports({ filter, reset }).then(() => (this.loadingPage = false));
    },
    setPage(page) {
      this.$store.commit("rvo_reports/setPage", page);
    },
    setQueryParams() {
      const { page } = this.pagination;
      this.$router.push({ query: { page } });
    },
    fetchMoreItems(page) {
      this.setPage(page);
      this.loadingPage = true;
      this.handleFetchItems({ reset: false });
    },
  },
};
</script>
<style></style>
