import Vue from "vue";

import { axiosInstance } from "@/services/axios";
import { applyFilters, createQuery } from "@/helpers";

export default {
  namespaced: true,
  state: {
    lifeNumbers: [],
    paternities: [],
    items: [],
    item: {},
    weighings: [],
    pagination: {
      totalCount: 0,
      totalPages: 0,
      pageSize: 25,
      page: 1,
    },
    period: {
      startDate: null,
      endDate: null,
    },
  },
  getters: {
    getDead(state) {
      return (
        state.items
          .filter((animal) => animal.date_of_death !== null)
          // eslint-disable-next-line func-names
          .sort(function (a, b) {
            // eslint-disable-next-line no-nested-ternary
            return a.date_of_death === b.date_of_death
              ? 0
              : a.date_of_death < b.date_of_death
              ? 1
              : -1;
          })
      );
    },
    authUser(state) {
      return state.user || null;
    },
  },
  actions: {
    fetchAnimalLifeNumbers({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setLifeNumbers", []);
      }
      const url = applyFilters("/animals/life_numbers", options.filter);

      return axiosInstance.get(url).then((res) => {
        const lifeNumbers = res.data;
        commit("setLifeNumbers", lifeNumbers);
        return state.lifeNumbers;
      });
    },
    fetchAnimalPaternities({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setPaternities", []);
      }
      const url = applyFilters("/paternities", options.filter);

      return axiosInstance.get(url).then((res) => {
        const paternities = res.data.data;
        commit("setPaternities", paternities);
        return state.paternities;
      });
    },
    fetchAnimals({ state, commit }, options = { reset: true }) {
      if (options.reset) {
        commit("setItems", { resource: "animals", items: [] }, { root: true });
      }

      const url = `/animals/paginated_index?${createQuery(
        options.filter,
        options.sort,
        options.extraFilters,
      )}`;

      return axiosInstance.get(url).then((res) => {
        let animals = res.data.data.map((animal) => {
          return {
            ...animal,
            attributes: {
              ...animal.attributes,
              id: animal.id,
              animal_numbers: {
                lifeNumber: animal.attributes.life_number.substring(
                  0,
                  animal.attributes.life_number.length - 5,
                ),
                workNumber: animal.attributes.life_number.substring(
                  animal.attributes.life_number.length - 5,
                ),
              },
            },
          };
        });

        const includedDoses = res.data.included?.filter((included) => included.type === "dose");
        const includedWeighings = res.data.included?.filter(
          (included) => included.type === "weighing",
        );

        // added doses to animals
        if (includedDoses.length > 0) {
          animals = animals.map((animal) => {
            return {
              ...animal,
              attributes: {
                ...animal.attributes,
                doses: includedDoses.filter((dose) =>
                  animal.relationships.doses.data.find(({ id }) => id === dose.id),
                ),
              },
            };
          });
        }

        if (includedWeighings.length > 0) {
          animals = animals.map((animal) => {
            return {
              ...animal,
              attributes: {
                ...animal.attributes,
                weighings: includedWeighings
                  .filter((weighing) =>
                    animal.relationships.weighings.data.find(({ id }) => id === weighing.id),
                  )
                  .map((weighing) => ({
                    id: weighing.id,
                    weight: weighing.attributes.weight,
                    created_at: weighing.attributes.weight_date,
                  })),
              },
            };
          });
        }

        commit(
          "setItems",
          {
            resource: "animals",
            items: animals.map((animal) => ({ id: animal.id, ...animal.attributes })),
          },
          { root: true },
        );

        commit("setPagination", { pagination: res.data.meta });

        return state.items;
      });
    },
    fetchAnimalById({ state, commit }, animalId) {
      commit("setItem", { resource: "animals", item: {} }, { root: true });
      return axiosInstance.get(`animals/${animalId}`).then((res) => {
        const animal = res.data;

        commit("setItem", { resource: "animals", item: animal }, { root: true });
        return state.item;
      });
    },
    fetchAnimalRvoById({ state, commit }, animalId) {
      commit("setItem", { resource: "animals", item: {} }, { root: true });
      return axiosInstance.get(`animals/${animalId}/rvo`).then((res) => {
        const animal = res.data.data;
        if (Object.prototype.hasOwnProperty.call(res.data, "meta")) {
          animal.attributes.meta = res.data.meta;
        }
        commit("setItem", { resource: "animals", item: animal.attributes }, { root: true });
        return state.item;
      });
    },
    fetchAnimalRvoLocationsById({ state, commit }, animalId) {
      commit("setItem", { resource: "animals", item: {} }, { root: true });
      return axiosInstance.get(`animals/${animalId}/rvo_locations`).then((res) => {
        const animal = res.data.data;
        if (Object.prototype.hasOwnProperty.call(res.data, "meta")) {
          animal.attributes.meta = res.data.meta;
        }
        commit("setItem", { resource: "animals", item: animal.attributes }, { root: true });
        return state.item;
      });
    },
    initializePagesFromQuery({ commit }, { page, startDate, endDate }) {
      commit("setPage", page);
      commit("setPeriod", { startDate, endDate });
    },
    bulkAddAnimalsCharacteristics({ state, commit }, { animalIds, characteristics }) {
      return Promise.all(
        characteristics.map((characteristic) =>
          axiosInstance.post("animals/bulk/characteristics", {
            animals: {
              animal_ids: animalIds,
              characteristic,
            },
          }),
        ),
      ).then(() => {
        commit(
          "setItems",
          {
            resource: "animals",
            // FIXME: corrupts state
            items: state.items.map((animal) =>
              animalIds.includes(animal.id)
                ? {
                    ...animal,
                    characteristics: [...animal.characteristics, ...characteristics],
                  }
                : {},
            ),
          },
          { root: true },
        );
      });
    },
    bulkDeleteAnimalsCharacteristics({ state, commit }, { animalIds, characteristics }) {
      return Promise.all(
        characteristics.map((characteristic) =>
          axiosInstance.delete("animals/bulk/characteristics", {
            data: {
              animals: {
                animal_ids: animalIds,
                characteristic,
              },
            },
          }),
        ),
      ).then(() => {
        commit(
          "setItems",
          {
            resource: "animals",
            items: state.items.map((animal) =>
              animalIds.includes(animal.id)
                ? {
                    ...animal,
                    characteristics: animal.characteristics.filter(
                      (characteristic) => !characteristics.includes(characteristic),
                    ),
                  }
                : animal,
            ),
          },
          { root: true },
        );
      });
    },
    bulkAddAnimalsWeighings({ state, commit }, weighings) {
      return Promise.all(
        weighings.map(({ animalIds, weight, weightDate }) =>
          axiosInstance.post("weighings/bulk", {
            weighing: {
              animal_ids: animalIds,
              weight,
              weight_date: weightDate,
            },
          }),
        ),
      ).then(() => {
        axiosInstance.get("weighings").then(({ data: saved }) => {
          commit(
            "setItems",
            {
              resource: "animals",
              items: state.items.map((animal) => ({
                ...animal,
                weighings: saved.data
                  .filter(({ attributes }) => attributes.animal_id === animal.id)
                  .map(({ attributes }) => ({
                    id: attributes.id,
                    weight: attributes.weight,
                    created_at: attributes.weight_date,
                  })),
              })),
            },
            { root: true },
          );
        });
      });
    },
    bulkUpdateAnimalsWeighings({ state, commit }, weighings) {
      return Promise.all(
        weighings.map(({ weight, weightDate, weighingIds }) =>
          axiosInstance.put("weighings/bulk", {
            weighing: {
              weight,
              weight_date: weightDate,
              weighing_ids: weighingIds,
            },
          }),
        ),
      ).then(() => {
        const changedWeighings = weighings.reduce((acc, { weighingIds, ...value }) => {
          weighingIds.forEach((weighingId) => {
            acc[weighingId] = value;
          });
          return acc;
        }, {});

        commit(
          "setItems",
          {
            resource: "animals",
            items: state.items.map((animal) => ({
              ...animal,
              weighings: animal.weighings.map((weighing) => {
                const changed = changedWeighings[weighing.id];
                return changed
                  ? {
                      id: weighing.id,
                      weight: changed.weight,
                      created_at: changed.weightDate,
                    }
                  : weighing;
              }),
            })),
          },
          { root: true },
        );
      });
    },
    bulkDeleteAnimalsWeighings({ state, commit }, weighingIds) {
      return axiosInstance
        .delete("weighings/bulk", { data: { weighing: { weighing_ids: weighingIds } } })
        .then(() => {
          commit(
            "setItems",
            {
              resource: "animals",
              items: state.items.map((animal) => ({
                ...animal,
                weighings: animal.weighings.filter(
                  (weighing) => !weighingIds.includes(weighing.id),
                ),
              })),
            },
            { root: true },
          );
        });
    },
    bulkAddAnimalsDoses({ state, commit }, doses) {
      return Promise.all(
        doses.map(({ animalIds, unit, amount, doseDate, medicineId }) =>
          axiosInstance.post("doses/bulk", {
            dose: {
              animal_ids: animalIds,
              unit,
              amount,
              dose_date: doseDate,
              medicine_id: medicineId,
            },
          }),
        ),
      ).then(() => {
        axiosInstance.get("doses").then(({ data: saved }) => {
          commit(
            "setItems",
            {
              resource: "animals",
              items: state.items.map((animal) => ({
                ...animal,
                doses: saved.data.filter(({ attributes }) => attributes.animal_id === animal.id),
              })),
            },
            { root: true },
          );
        });
      });
    },
    bulkUpdateAnimalsDoses({ state, commit }, doses) {
      return Promise.all(
        doses.map(({ unit, amount, doseDate, medicineId, doseIds }) =>
          axiosInstance.put("doses/bulk", {
            dose: {
              unit,
              amount,
              dose_date: doseDate,
              medicine_id: medicineId,
              dose_ids: doseIds,
            },
          }),
        ),
      ).then(() => {
        const changedDoses = doses.reduce((acc, { doseIds, ...value }) => {
          doseIds.forEach((doseId) => {
            acc[doseId] = value;
          });
          return acc;
        }, {});

        commit(
          "setItems",
          {
            resource: "animals",
            items: state.items.map((animal) => ({
              ...animal,
              doses: animal.doses.map((dose) => {
                const changed = changedDoses[dose.id];
                return changed
                  ? {
                      ...dose,
                      attributes: {
                        ...dose.attributes,
                        amount: changed.amount,
                        dose_date: changed.doseDate,
                        medicine_name: changed.medicineName,
                        unit: changed.unit,
                      },
                      relationships: {
                        ...dose.relationships,
                        medicine: {
                          data: {
                            ...dose.relationships.medicine.data,
                            id: changed.medicineId,
                          },
                        },
                      },
                    }
                  : dose;
              }),
            })),
          },
          { root: true },
        );
      });
    },
    bulkDeleteAnimalsDoses({ state, commit }, doseIds) {
      return axiosInstance
        .delete("doses/bulk", { data: { dose: { dose_ids: doseIds } } })
        .then(() => {
          commit(
            "setItems",
            {
              resource: "animals",
              items: state.items.map((animal) => ({
                ...animal,
                doses: animal.doses.filter((dose) => !doseIds.includes(dose.id)),
              })),
            },
            { root: true },
          );
        });
    },
  },
  mutations: {
    setLifeNumbers(state, lifeNumbers) {
      state.lifeNumbers = lifeNumbers;
    },
    setPaternities(state, paternities) {
      state.paternities = paternities;
    },
    updateAnimal(state, updatedAnimal) {
      state.items = { ...state.items, ...updatedAnimal };
    },
    setPagination(state, pagination) {
      Vue.set(state.pagination, "totalCount", parseInt(pagination.pagination.total_count));
      Vue.set(state.pagination, "totalPages", parseInt(pagination.pagination.total_pages));
    },
    setPage(state, currentPage) {
      Vue.set(state.pagination, "page", parseInt(currentPage));
    },
    setPeriod(state, { startDate, endDate }) {
      Vue.set(state.period, "startDate", startDate);
      Vue.set(state.period, "endDate", endDate);
    },
    setPageSize(state, pageSize) {
      Vue.set(state.pagination, "pageSize", pageSize);
    },
  },
};
