<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div v-if="animalId">
        <div
          v-if="
            Object.prototype.hasOwnProperty.call(animal.meta, 'verwerkingsresultaat') &&
            animal.meta['verwerkingsresultaat']['succes_indicator'] == 'N'
          "
          class="row justify-content-center my-4"
        >
          <div class="col-12 text-red">
            {{ animal.meta["verwerkingsresultaat"]["foutmelding"] }}
          </div>
        </div>
        <div v-else>
          <div class="row justify-content-center my-4">
            <div class="col-6">
              <card>
                <div slot="header">Dier details</div>
                <div v-if="Object.prototype.hasOwnProperty.call(animal.meta, 'dier_details')">
                  <div v-for="(value, k) in animal.meta['dier_details']" :key="k">
                    <label>{{ humanize(k) }}</label> : {{ humanize(value) || "-" }}
                  </div>
                </div>
                <div v-else>—</div>
              </card>
            </div>
            <div class="col-6">
              <card>
                <div slot="header">RVO Vlaggen</div>
                <ul
                  v-if="Object.prototype.hasOwnProperty.call(animal.meta, 'dier_vlaggen')"
                  slot="raw-content"
                  class="list-group list-group-flush"
                >
                  <li
                    v-for="flag in [animal.meta['dier_vlaggen']].flat()"
                    :key="flag"
                    class="list-group-item"
                  >
                    <span v-for="(value, k) in flag" :key="k" class="d-block">
                      <label>{{ humanize(k) }}</label> : {{ value }}
                    </span>
                  </li>
                </ul>
                <div v-else>—</div>
              </card>
            </div>
          </div>
          <div class="row justify-content-center my-4">
            <div class="col-8">
              <card>
                <div slot="header">Verblijf</div>
                <ul
                  v-if="
                    Object.prototype.hasOwnProperty.call(
                      animal.meta,
                      'dier_details_verblijfplaatsen',
                    )
                  "
                  slot="raw-content"
                  class="list-group list-group-flush"
                >
                  <li
                    v-for="location in [animal.meta['dier_details_verblijfplaatsen']].flat()"
                    :key="location"
                    class="list-group-item"
                  >
                    <address>
                      <span v-for="(value, k) in location" :key="k" class="d-block">
                        <label>{{ humanize(k) }}</label> : {{ value }}
                      </span>
                    </address>
                  </li>
                </ul>
                <div v-else>—</div>
              </card>
            </div>
            <div class="col-4">
              <card>
                <div slot="header">RVO resultaat</div>
                <div
                  v-if="Object.prototype.hasOwnProperty.call(animal.meta, 'verwerkingsresultaat')"
                >
                  {{ animal.meta["verwerkingsresultaat"] }}
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "RvoOverview",
  mixins: [pageLoader],
  data() {
    return {
      animalLoaded: false,
      animals: [],
      animalId: "",
    };
  },
  computed: {
    ...mapState({
      animal: (state) => state.animals.item,
      groups: (state) => state.groups.items,
    }),
  },
  created() {
    this.animalId = this.$route.params.id;
    Promise.all([this.fetchAnimalRvoById(this.animalId)])
      .then(() => this.pageLoader_resolveData())
      .catch(() => {
        this.pageLoader_resolveData();
      });
  },
  methods: {
    ...mapActions("animals", ["fetchAnimalLifeNumbers", "fetchAnimalRvoById"]),
    getYearsAndMonthsOld(date) {
      const dateArray = date?.split("-")?.reverse();

      if (!this.validAnimalDateArray(dateArray)) {
        return "";
      }

      const dateNow = moment();
      const birthDate = moment(dateArray);

      const years = dateNow.diff(birthDate, "year");
      birthDate.add(years, "years");
      const months = dateNow.diff(birthDate, "months");
      const monthsText = months === 1 ? "maand" : "maanden";

      return `${years === 0 ? "" : `${years} jaar `}${months} ${monthsText} `;
    },
    validAnimalDateArray(dateArray) {
      if (dateArray && parseInt(dateArray[0]) && parseInt(dateArray[1]) && parseInt(dateArray[2])) {
        return true;
      }

      return false;
    },
    getDaysOld(date) {
      const dateArray = date?.split("-")?.reverse();

      if (!this.validAnimalDateArray(dateArray)) {
        return "";
      }

      const dateNow = moment();
      const birthDate = moment(dateArray);
      const days = dateNow.diff(birthDate, "days");
      // const daysText = days === 1 ? "dag" : "dagen";

      return days;
    },
    humanize(str) {
      if (str === null) {
        return "";
      }
      let i;
      const frags = str.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
  },
};
</script>
<style></style>
