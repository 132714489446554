<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <router-link class="back" :to="{ path: '/settings/contacts' }">
            <div class="icon-chevron-back"></div>
            Adressen
          </router-link>
          <h1 class="page-title">{{ contact.company_name }}</h1>
        </div>
      </div>
      <hr class="my-4" />
      <div class="row justify-content-center">
        <div class="col-4">
          <h6>Bedrijf</h6>
          <label>ubn</label>
          <br />
          {{ contact.ubn }}
          <label>KVK</label>
          {{ contact.chamber_of_commerce }}
        </div>
        <div class="col-4">
          <h6>Contact</h6>
          <label>Tel.</label>
          {{ contact.phone }}
          <br />
          <label>E-mail</label>
          {{ contact.email }}
        </div>
        <div class="col-4">
          <h6>Adres</h6>
          <address>
            {{ contact.address1 }}
            {{ contact.address2 }}
            <br />
            {{ contact.zipcode }}
            {{ contact.city }}
            <br />
            {{ contact.country }}
          </address>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Groups",
  data() {
    return {
      contact: {},
      error: "",
    };
  },
  created() {
    this.$http.secured
      .get(`/contacts/${this.$route.params.id}`)
      .then((response) => {
        this.contact = response.data.data.attributes;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
};
</script>
<style></style>
