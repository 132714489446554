<template>
  <div class="text-center">
    <div>
      <img class="mb-3 mt-5" alt="Vue logo" src="img/gribb-logo-wit-groot.svg" />

      <form class="form-signin" @submit.prevent="requestNewPassword">
        <div v-if="error" class="text-red">{{ error }}</div>
        <div v-if="notice" class="text-green">{{ notice }}</div>
        <div class="text-red">{{ apiurl }}</div>
        <p class="text-white">
          Reset wachtwoord Donec ullamcorper nulla non metus auctor fringilla. Maecenas faucibus
          mollis interdum.
        </p>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span id="basic-addon1" class="input-group-text">@</span>
          </div>
          <input
            id="email"
            v-model="email"
            type="email"
            class="form-control"
            placeholder="E-mail"
          />
        </div>
        <button type="submit" class="btn btn-primary mb-5 w-100">Versturen</button>
        <!-- <router-link to="/password" class="mt-5 font-weight-bold text-white">Wachtwoord vergeten?</router-link> -->
        <!-- <router-link to="/signup" class="mt-5 font-weight-bold text-white">Account aanmaken</router-link> -->
      </form>
    </div>
    <content-footer></content-footer>
  </div>
</template>

<script>
import ContentFooter from "@/layout/ContentFooter.vue";
import { boot, shutdown } from "@/services/intercom";

export default {
  name: "Password",
  components: {
    ContentFooter,
  },
  data() {
    return {
      notice: "",
      email: "",
      error: "",
      apiurl: process.env.API_URL,
    };
  },
  beforeMount() {
    boot();
  },
  beforeDestroy() {
    shutdown();
  },
  methods: {
    requestNewPassword() {
      this.$store
        .dispatch("auth/requestNewPassword", {
          user: { email: this.email },
        })
        .then(() => this.$router.push("/password"));
    },
  },
};
</script>
