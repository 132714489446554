var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medicine-select-container"},[_c('div',{staticClass:"col-12"},[_vm._m(0),_c('div',{staticClass:"row mb-2"},[_c('b-form-select',{staticClass:"selectMedicine",attrs:{"options":_vm.medicineOptions},model:{value:(_vm.selectedMedicine),callback:function ($$v) {_vm.selectedMedicine=$$v},expression:"selectedMedicine"}})],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"font-s-500 inputWrapper",attrs:{"label":"Minimale behandelingsduur"}},[_c('b-form-input',{staticClass:"input daysInput",model:{value:(_vm.treatment_duration),callback:function ($$v) {_vm.treatment_duration=$$v},expression:"treatment_duration"}}),_c('span',{staticClass:"icon"},[_vm._v("dag(en)")])],1)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"font-s-500 inputWrapper",attrs:{"label":"Dosering"}},[_c('b-form-input',{staticClass:"input",model:{value:(_vm.dosage),callback:function ($$v) {_vm.dosage=$$v},expression:"dosage"}}),_c('span',{staticClass:"icon"},[_vm._v("aantal")]),_c('b-form-radio-group',{attrs:{"required":"","options":[
            { text: 'ml per dier', value: 'ml' },
            { text: 'gram per dier', value: 'g' },
            { text: 'per stuk', value: 'apiece' } ],"name":"dose-select"},model:{value:(_vm.dosage_unit),callback:function ($$v) {_vm.dosage_unit=$$v},expression:"dosage_unit"}})],1)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"font-s-500",attrs:{"label":"Frequentie"}},[_c('div',{staticClass:"row pl-3"},[_c('div',{staticClass:"inputWrapper"},[_c('b-form-input',{staticClass:"input frequencyInput",model:{value:(_vm.frequency),callback:function ($$v) {_vm.frequency=$$v},expression:"frequency"}}),_c('span',{staticClass:"icon"},[_vm._v(_vm._s(_vm.frequency_unit === "once" ? "keer" : "keer per"))])],1),_c('div',[_c('b-form-select',{attrs:{"required":"","options":[
                { text: 'Selecteer...', value: '' },
                { text: 'Uur', value: 'hour' },
                { text: 'Dag', value: 'day' },
                { text: 'Eenmalig', value: 'once' },
                { text: 'Anders', value: 'other' } ],"name":"radio-doses"},model:{value:(_vm.frequency_unit),callback:function ($$v) {_vm.frequency_unit=$$v},expression:"frequency_unit"}})],1),(_vm.frequency_unit === 'other')?_c('div',[_c('b-form-input',{staticClass:"input frequencyInput ml-2",attrs:{"placeholder":"Anders, nameklijk..."},model:{value:(_vm.frequency_custom),callback:function ($$v) {_vm.frequency_custom=$$v},expression:"frequency_custom"}})],1):_vm._e()])])],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"font-s-500",attrs:{"label":"Overige notitie"}},[_c('b-form-input',{staticClass:"input",model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('div',{staticClass:"row mb-1"},[_c('b-button',{staticClass:"px-4 save",attrs:{"variant":"success","disabled":_vm.verifySave()},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("Opslaan")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-2"},[_c('span',{staticClass:"description"},[_vm._v(" Geef de minimale behandelingsduur aan om het medicijn toe te voegen aan deze aandoening. ")])])}]

export { render, staticRenderFns }