<template>
  <div v-if="selectedAnimalsArray.length" class="selectBar">
    <div class="selectedInfo">
      <span class="text-white">{{ selectedAnimalsArray.length }} geselecteerd</span>
      <button class="btn option" @click="clearSelect()">Deselecteren</button>
    </div>
    <div class="options">
      <b-form-select v-model="editOption" class="option" :options="editOptions"></b-form-select>
      <b-form-select v-model="addOption" class="option" :options="addOptions"></b-form-select>
      <b-form-select v-model="removeOption" class="option" :options="removeOptions"></b-form-select>
    </div>
    <Modal
      v-if="modalOpen"
      :open="modalOpen"
      :selected-add-option="addOption"
      :selected-remove-option="removeOption"
      :selected-edit-option="editOption"
      :selected-animals="selectedAnimalsArray"
      :selected-animals-detailed="selectedAnimalsDetailed"
      @cancel="handleModalCancel()"
    />
  </div>
</template>
<script>
import Modal from "@/components/animalModals/Modal.vue";

export default {
  name: "EditBar",
  components: {
    Modal,
  },
  props: {
    selectedAnimalsArray: {
      type: Array,
      required: true,
    },
    selectedAnimalsDetailed: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      allStatus: "unchecked",
      modalOpen: false,
      editOption: null,
      editOptions: [
        { value: null, text: "Wijzigen" },
        { value: "gewichten", text: "Gewichten" },
        { value: "medicijnen", text: "Medicijnen" },
      ],
      addOption: null,
      addOptions: [
        { value: null, text: "Toevoegen" },
        { value: "kenmerken", text: "Kenmerken" },
        { value: "gewichten", text: "Gewichten" },
        { value: "medicijnen", text: "Medicijnen" },
      ],
      removeOption: null,
      removeOptions: [
        { value: null, text: "Verwijderen" },
        { value: "kenmerken", text: "Kenmerken" },
        { value: "gewichten", text: "Gewichten" },
        { value: "medicijnen", text: "Medicijnen" },
      ],
    };
  },

  watch: {
    addOption(value) {
      if (value) {
        this.modalOpen = true;
      } else {
        this.modalOpen = false;
      }
    },
    editOption(value) {
      if (value) {
        this.modalOpen = true;
      } else {
        this.modalOpen = false;
      }
    },
    removeOption(value) {
      if (value) {
        this.modalOpen = true;
      } else {
        this.modalOpen = false;
      }
    },
  },
  methods: {
    clearSelect() {
      this.$emit("deselect", []);
      this.editOption = null;
      this.addOption = null;
      this.removeOption = null;
    },
    handleModalCancel() {
      this.modalOpen = false;
      this.editOption = null;
      this.addOption = null;
      this.removeOption = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.selectBar {
  height: 100px;
  width: calc(100% - 540px);
  margin-left: -15px;
  position: fixed;
  bottom: 0;
  z-index: 5;
  background-color: #2e994c;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 25px;

  .selectedInfo {
    span {
      margin-right: 10px;
    }
  }

  .options {
    display: flex;
  }
  .option {
    color: white;
    font-size: bold;
    background-color: #58ad70;
    border: unset;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .custom-select {
    background: #58ad70 url("../assets/images/icons/chevron-up-white.svg") no-repeat right 0.75rem
      center/8px 10px !important;

    &:hover {
      background-color: #4c9a62 !important;
      cursor: pointer;
    }
  }
}
</style>
