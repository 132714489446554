<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <router-link class="back" :to="{ path: '/settings/treatmentplans' }">
            <div class="icon-chevron-back"></div>
            Alle behandelplannen
          </router-link>
          <h1 class="page-title">Behandelplan aanpassen</h1>
          <div class="pl-3 pr-3 row d-flex justify-content-between">
            <div class="w-25 mb-0"></div>
            <b-button variant="danger" class="px-4 save" @click="handleDelete"
              >Verwijderen</b-button
            >
          </div>
        </div>
      </div>
      <hr class="my-4" />
      <div class="col-12">
        <b-form @submit.prevent="onSubmit">
          <!-- ANIMAL TYPE -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Dit medicijn is voor">
              <b-form-radio-group
                v-model="treatmentplan.animal_type"
                required
                :options="[
                  { text: 'Schapen', value: 3 },
                  { text: 'Geiten', value: 4 },
                ]"
                name="animal-type-selector"
              ></b-form-radio-group>
            </b-form-group>
          </div>

          <!-- CONDITION -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Aandoening">
              <b-form-input v-model="treatmentplan.disease" required class="input"></b-form-input
            ></b-form-group>
          </div>

          <!-- MEDICINE 1 -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Eerste keus medicijnen">
              <b-form-input
                v-if="!openMedicine1 && treatmentplan.medicine_first_choice_attributes.medicine_id"
                disabled
                :value="treatmentplan.medicine_first_choice_attributes.medicine_name"
                class="input"
              />
              <add-button
                v-if="!openMedicine1 && !treatmentplan.medicine_first_choice_attributes.medicine_id"
                text="Medicijn toevoegen"
                size="l"
                @handleAddClick="openMedicine1 = true"
              />
              <medicine-select
                v-if="openMedicine1"
                :medicines="medicines"
                class="mb-4"
                :old-medicine="treatmentplan.medicine_first_choice_attributes.id || ''"
                :old-treatment-plan-medicines="oldTreatmentPlanMedicines"
                @submit="getMedicine1"
              />
              <div class="row pl-3">
                <b-form-group class="font-m-500" label="In combinatie met">
                  <b-form-input
                    v-if="
                      (!openCombi1 &&
                        treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
                          .medicine_id) ||
                      ''
                    "
                    disabled
                    :value="
                      treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
                        .medicine_name
                    "
                    class="input m"
                  />
                  <add-button
                    v-if="
                      !openCombi1 &&
                      !treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
                        .medicine_id
                    "
                    text="In combinatie met..."
                    size="m"
                    @handleAddClick="openCombi1 = true"
                  />
                  <medicine-select
                    v-if="openCombi1"
                    :medicines="medicines"
                    class="mb-4"
                    :old-medicine="
                      treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
                        .id || ''
                    "
                    :old-treatment-plan-medicines="oldTreatmentPlanMedicines"
                    @submit="getCombi1"
                  />
                </b-form-group>
                <b-form-group class="font-m-500" label="Als alternatief">
                  <b-form-input
                    v-if="
                      !openAlt1 &&
                      treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
                        .medicine_id
                    "
                    disabled
                    :value="
                      treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
                        .medicine_name
                    "
                    class="input m"
                  />
                  <add-button
                    v-if="
                      !openAlt1 &&
                      !treatmentplan.medicine_first_choice_attributes
                        .alternative_medicine_attributes.medicine_id
                    "
                    text="Alternatief toevoegen"
                    size="m"
                    @handleAddClick="openAlt1 = true"
                  />
                  <medicine-select
                    v-if="openAlt1"
                    class="mb-4"
                    :medicines="medicines"
                    :old-medicine="
                      treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
                        .id || ''
                    "
                    :old-treatment-plan-medicines="oldTreatmentPlanMedicines"
                    @submit="getAlt1"
                  />
                </b-form-group>
              </div>
            </b-form-group>
          </div>

          <!-- MEDICINE 2 -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Tweede keus medicijnen">
              <b-form-input
                v-if="!openMedicine2 && treatmentplan.medicine_second_choice_attributes.medicine_id"
                disabled
                :value="treatmentplan.medicine_second_choice_attributes.medicine_name"
                class="input"
              />
              <add-button
                v-if="
                  !openMedicine2 && !treatmentplan.medicine_second_choice_attributes.medicine_id
                "
                text="Medicijn toevoegen"
                size="l"
                @handleAddClick="openMedicine2 = true"
              />
              <medicine-select
                v-if="openMedicine2"
                :medicines="medicines"
                class="mb-4"
                :old-medicine="treatmentplan.medicine_second_choice_attributes.id || ''"
                :old-treatment-plan-medicines="oldTreatmentPlanMedicines"
                @submit="getMedicine2"
              />
              <div class="row pl-3">
                <b-form-group class="font-m-500" label="In combinatie met">
                  <b-form-input
                    v-if="
                      !openCombi2 &&
                      treatmentplan.medicine_second_choice_attributes
                        .combined_medicine_attributes &&
                      treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
                        .medicine_id
                    "
                    disabled
                    :value="
                      treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
                        .medicine_name
                    "
                    class="input m"
                  />
                  <add-button
                    v-if="
                      !openCombi2 &&
                      treatmentplan.medicine_second_choice_attributes
                        .combined_medicine_attributes &&
                      !treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
                        .medicine_id
                    "
                    text="In combinatie met..."
                    size="m"
                    @handleAddClick="openCombi2 = true"
                  />
                  <medicine-select
                    v-if="openCombi2"
                    :medicines="medicines"
                    class="mb-4"
                    :old-medicine="
                      treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
                        .id || ''
                    "
                    :old-treatment-plan-medicines="oldTreatmentPlanMedicines"
                    @submit="getCombi2"
                  />
                </b-form-group>
                <b-form-group class="font-m-500" label="Als alternatief">
                  <b-form-input
                    v-if="
                      !openAlt2 &&
                      treatmentplan.medicine_second_choice_attributes
                        .alternative_medicine_attributes.medicine_id
                    "
                    disabled
                    :value="
                      treatmentplan.medicine_second_choice_attributes
                        .alternative_medicine_attributes.medicine_name
                    "
                    class="input m"
                  />
                  <add-button
                    v-if="
                      !openAlt2 &&
                      !treatmentplan.medicine_second_choice_attributes
                        .alternative_medicine_attributes.medicine_id
                    "
                    text="Alternatief toevoegen"
                    size="m"
                    @handleAddClick="openAlt2 = true"
                  />
                  <medicine-select
                    v-if="openAlt2"
                    :medicines="medicines"
                    class="mb-4"
                    :old-medicine="
                      treatmentplan.medicine_second_choice_attributes
                        .alternative_medicine_attributes.id || ''
                    "
                    :old-treatment-plan-medicines="oldTreatmentPlanMedicines"
                    @submit="getAlt2"
                  />
                </b-form-group>
              </div>
            </b-form-group>
          </div>
          <!-- NOTES -->
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Overige notities">
              <b-form-input
                v-model="treatmentplan.notes"
                placeholder="Notitie..."
                class="input"
              ></b-form-input
            ></b-form-group>
          </div>
          <div class="row mb-4">
            <b-form-group class="font-m-500" label="Behandelplan opslaan" :state="saveSuccess">
              <template #invalid-feedback
                ><p>Er is iets fout gegaan met het toevoegen van een behandelplan.</p></template
              >
              <template #valid-feedback><p>Behandelplan opgeslagen</p></template>
              <b-button type="submit" variant="success" class="px-4 save">Opslaan</b-button>
            </b-form-group>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { deserialize } from "jsonapi-fractal";
import { axiosInstance } from "@/services/axios";
import AddButton from "../../components/Forms/AddButton.vue";
import MedicineSelect from "../../components/Forms/MedicineSelect.vue";

export default {
  name: "TreatmentPlanEdit",
  components: {
    AddButton,
    MedicineSelect,
  },
  data() {
    return {
      oldTreatmentPlan: {},
      oldTreatmentPlanMedicines: [],
      saveSuccess: null,
      medicines: [],
      openMedicine1: false,
      openMedicine2: false,
      openCombi1: false,
      openCombi2: false,
      openAlt1: false,
      openAlt2: false,
      treatmentplan: {
        disease: null,
        animal_type: null,
        notes: null,
        medicine_first_choice_attributes: {
          treatment_duration: null,
          id: null,
          dosage: null,
          dosage_unit: null,
          frequency: null,
          frequency_unit: null,
          frequency_custom: null,
          notes: null,
          medicine_id: null,
          combined_medicine_attributes: {
            treatment_duration: null,
            dosage: null,
            dosage_unit: null,
            frequency: null,
            id: null,
            frequency_unit: null,
            frequency_custom: null,
            notes: null,
            medicine_id: null,
          },
          alternative_medicine_attributes: {
            treatment_duration: null,
            dosage: null,
            dosage_unit: null,
            frequency: null,
            frequency_unit: null,
            id: null,
            frequency_custom: null,
            notes: null,
            medicine_id: null,
          },
        },
        medicine_second_choice_attributes: {
          treatment_duration: null,
          dosage: null,
          dosage_unit: null,
          frequency: null,
          frequency_unit: null,
          frequency_custom: null,
          notes: null,
          id: null,
          medicine_id: null,
          combined_medicine_attributes: {
            treatment_duration: null,
            dosage: null,
            dosage_unit: null,
            frequency: null,
            frequency_unit: null,
            frequency_custom: null,
            id: null,
            notes: null,
            medicine_id: null,
          },
          alternative_medicine_attributes: {
            treatment_duration: null,
            dosage: null,
            dosage_unit: null,
            frequency: null,
            frequency_unit: null,
            id: null,
            frequency_custom: null,
            notes: null,
            medicine_id: null,
          },
        },
      },
    };
  },
  watch: {
    oldTreatmentPlan() {
      this.populateTratmentPlan();
      this.deleteUnusedKeys();
    },
  },
  created() {
    this.$http.secured
      .get(`/treatment_plans/${this.$route.params.id}`)
      .then((response) => {
        this.oldTreatmentPlan = response.data;
        this.oldTreatmentPlanMedicines = response.data.included;

        const data = deserialize(response.data, { changeCase: "camelCase" });
        this.oldTreatmentPlan = data;
      })
      .catch((error) =>
        this.setError(error, "Er is iets fout gegaan met het ophalen van het behandelplan."),
      );

    this.$http.secured
      .get("/medicines")
      .then((response) => {
        this.medicines = response.data.data;
      })
      .catch((error) =>
        this.setError(error, "Er is iets fout gegaan met het ophalen van de medicijnen."),
      );
  },

  methods: {
    onSubmit() {
      const treatmentplan = { ...this.treatmentplan };
      if (
        !treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes?.medicine_id
      ) {
        delete treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes;
      }
      if (
        !treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes?.medicine_id
      ) {
        delete treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes;
      }
      if (
        !treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes?.medicine_id
      ) {
        delete treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes;
      }
      if (
        !treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes
          ?.medicine_id
      ) {
        delete treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes;
      }
      if (!treatmentplan.medicine_second_choice_attributes.medicine_id) {
        delete treatmentplan.medicine_second_choice_attributes;
      }
      if (!treatmentplan.notes) {
        delete treatmentplan.notes;
      }

      axiosInstance
        .put(`/treatment_plans/${this.$route.params.id}`, treatmentplan)
        .then(() => {
          this.saveSuccess = true;
          setTimeout(() => {
            this.saveSuccess = null;
            this.$router.push("/settings/treatmentplans");
          }, 2000);
        })
        .catch(() => {
          this.saveSuccess = false;
        });
    },
    getMedicine1(medicine) {
      this.treatmentplan.medicine_first_choice_attributes = {
        ...medicine,
        combined_medicine_attributes: {
          ...this.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes,
        },
        alternative_medicine_attributes: {
          ...this.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes,
        },
      };
      this.openMedicine1 = false;
    },
    getMedicine2(medicine) {
      this.treatmentplan.medicine_second_choice_attributes = {
        ...medicine,
        combined_medicine_attributes: {
          ...this.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes,
        },
        alternative_medicine_attributes: {
          ...this.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes,
        },
      };

      this.openMedicine2 = false;
    },
    getCombi1(medicine) {
      this.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes = medicine;
      this.openCombi1 = false;
    },
    getCombi2(medicine) {
      this.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes = medicine;

      this.openCombi2 = false;
    },
    getAlt1(medicine) {
      this.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes =
        medicine;
      this.openAlt1 = false;
    },
    getAlt2(medicine) {
      this.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes =
        medicine;
      this.openAlt2 = false;
    },
    populateTratmentPlan() {
      this.treatmentplan.disease = this.oldTreatmentPlan.disease;
      this.treatmentplan.animal_type = this.oldTreatmentPlan.animalType;
      this.treatmentplan.notes = this.oldTreatmentPlan.notes;
      if (this.oldTreatmentPlan.medicine_first_choice) {
        this.treatmentplan.medicine_first_choice_attributes = {
          ...this.treatmentplan.medicine_first_choice_attributes,
          treatment_duration: this.oldTreatmentPlan.medicine_first_choice.treatmentDuration,
          id: this.oldTreatmentPlan.medicine_first_choice.id,
          dosage: this.oldTreatmentPlan.medicine_first_choice.dosage,
          dosage_unit: this.oldTreatmentPlan.medicine_first_choice.dosageUnit,
          frequency: this.oldTreatmentPlan.medicine_first_choice.frequency,
          frequency_unit: this.oldTreatmentPlan.medicine_first_choice.frequencyUnit,
          frequency_custom: this.oldTreatmentPlan.medicine_first_choice.frequencyCustom,
          notes: this.oldTreatmentPlan.medicine_first_choice.notes,
          medicine_id: this.oldTreatmentPlan.medicine_first_choice.medicine.id,
        };
        this.openMedicine1 = true;
      }
      if (this.oldTreatmentPlan.medicine_second_choice) {
        this.treatmentplan.medicine_second_choice_attributes = {
          ...this.treatmentplan.medicine_second_choice_attributes,
          treatment_duration: this.oldTreatmentPlan.medicine_second_choice.treatmentDuration,
          id: this.oldTreatmentPlan.medicine_second_choice.id,
          dosage: this.oldTreatmentPlan.medicine_second_choice.dosage,
          dosage_unit: this.oldTreatmentPlan.medicine_second_choice.dosageUnit,
          frequency: this.oldTreatmentPlan.medicine_second_choice.frequency,
          frequency_unit: this.oldTreatmentPlan.medicine_second_choice.frequencyUnit,
          frequency_custom: this.oldTreatmentPlan.medicine_second_choice.frequencyCustom,
          notes: this.oldTreatmentPlan.medicine_second_choice.notes,
          medicine_id: this.oldTreatmentPlan.medicine_second_choice.medicine.id,
        };
        this.openMedicine2 = true;
      }

      if (this.oldTreatmentPlan.medicine_first_choice?.combined_medicine) {
        this.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes =
          this.oldTreatmentPlan.medicine_first_choice.combined_medicine;
        this.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes.medicine_id =
          this.oldTreatmentPlan.medicine_first_choice.combined_medicine.medicine.id;
        this.openCombi1 = true;
      }
      if (this.oldTreatmentPlan.medicine_first_choice?.alternative_medicine) {
        this.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes =
          this.oldTreatmentPlan.medicine_first_choice.alternative_medicine;
        this.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes.medicine_id =
          this.oldTreatmentPlan.medicine_first_choice.alternative_medicine.medicine.id;
        this.openCombi2 = true;
      }
      if (this.oldTreatmentPlan.medicine_second_choice?.combined_medicine) {
        this.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes =
          this.oldTreatmentPlan.medicine_second_choice.combined_medicine;
        this.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes.medicine_id =
          this.oldTreatmentPlan.medicine_second_choice.combined_medicine.medicine.id;
        this.openAlt1 = true;
      }
      if (this.oldTreatmentPlan.medicine_second_choice?.alternative_medicine) {
        this.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes =
          this.oldTreatmentPlan.medicine_second_choice.alternative_medicine;
        this.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes.medicine_id =
          this.oldTreatmentPlan.medicine_second_choice.alternative_medicine.medicine.id;
        this.openAlt2 = true;
      }
    },
    deleteUnusedKeys() {
      delete this.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
        .combined_medicine;
      delete this.treatmentplan.medicine_first_choice_attributes.combined_medicine_attributes
        .alternative_medicine;
      delete this.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
        .combined_medicine;
      delete this.treatmentplan.medicine_first_choice_attributes.alternative_medicine_attributes
        .alternative_medicine;
      delete this.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
        .combined_medicine;
      delete this.treatmentplan.medicine_second_choice_attributes.combined_medicine_attributes
        .alternative_medicine;
      delete this.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes
        .combined_medicine;
      delete this.treatmentplan.medicine_second_choice_attributes.alternative_medicine_attributes
        .alternative_medicine;
    },

    handleDelete() {
      axiosInstance
        .delete(`/treatment_plans/${this.$route.params.id}`)
        .then(() => {
          setTimeout(() => {
            this.$router.push("/settings/treatmentplans");
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          this.setError(err, "Er is iets fout gegaan met het verwijderen");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  color: #0e0649;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: url("../../assets/images/icons/filter.svg") !important;
  padding: 5px;
}

.inputWrapper {
  position: relative;
  .input + .icon {
    position: absolute;
    font-size: 0.875rem;
    right: 1rem;
    top: 8px;
    bottom: 0;
    height: 100%;
  }
}

.input {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 14px 16px;
  font-size: 0.875rem;
  margin-right: 8px;
  margin-bottom: 8px;
  max-height: 43px;
  width: 348px;
  background-color: white;
}

.save {
  width: 224px;
  border-radius: 21.5px;
}

.m {
  width: 224px !important;
}
</style>
